import React from 'react'
import PropTypes from "prop-types";

function RenderByCond({ child, cond }) {
    return cond ? child : ""
}

RenderByCond.propTypes = {
    child: PropTypes.node.isRequired,
    cond: PropTypes.bool,
}

export default RenderByCond