/* ARTISANS */
export const GET_ARTISANS_LIST = "GET_ARTISANS_LIST"
export const GET_ARTISANS_SUCCESS = "GET_ARTISANS_SUCCESS"
export const GET_ARTISAN = "GET_ARTISAN"
export const GET_ARTISAN_SUCCESS = "GET_ARTISAN_SUCCESS"
export const GET_ARTISANS_FAIL = "GET_ARTISANS_FAIL"
export const SAVE_ARTISAN = "SAVE_ARTISAN"
export const SAVE_ARTISAN_SUCCESS = "SAVE_ARTISAN_SUCCESS"
export const SAVE_ARTISAN_FAIL = "SAVE_ARTISAN_FAIL"
export const UPDATE_ARTISAN = "UPDATE_ARTISAN"
export const DELETE_ARTISAN = "DELETE_ARTISAN"
export const MODAL_ARTISAN_SHOW = "MODAL_ARTISAN_SHOW"

export const deleteArtisan = artisan => ({
    type: DELETE_ARTISAN,
    payload:artisan,
})
export const getArtisansList = (data) => ({
  type: GET_ARTISANS_LIST,
    payload:data
})

export const getArtisan = artisan => ({
  type: GET_ARTISAN,
  payload: artisan,
})
export const getArtisanSuccess = artisan => ({
    type: GET_ARTISAN_SUCCESS,
    payload: artisan,
})

export const getArtisansSuccess = artisanList => ({
    type: GET_ARTISANS_SUCCESS,
    payload: artisanList,
})
export const getArtisansFail = error => ({
    type: GET_ARTISANS_FAIL,
    payload: error,
  })

export const saveArtisan = artisan => ({
    type: SAVE_ARTISAN,
    payload:artisan,
})
export const saveArtisanSuccess = response => ({
    type: SAVE_ARTISAN_SUCCESS,
    payload: response,
  })
  
export const saveArtisanFail = error => ({
    type: SAVE_ARTISAN_FAIL,
    payload: error,
  })

export const updateArtisan = artisan => ({
    type: UPDATE_ARTISAN,
    payload:artisan,
})

export const modalArtisanShow = flag => ({
    type: MODAL_ARTISAN_SHOW,
    payload:flag,
})