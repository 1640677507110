import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods

import {
  postLogin
} from "../../../helpers/backend_helper";
import {SHOW_PRELOADER} from "../../layout/actionTypes";
import {setProfile} from "../profile/actions";

function* loginUser({ payload: { user, history } }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(postLogin, {
        email: user.email,
        password: user.password,
    });
    if(response.success) {
      yield put(loginSuccess(response.data));
      yield put(setProfile(response.data.profile));
      //history('/dashboard',{replace:true});
      window.location.replace("/dashboard")
    } else {
      yield put(apiError(response.data.error));
    }
  } catch (error) {
    console.info(error)
    yield put(apiError(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    sessionStorage.removeItem("authUser");
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
