import {

    GET_CAT_STATE_LIST_SUCCESS } from "./actions";

const INIT_STATE = {
  catStateList: [],
    error: {},
  }
          
  const Catalogs = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CAT_STATE_LIST_SUCCESS:
        return {
          ...state,
          catStateList: action.payload,
        }
        default:
        return state
      }
  }
          
  export default Catalogs