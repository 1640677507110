import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
//artisans
import artisans from "./artisan/reducer";
import ImageGallery from "./imageGallery/reducer";
//catTypeCraftsman
import CatTypeCraftsman from "./catTypeCraftsman/reducer";
import CatShippingCarrier from "./catShippingCarrier/reducer";
//collectors
import Collectors from "./collector/reducer";
import Ecommerce from "./e-commerce/reducer";
import Products from "./product/reducer";
import Catalogs from "./catalogs/reducer";
//Shipping
import Shipping from "./Shipping/reducer";


const rootReducer = combineReducers({
  Layout,
  Login,
  artisans,
  CatTypeCraftsman,
  CatShippingCarrier,
  Collectors,
  Account,
  Ecommerce,
  ImageGallery,
  ForgetPassword,
  Products,
  Profile,
  Catalogs,
  Shipping
});

export default rootReducer;
