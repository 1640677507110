export const GET_CAT_SHIPPING_CARRIER_LIST = "GET_CAT_SHIPPING_CARRIER_LIST"
export const GET_CAT_SHIPPING_CARRIER_SUCCESS = "GET_CAT_SHIPPING_CARRIER_SUCCESS"
export const GET_CAT_SHIPPING_CARRIER_FAIL = "GET_CAT_SHIPPING_CARRIER_FAIL"
export const SAVE_CAT_SHIPPING_CARRIER = "SAVE_CAT_SHIPPING_CARRIER"
export const SAVE_CAT_SHIPPING_CARRIER_SUCCESS = "SAVE_CAT_SHIPPING_CARRIER_SUCCESS"
export const SAVE_CAT_SHIPPING_CARRIER_FAIL = "SAVE_CAT_SHIPPING_CARRIER_FAIL"
export const UPDATE_CAT_SHIPPING_CARRIER = "UPDATE_CAT_SHIPPING_CARRIER"
export const UPDATE_CAT_SHIPPING_CARRIER_SUCCESS = "UPDATE_CAT_SHIPPING_CARRIER_SUCCESS"
export const UPDATE_CAT_SHIPPING_CARRIER_FAIL = "UPDATE_CAT_SHIPPING_CARRIER_FAIL"
export const DELETE_CAT_SHIPPING_CARRIER = "DELETE_CAT_SHIPPING_CARRIER"
export const DELETE_CAT_SHIPPING_CARRIER_SUCCESS = "DELETE_CAT_SHIPPING_CARRIER_SUCCESS"
export const DELETE_CAT_SHIPPING_CARRIER_FAIL = "DELETE_CAT_SHIPPING_CARRIER_FAIL"



export const getCatShippingCarrierList = () => ({
  type: GET_CAT_SHIPPING_CARRIER_LIST,
})

export const getCatShippingCarrierSuccess = catShippingCarrierList => ({
  type: GET_CAT_SHIPPING_CARRIER_SUCCESS,
  payload: catShippingCarrierList,
})

export const getCatShippingCarrierFail = error => ({
    type: GET_CAT_SHIPPING_CARRIER_FAIL,
    payload: error,
  })

  export const saveCatShippingCarrier = catTypeCratfsman => ({
    type: SAVE_CAT_SHIPPING_CARRIER,
    payload:catTypeCratfsman,
  })
  
export const saveCatShippingCarrierSuccess = catShippingCarrierList => ({
    type: SAVE_CAT_SHIPPING_CARRIER_SUCCESS,
    payload: catShippingCarrierList,
  })
  
export const saveCatShippingCarrierFail = error => ({
    type: SAVE_CAT_SHIPPING_CARRIER_FAIL,
    payload: error,
  })

export const updateCatShippingCarrier = catTypeCratfsman => ({
    type: UPDATE_CAT_SHIPPING_CARRIER,
    payload:catTypeCratfsman,
})

export const updateCatShippingCarrierSuccess = catShippingCarrierList => ({
    type: SAVE_CAT_SHIPPING_CARRIER_SUCCESS,
    payload: catShippingCarrierList,
})

export const updateCatShippingCarrierFail = error => ({
    type: SAVE_CAT_SHIPPING_CARRIER_FAIL,
    payload: error,
})

export const deleteCatShippingCarrier = catTypeCratfsman => ({
    type: DELETE_CAT_SHIPPING_CARRIER,
    payload:catTypeCratfsman,
})

export const deleteCatShippingCarrierSuccess = catShippingCarrierList => ({
    type: SAVE_CAT_SHIPPING_CARRIER_SUCCESS,
    payload: catShippingCarrierList,
})

export const deleteCatShippingCarrierFail = error => ({
    type: SAVE_CAT_SHIPPING_CARRIER_FAIL,
    payload: error,
})
