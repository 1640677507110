import { GET_COLLECTORS_SUCCESS, GET_COLLECTORS_FAIL, SAVE_COLLECTOR_SUCCESS,
    SAVE_COLLECTOR_FAIL,MODAL_COLLECTOR_SHOW} from "./actions"

const INIT_STATE = {
    collectorList: [],
    collector:{},
    saveCollectorSuccess:false,
    modalCollectorShow:false,
  }
          
  const Collectors = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_COLLECTORS_SUCCESS:
          console.info(action.payload)
        return {
          ...state,
          collectorList: action.payload.data,
        }
      case GET_COLLECTORS_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case SAVE_COLLECTOR_SUCCESS:
          return {
            ...state,
            collectorList: action.payload.data,
              saveCollectorSuccess: action.payload.success,
              modalCollectorShow: !action.payload.success,
        }
        case SAVE_COLLECTOR_FAIL:
        return {
            ...state,
            saveCollectorSuccess: action.payload.success,
            modalCollectorShow: !action.payload.success,
        }
        case MODAL_COLLECTOR_SHOW:
        return {
            ...state,
            modalCollectorShow: action.payload,
        }
      default:
        return state
      }
  }
          
  export default Collectors