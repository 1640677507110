import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {EDIT_PROFILE, GET_DATA_ADIC_ACCOUNT_BY_SESSION, setProfile} from "./actions"
import { profileSuccess, profileError } from "./actions"
import {getProfile} from "../../../helpers/backend_helper";
import {getDataAdicAccountSuccess} from "../register/actions";

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      /*const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))*/
    } else if (process.env.REACT_APP_DEFAULTAUTH === "genuine") {
      /*const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))*/
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* onGetProfile() {
  try {
    const response = yield call(getProfile, {});
    if(response.success) {
      yield put(setProfile(response.data))
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
  }
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
  yield takeEvery(GET_DATA_ADIC_ACCOUNT_BY_SESSION, onGetProfile);
}

export default ProfileSaga
