const typesProduct = [
    { value: "Women Shoes", label: ""},
    { value: "Women Clothing", label: ""},
    { value: "Men clothing", label: ""},
    { value: "Men shoes", label: ""},
    { value: "Children Clothing", label: ""},
    { value: "Children shoes", label: ""},
    { value: "Accessories", label: ""},
    { value: "Jewelry", label: ""},
    { value: "Bags", label: ""},
    { value: "Home Decoration", label: ""},
    { value: "Authentic Mexican Clay", label: ""},
    { value: "Alebrijes", label: ""},
    { value: "Pet Collection", label: ""},
    { value: "Dia de los muertos", label: ""},
    { value: "Christmas", label: ""},
    { value: "Rebozos y Ponchos", label: ""},
    { value: "Mexican Handmade Dolls", label: ""},
    { value: "Mexican Handmade Toys", label: ""},
    { value: "Fiesta", label: ""},
    { value: "Wedding & Quinceañera", label: ""},
];

export {typesProduct}
