import { takeEvery, put, call } from "redux-saga/effects";
import {
 GET_CAT_STATE_LIST, getCatStateListSuccess
} from "./actions"

import { getCatStatesData } from "../../helpers/backend_helper"
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchCatStateList ({payload:filter}) {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(getCatStatesData, filter)
        console.info(response.data.data)
      yield put(getCatStateListSuccess(response.data))
    } catch (error) {
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

// watchers
function* CatStateSaga() {
    yield takeEvery(GET_CAT_STATE_LIST, fetchCatStateList)

}
    
export default CatStateSaga;