import RenderByCond from "components/Common/RenderByCond";
import React from "react";
import { Input, Row } from "reactstrap";
import "./style.css"

const ProductVariantImages = ({variantsBuild, handleAddPriceVariant, toggleImageGalleryModal,rooteable=false, productEditable=false}) => {

    return (
        <div className={"col-md-12 col-lg-12 col-md-12"}>
            {
                variantsBuild?.map((item, key)=>
                    (<Row className={"mt-3 pb-2 border-bottom"} key={key}>
                    <div className="col-md-2 container-variant-images ">  <h6>Imagén </h6>
                            <button type="button" className={item?.image?"selected btn":"btn"} onClick={()=>toggleImageGalleryModal(item,key)}>
                                    <i className="fa fa-image"></i></button>
                            <RenderByCond cond={item?.image} child={
                                <img className="variant-image" src={item?.image?.url_public} />
                            } />
                            
                        </div>
                        <div className={"col-md-4"}> <h6>Variante </h6>
                        <div><Input disabled={true} value={Array.isArray(item.variant) ? item?.variant?.join(" / "):item.variant}/>
                        {
                            rooteable ?
                            <>
                                <h6 className="mt-3"> Variante En Shopify </h6>
                                <Input disabled={true} value={Array.isArray(item.variant_admin) ? item?.variant_admin?.join(" / "):item.variant_admin}/>
                            </>:""
                        }
                        </div>
                        
                        </div>
                        <div className={"col-md-2"}>
                            <h6>Precio (en MXN) </h6>
                            <div>
                                <Input id={"price"+key} name={"price"} value={item?.price}
                                type="number" disabled={rooteable} onChange={(e)=>handleAddPriceVariant(key,item,e.target.value,e,"price")} 
                                className="form-control" placeholder="1.00"/>
                                {
                                    rooteable ?<>
                                    <h6 className="mt-3">Precio (en DLS) </h6>
                                    <Input style={{float:"left"}} disabled={!productEditable} id={"price_admin"+key} name={"price_admin"+key} value={item.price_admin}
                                    type="text" onChange={(e)=>handleAddPriceVariant(key,item,e.target.value,e,"price_admin")} 
                                    className="form-control" placeholder="1.00"/></>:""
                                }    
                            </div>
                         </div>
                         <RenderByCond cond={rooteable} child={
                         <div className={"col-md-2"}>
                                    <h6 >Costo (en DLS) </h6>
                                    <Input style={{float:"left"}} disabled={!productEditable} id={"price_cost"+key} name={"price_cost"+key} value={item.price_cost}
                                    type="text" onChange={(e)=>handleAddPriceVariant(key,item,e.target.value,e,"price_cost")} 
                                    className="form-control" placeholder="1.00"/>
                         </div>} />
                         <div className={"col-md-2"}><h6>Cantidad </h6>
                             <div><Input  disabled={rooteable}  id={"quantity"+key}  name={"quantity"+key} value={item?.quantity}
                                type="number" onChange={(e)=>handleAddPriceVariant(key,item,e.target.value,e,"quantity")} 
                                 className="form-control" placeholder="" />
                                 </div>

                                 <div className="border-card">
                                 <h5 className="mt-1">Inventario </h5>
                                    <div  >
                                    <h6 className="mt-1">Pendiente Para Enviar </h6>
                                    <div><Input  disabled={true}  id={"quantity"+key}  name={"quantity"+key} value={item?.committed}
                                        type="text" 
                                        className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div >
                                        <h6 className="mt-3"> Disponible a la Venta </h6>
                                    <div><Input  disabled={true}  id={"quantity"+key}  name={"quantity"+key} value={item?.inventory_quantity}
                                        type="text" 
                                        className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div >
                                    <h6 className="mt-3"> Publicados </h6>
                                        <div><Input  disabled={true}  id={"quantity"+key}  name={"quantity"+key} value={item?.quantity}
                                        type="text" 
                                        className="form-control" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                         </div>
            
                        </Row>
                    ))        
            }
        </div>
    )
}

export default ProductVariantImages