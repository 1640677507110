export const GET_CAT_STATE_LIST = "GET_CAT_STATE_LIST"
export const GET_CAT_STATE_LIST_SUCCESS = "GET_CAT_STATE_SUCCESS"

export const getCatStateList = (filter) => ({
  type: GET_CAT_STATE_LIST,
  payload:filter
})

export const getCatStateListSuccess = data => ({
  type: GET_CAT_STATE_LIST_SUCCESS,
  payload: data,
})