/* COLLECTORS */
export const GET_COLLECTORS_LIST = "GET_COLLECTORS_LIST"
export const GET_COLLECTORS_SUCCESS = "GET_COLLECTORS_SUCCESS"
export const GET_COLLECTORS_FAIL = "GET_COLLECTORS_FAIL"
export const SAVE_COLLECTOR = "SAVE_COLLECTOR"
export const SAVE_COLLECTOR_SUCCESS = "SAVE_COLLECTOR_SUCCESS"
export const SAVE_COLLECTOR_FAIL = "SAVE_COLLECTOR_FAIL"
export const UPDATE_COLLECTOR = "UPDATE_COLLECTOR"
export const DELETE_COLLECTOR = "DELETE_COLLECTOR"
export const MODAL_COLLECTOR_SHOW = "MODAL_COLLECTOR_SHOW"

export const deleteCollector = collector => ({
    type: DELETE_COLLECTOR,
    payload:collector,
})
export const getCollectorsList = () => ({
  type: GET_COLLECTORS_LIST,
})

export const getCollectorsSuccess = collectorList => ({
  type: GET_COLLECTORS_SUCCESS,
  payload: collectorList,
})
export const getCollectorsFail = error => ({
    type: GET_COLLECTORS_FAIL,
    payload: error,
  })

export const saveCollector = collector => ({
    type: SAVE_COLLECTOR,
    payload:collector,
})
export const saveCollectorSuccess = collectorList => ({
    type: SAVE_COLLECTOR_SUCCESS,
    payload: collectorList,
  })
  
export const saveCollectorFail = error => ({
    type: SAVE_COLLECTOR_FAIL,
    payload: error,
  })

export const updateCollector = collector => ({
    type: UPDATE_COLLECTOR,
    payload:collector,
})

export const setModalCollectorShow = modal => ({
    type: MODAL_COLLECTOR_SHOW,
    payload:modal,
})