import PropTypes from "prop-types";
import React, {useEffect} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import {useParams, Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { passwordVerified as onPasswordVerified,
  getDataAdicAccount as onGetDataAdicAccount} from "../../store/auth/register/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-main.png";
import {states} from "../../common/data";
import {isEmpty} from "lodash";
import account from "../../store/auth/register/reducer";
import Account from "../../store/auth/register/reducer";

const VerifyAccountPage = props => {

  //meta title
  document.title = "Verificar Cuenta | Guelaguetza Designs";

  const dispatch = useDispatch();
  const {key} = useParams()

  const { role,dataAdic, passwordVerifiedSuccess } = useSelector(state => ({
    role: state.Account.role,
    dataAdic: state.Account.dataAdic,
    passwordVerifiedSuccess: state.Account.passwordVerifiedSuccess
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      business_name:  dataAdic ? dataAdic.business_name:null,
      name: dataAdic && dataAdic.name,
      paternal_surname:  dataAdic && dataAdic.paternal_surname,
      maternal_surname:  dataAdic && dataAdic.maternal_surname,
      birthdate:  dataAdic && dataAdic.birthdate,
      address: dataAdic && dataAdic.address,
      zip_code:  dataAdic && dataAdic.zip_code,
      state:  dataAdic && dataAdic.state,
      category:  '',
      phone: dataAdic && dataAdic.phone,
      bank_account:  dataAdic && dataAdic.bank_account,
      email:  dataAdic && dataAdic.email,
      password: '',
      passwordVerified: '',
      rememberToken:""
    },
    validationSchema: Yup.object({
      business_name: Yup.string().when([], {
        is: () => role===2,
        then: Yup.string().required("Ingresa esta información"),
        otherwise: Yup.string().notRequired()
      }),
      birthdate: Yup.string().when([], {
            is: () => false,
            then: Yup.string().required("Ingresa tu fecha de nacimiento"),
            otherwise: Yup.string().notRequired()
          }),
      address: Yup.string().when([], {
        is: () => false,
        then: Yup.string().required("Ingresa el domicilio "),
        otherwise: Yup.string().notRequired()
      }),
      state: Yup.string().when([], {
        is: () => role===2,
        then: Yup.string().required("Elige tu estado"),
        otherwise: Yup.string().notRequired()
      }),
      zip_code: Yup.string().when([], {
        is: () => false,
        then: Yup.string().required("Ingresa tu código postal"),
        otherwise: Yup.string().notRequired()
      }),
      phone: Yup.string().when([], {
        is: () => role===2,
        then: Yup.string().required("Ingresa tu teléfono"),
        otherwise: Yup.string().notRequired()
      }),


      password: Yup.string().required("Ingresa una contraseña segura"),
      passwordVerified: Yup.string().required("Confirma tu contraseña")
          .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Las contraseñas no coinciden"
        ),
      }),
    }),
    onSubmit: (values) => {
      values["rememberToken"] = key;
      const userData = {
        business_name: values["business_name"],
        birthdate: values["birthdate"],
        address: values["address"],
        state: values["state"],
        zip_code: values["zip_code"],
        phone: values["phone"],
        bank_account:values["bank_account"],
        rememberToken: key,
        password: values["password"],
        passwordVerified: values["passwordVerified"],
        finishRegistration: dataAdic?.status==="Inactivo",
      };
      dispatch(onPasswordVerified(userData, props.router.navigate));
    }
  });

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  useEffect(() => {
    if (!isEmpty(key)) {
      dispatch(onGetDataAdicAccount(key))
    }
  }, [key]);

  useEffect(() => {
    if (passwordVerifiedSuccess) {
      validation.resetForm();
    }
  }, [passwordVerifiedSuccess]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 text-white">
                        <h3 className="text-primary text-white">Guelaguetza Designs </h3>
                        <p>Activación de tu cuenta </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      {
                          (role===2 && dataAdic?.status==="Inactivo") &&
                          <>
                            <Col className={"mb-3"}>
                              <h5>Completa la información tu cuenta</h5>
                            </Col>
                            <Col className="mb-3">
                              <div className="mb-3">
                                <Label className="form-label">Nombre de Taller o Negocio * </Label>
                                <Input
                                    name="business_name"
                                    type="text"
                                    placeholder="Ingresa el nombre de tu taller o negocio"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.business_name || ""}
                                    invalid={
                                      validation.touched.business_name && validation.errors.business_name ? true : false
                                    }
                                />
                                {validation.touched.business_name && validation.errors.business_name ? (
                                    <FormFeedback type="invalid">{validation.errors.business_name}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col className={"mb-3"}>
                              <div className="mb-3">
                                <Label className="form-label">Fecha de nacimiento </Label>
                                <Input
                                    name="birthdate"
                                    type="date"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={validation.values.birthdate || ""}
                                    invalid={
                                      validation.touched.birthdate && validation.errors.birthdate ? true : false
                                    }
                                />
                                {validation.touched.birthdate && validation.errors.birthdate ? (
                                    <FormFeedback type="invalid">{validation.errors.birthdate}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col className={"mb-3"}>
                              <div className="mb-3">
                                <Label className="form-label">Domicilio</Label>
                                <Input
                                    name="address"
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                    invalid={
                                      validation.touched.address && validation.errors.address ? true : false
                                    }
                                />
                                {validation.touched.address && validation.errors.address ? (
                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col className={"mb-3"}>
                              <div className="mb-3">
                                <Label className="form-label">Código Postal </Label>
                                <Input
                                    name="zip_code"
                                    type="text"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    placeholder="Ingresa el C.P. "
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.zip_code || ""}
                                    invalid={
                                      validation.touched.zip_code && validation.errors.zip_code ? true : false
                                    }
                                />
                                {validation.touched.zip_code && validation.errors.zip_code ? (
                                    <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col className={"mb-3"}>
                              <div className="mb-4">
                                <Label className="form-label">Estado *</Label>
                                <Input
                                    name="state"
                                    type="select" readOnly={key!==undefined} disabled={key!==undefined}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    className="form-select"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.state || ""}
                                    invalid={
                                      validation.touched.state && validation.errors.state ? true : false
                                    }
                                >
                                  <option value={""}>Elija una opción</option>
                                  {
                                    states.map((item)=>{
                                      return <option key={item.key} value={item.id}>{item.name}</option>
                                    })
                                  }
                                </Input>
                                {validation.touched.state && validation.errors.state ? (
                                    <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col className={"mb-3"}>
                              <div className="mb-3">
                                <Label className="form-label">Teléfono *</Label>
                                <Input
                                    name="phone"
                                    type="tel"
                                    placeholder="Ingresa el teléfono "
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                    invalid={
                                      validation.touched.phone && validation.errors.phone ? true : false
                                    }
                                />
                                {validation.touched.phone && validation.errors.phone ? (
                                    <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col className={"mb-3"}>
                              <div className="mb-3">
                                <Label className="form-label">CLABE Interbancaria</Label>
                                <Input
                                    name="bank_account"
                                    type="text"
                                    placeholder="Ingresa tu CLABE interbancaria de 18 DÍGITOS"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.bank_account || ""}
                                    invalid={
                                      validation.touched.bank_account && validation.errors.bank_account ? true : false
                                    }
                                />
                                {validation.touched.bank_account && validation.errors.bank_account ? (
                                    <FormFeedback type="invalid">{validation.errors.bank_account}</FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                      }
                      <Col className={"mb-3"}>
                        {
                          dataAdic?.status==="Activo" ?
                              (<h5>Actualiza tu contraseña para ingresar</h5>):
                              (<h5>Crea una contraseña para tu cuenta</h5>)
                        }

                      </Col>
                      <Col className={"mb-3"}>
                        <div className="mb-3">
                          <Label className="form-label">Password *</Label>
                          <Input
                            name="password"
                            className="form-control text-center"
                            placeholder="Crea una contraseña segura"
                            type="password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col className={"mb-3"}>
                        <div className="mb-3">
                          <Label className="form-label">Confirma tu Password *</Label>
                          <Input
                              name="passwordVerified"
                              className="form-control text-center"
                              placeholder="Ingresa de nuevo tu contraseña"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.passwordVerified || ""}
                              invalid={
                                validation.touched.passwordVerified && validation.errors.passwordVerified ? true : false
                              }
                          />
                          {validation.touched.passwordVerified && validation.errors.passwordVerified ? (
                              <FormFeedback type="invalid">{validation.errors.passwordVerified}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-success w-md "
                            type="submit"
                          >
                            Guardar Contraseña
                          </button>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="text-center">
                          ¿Ya tienes contraseña? {" "}
                          <Link to="/login" className="btn btn-info">
                            Inicia Sesión
                          </Link>{" "}
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(VerifyAccountPage);

VerifyAccountPage.propTypes = {
  history: PropTypes.object,
};
