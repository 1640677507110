import {
    GET_CAT_TYPE_CRAFTSMAN_SUCCESS,
    GET_CAT_TYPE_CRAFTSMAN_FAIL,
    SAVE_CAT_TYPE_CRAFTSMAN_SUCCESS,
    SAVE_CAT_TYPE_CRAFTSMAN_FAIL,
    UPDATE_CAT_TYPE_CRAFTSMAN_FAIL,
    UPDATE_CAT_TYPE_CRAFTSMAN_SUCCESS,
    DELETE_CAT_TYPE_CRAFTSMAN_SUCCESS,
    DELETE_CAT_TYPE_CRAFTSMAN_FAIL} from "./actions";

const INIT_STATE = {
  catTypeCraftsmanList: [],
  catCatTypeCraftsman:{},
    error: {},
  }
          
  const CatTypeCraftsman = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CAT_TYPE_CRAFTSMAN_SUCCESS:
        return {
          ...state,
          catTypeCraftsmanList: action.payload,
        }
      case GET_CAT_TYPE_CRAFTSMAN_FAIL:
        return {
            ...state,
            error: action.payload,
        }
      case SAVE_CAT_TYPE_CRAFTSMAN_SUCCESS:
          return {
            ...state,
            catTypeCraftsmanList: action.payload.data.data,
              error: action.payload
          }  
      case SAVE_CAT_TYPE_CRAFTSMAN_FAIL:
          return {
              ...state,
              error: action.payload,
          }
        case UPDATE_CAT_TYPE_CRAFTSMAN_SUCCESS:
            console.info(action.payload);
            return {
                ...state,
                catTypeCraftsmanList: action.payload,
                error: action.payload
            }
        case UPDATE_CAT_TYPE_CRAFTSMAN_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_CAT_TYPE_CRAFTSMAN_SUCCESS:
            return {
                ...state,
                catTypeCraftsmanList: action.payload,
                error: action.payload
            }
        case DELETE_CAT_TYPE_CRAFTSMAN_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
        return state
      }
  }
          
  export default CatTypeCraftsman