import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./style.css"

const TermsArtisan = () => {
    return (
        <React.Fragment>
             <Container fluid={true}>
                 <Row>
                     <p>
                         <span className="font-size-16">Guelaguetza Designs LLC</span>
                         Disposiciones a las que se sujetarán aquellas personas que Ofrezcan, Comercialicen o Vendan Bienes, a traves de nuestro servicio.
                     </p>
                     <h3 >
                         GENERALIDADES
                     </h3>
                     <p className="">
                         Guelaguetza Designs LLC como intermediario entre el Artesano y el comprador final, proporciona las herramientas y los servicios que se ponen en el sitio web a disposición suya (artesano) y el usuario, siempre y cuando acepte la totalidad de los términos, Politicas del servicio, limitaciones del servicio, responsabilidades y condiciones de servicio contemplados aquí.
                     </p>
                     <h3>
                         CONDICIONES DEL SERVICIO
                     </h3>
                     <p>
                         Cada artesano se compromete a:
                     </p>
                     <p>
                         1. Tener stock por 3 meses. Si el artículo es removido antes de los 3 meses, artesano es responsable del pago
                         por publicación de $15 pesos, por producto/color.
                     </p>
                     <p>
                         Por ejemplo, si tiene talla chica, mediana y grande (3 artículos) su pago por publicación por remover el articulo antes de los 3 meses serian $15 pesos.
                     </p>
                     <p>
                         2. Enviar los productos en los días definidos:
                     </p>
                     <p>
                         <ul>
                             <li>
                                 Los pedidos se recibirán de jueves a martes.
                             </li>
                             <li>
                                 Ordenes se enviarán a Guelaguetza Designs cada miércoles de la semana.
                             </li>
                         </ul>
                     </p>
                     <p>
                         Por ejemplo, si su orden entra en sistema en jueves, tienes hasta el próximo martes para entregarlo al
                         colector.
                     </p>
                     <p>
                         Si tu orden entra en martes antes de las 2pm, debe de llevar el articulo (s) el mismo día antes de las 7pm.
                     </p>
                     <p>
                         Uso de propiedad intelectual autónoma
                         <ul>
                             <li>
                                 Fotos originales del articulo
                                 <ul>
                                     <li>
                                         Descripción detallada del articulo que incluya tipo de tela, tipo de bordado, talla, medidas, colores, etc.
                                     </li>
                                 </ul>
                             </li>
                         </ul>
                     </p>
                     <p>
                         4. Si el articulo enviado no corresponde al artículo solicitado, el artesano será acreedor a una penalización.
                         <br/>
                         En caso de producto defectuoso- Se negociará un porcentaje de descuento para la venta
                         <p>
                             Si el producto no es apto para la venta, el artesano pude ser responsable de cargos de envío de México a
                             Guelaguetza Designs.
                         </p>
                         <p>
                             En caso de 3 productos defectuosos durante 6 meses, su estadía como parte del grupo debe de ser revalidada
                         </p>
                     </p>
                     <p>
                         5. Artesano estipulará el costo de su producto en pesos mexicanos y este monto es el pago que se le hará una vez que el producto sea vendido (2 días después de la entrega del artículo en Guelaguetza Designs (Michigan))
                         <p>
                             El precio de venta al cliente es independiente al costo del artesano. Dicho precio de venta será determinado únicamente por Guelaguetza Designs de acuerdo a todas las variantes y gastos que el articulo implique para su venta.
                         </p>
                     </p>
                     <h3>
                         DE LAS RESPONSABILIDADES DE GUELAGUETZA DESIGNS
                     </h3>
                     <p>
                         <ul>
                             <li>
                                 Publicar productos que cumpla con las normas en website y canales de venta
                             </li>
                             <li>
                                 Cubrir cargos de envío de México a Guelaguetza Designs
                             </li>
                             <li>
                                 Realizar los envíos al cliente final
                             </li>
                             <li>
                                 Pago de cargo de publicación por 3 meses si el producto no se vende
                             </li>
                             <li>
                                 Manejo de cualquier daño o reclamo sobre la orden una vez enviada al cliente
                             </li>
                             <li>
                                 Pago de los artículos enviados será 2 días hábiles DESPUES de que los articulo (s) sean entregados e
                                 inspeccionados en Guelaguetza Designs.
                             </li>
                         </ul>
                     </p>
                     <p>
                         Por ejemplo, si usted entrego su articulo el jueves, dicho artículo salió en paquetería el próximo miércoles. Guelaguetza Designs estaría recibiendo el articulo el segundo lunes después de la que el artesano hizo la entrega. El articulo se inspeccionará y será pagado al artesano no mas de 2 días después de la entrega del paquete en Guelaguetza Designs
                     </p>
                     <p>
                         <span className="font-size-16">POLÍTICAS DEL SERVICIO WEB y COPYRIGHT</span> (Comercio Electrónico – Disposiciones a las que se sujetarán aquellas personas que Ofrezcan, Comercialicen o Vendan Bienes, Productos o Servicios.)
                     </p>
                     <p style={{fontWeight:"bold"}}>Usted reconce y acepta la autoria de sus productos como propios. </p>
                    <p>
                        Guelaguetza Designs No tendra ninguna responsabilidad como consecuencia del uso malversado o reproduccion en imagen o captura de pantalla u otro medio de reproducción o copia, de obra que NO cuente con el registro de obra literaria o artística del artesano que la propociono y que se encuentre publicada a la venta en el sitio web.
                    </p>
                     <p>
                         El artesano se compromote a proporcionar imagen fidedigna del producto a comercializar, esto para garantizar que la obra este libre de copyright.
                     </p>
                     <p>
                         Guelaguetza Designs no será responsable por la obras o por las imágenes que el artesano proporcione y que infringan el copyright o derecho de autor.
                     </p>
                     <h3>
                         LIMITACIONES DEL SERVICIO
                     </h3>
                     <p>
                         Se le prohíbe utilizar el sitio o su contenido (a) para cualquier propósito ilegal; (b) para solicitar a otros que realicen o participen en cualquier acto ilegal; (c) para infringir cualquier reglamento, norma, ley u ordenanza local internacional, federal, provincial o estatal; (d) para infringir o violar nuestros derechos de propiedad intelectual o los derechos de propiedad intelectual de otros.
                     </p>
                     <p>
                         Usted acepta no reproducir, duplicar, copiar, vender, revender ni aprovechar ninguna parte del Servicio o productos, uso del Servicio o acceso al Servicio o cualquier contacto en el sitio web a través de la cual se presta el servicio, sin nuestro permiso expreso por escrito, esto incluye obra o fotografia, asi como nombres de obras.
                     </p>
                     <h3>
                         DE LA COMPETENCIA DE PAGO
                     </h3>
                     <p>
                         El artesano se compromete a proporcionar en todo momento informacion fidedigna de su identidad y los mecanismos para el pago de transacciones.
                     </p>
                     <p>
                         Usted acepta actualizar rápidamente su cuenta y demás informaciones, entre ellas, su dirección de correo electrónico, los números de tarjeta de crédito o débito según sea el caso y las fechas de vencimiento, para que podamos completar sus transacciones y contactarlo según sea necesario.
                     </p>
                     <p>
                         Guelaguetza Designs no se hará responsable por la información incompleta o malversada de los mecanismos de pago que proporcione el artesano y que deriven en que los recursos sean retenidos o desviados por la entidades bancarias o las que correspondan según sea el caso.
                     </p>
                     <h3>
                         MODIFICACIONES AL SERVICIO
                     </h3>
                     <p>
                         En caso de que se infrigan las normas establecidas previamente Guelaguetza Designs se reserva el derecho de sancionar a o los artesanos con lo establecido bajo contrato o en su defecto resindir o eliminar sus productos del sitio de comercialización, sin responsabilidad alguna.
                     </p>
                 </Row>
             </Container>
        </React.Fragment>
    )
}

export default TermsArtisan
