import React from 'react'
import { useDispatch } from 'react-redux'
import { Input } from 'reactstrap'


const InputVariantValues = ({handleChangeVariantValue, iterator=0, item, id, productIDSaved=null,
    onRemoveRowVariantProduct, onAddRowVariantProduct, name, lists, rooteable=false,productEditable
}) => {

    const dispatch = useDispatch()
    return (
        <>
            <div key={iterator} className="input-group">
            
                <Input disabled={rooteable || productIDSaved!==null} onChange={event => handleChangeVariantValue(name, event, iterator)} 
                id={id+iterator} name={name+iterator} type="text" value={item.value} 
                className="form-control" placeholder=""/>
                {
                    rooteable ? <Input onChange={event => handleChangeVariantValue(name, event, iterator,rooteable)} 
                    id={id+iterator} name={name+iterator} type="text" value={item.value_shopify} disabled={!productEditable}
                    className="form-control" placeholder=""/>:""
                }
                { iterator !== 0 && !rooteable && productIDSaved===null ? 
                <button onClick={() => { dispatch(onRemoveRowVariantProduct({name:name,val:"",key:iterator}))}} 
                className="btn btn-danger" type="button" id={"inputGroup"+name+iterator}>Borrar</button> : ""}
                { (iterator + 1) === lists.length && !rooteable  && productIDSaved===null ?
                <button onClick={() => { dispatch(onAddRowVariantProduct({name:name,val:"",key:null})) }} 
                className="btn btn-info" type="button" id={"inputGroup"+name+iterator}>Otro</button> : "" }
            </div>
        </>
        
    )
}

export default InputVariantValues