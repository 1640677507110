/* PRODUCTS */
export const GET_PRODUCTS_LIST = "GET_PRODUCTS_LIST"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"
export const SAVE_PRODUCT = "SAVE_PRODUCT"
export const SAVE_PRODUCT_SUCCESS = "SAVE_PRODUCT_SUCCESS"
export const SAVE_PRODUCT_FAIL = "SAVE_PRODUCT_FAIL"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_VARIANT = "UPDATE_PRODUCT_VARIANT"
export const UPDATE_PRODUCT_PUBLISH_SHOPIFY = "UPDATE_PRODUCT_PUBLISH_SHOPIFY"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const PRODUCT_ADD_TAB_ACTIVE_UPDATE = "PRODUCT_ADD_TAB_ACTIVE_UPDATE"
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const SET_PRODUCT_CLEAN = "SET_PRODUCT_CLEAN"
export const SET_PRODUCT_VARIANT_FLAG= "SET_PRODUCT_VARIANT_FLAG"
export const SET_PRODUCT_SIZE_LIST= "SET_PRODUCT_SIZE_LIST"
export const SET_PRODUCT_COLOR_LIST= "SET_PRODUCT_COLOR_LIST"
export const SET_PRODUCT_STYLE_LIST= "SET_PRODUCT_STYLE_LIST"
export const PRODUCT_OPTION_CHECK_UNCHECK= "PRODUCT_OPTION_CHECK_UNCHECK"
export const SET_PRODUCT_SIZE_CHECK= "SET_PRODUCT_SIZE_CHECK"
export const SET_PRODUCT_COLOR_CHECK= "SET_PRODUCT_COLOR_CHECK"
export const SET_PRODUCT_STYLE_CHECK= "SET_PRODUCT_STYLE_CHECK"
export const ADD_ROW_VARIANT_PRODUCT= "ADD_ROW_VARIANT_PRODUCT"
export const REMOVE_ROW_VARIANT_PRODUCT= "REMOVE_ROW_VARIANT_PRODUCT"
export const CHANGE_VALUE_VARIANT_PRODUCT= "CHANGE_VALUE_VARIANT_PRODUCT"
export const SET_PRODUCT_OPTIONS= "SET_PRODUCT_OPTIONS"
export const SET_PRODUCT_VARIANTS= "SET_PRODUCT_VARIANTS"
export const SET_VARIANTS_BUILD= "SET_VARIANTS_BUILD"
export const SET_VARIANTS= "SET_VARIANTS"
export const SET_PRICE_VARIANTS= "SET_PRICE_VARIANTS"
export const SET_IMAGE_VARIANTS= "SET_IMAGE_VARIANTS"
export const SET_VARIANT_EDITBLE = "SET_VARIANT_EDITBLE"


export const deleteProduct = product => ({
    type: DELETE_PRODUCT,
    payload:product,
})
export const getProductsList = data => ({
  type: GET_PRODUCTS_LIST,
    payload: data
})

export const getProductsSuccess = productList => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: productList,
})
export const getProductsFail = error => ({
    type: GET_PRODUCTS_FAIL,
    payload: error,
  })

export const saveProduct = product => ({
    type: SAVE_PRODUCT,
    payload:product,
})
export const saveProductSuccess = productList => ({
    type: SAVE_PRODUCT_SUCCESS,
    payload: productList,
  })
export const productAddActiveTabUpdate = activeTab => ({
    type: PRODUCT_ADD_TAB_ACTIVE_UPDATE,
    payload: activeTab,
  })

export const saveProductFail = error => ({
    type: SAVE_PRODUCT_FAIL,
    payload: error,
  })

export const updateProduct = product => ({
    type: UPDATE_PRODUCT,
    payload:product,
})

export const updateProductVariant = productV => ({
    type: UPDATE_PRODUCT_VARIANT,
    payload:productV,
})


export const getProductDetail = productId => ({
    type: GET_PRODUCT_DETAIL,
    productId,
})

export const getProductDetailSuccess = products => ({
    type: GET_PRODUCT_DETAIL_SUCCESS,
    payload: products,
})

export const setProductClean = product => ({
    type: SET_PRODUCT_CLEAN,
    payload: product,
})

export const setProductVariantFlag = checked => ({
    type: SET_PRODUCT_VARIANT_FLAG,
    payload: checked,
})


export const productPublishShopify = product => ({
    type: UPDATE_PRODUCT_PUBLISH_SHOPIFY,
    payload:product,
})

export const setSizeList = sizeList => ({
    type: SET_PRODUCT_SIZE_LIST,
    payload:sizeList,
})

export const setColorList = colorList => ({
    type: SET_PRODUCT_COLOR_LIST,
    payload:colorList,
})

export const setStyleList = styleList => ({
    type: SET_PRODUCT_STYLE_LIST,
    payload:styleList,
})

export const setSizeCheck = optionChecked => ({
    type: SET_PRODUCT_SIZE_CHECK,
    payload:optionChecked,
})

export const productOptionCheck = optionChecked => ({
    type: PRODUCT_OPTION_CHECK_UNCHECK,
    payload:optionChecked,
})

export const setColorCheck = styleFlag => ({
    type: SET_PRODUCT_COLOR_CHECK,
    payload:styleFlag,
})
export const setStyleCheck = styleFlag => ({
    type: SET_PRODUCT_STYLE_CHECK,
    payload:styleFlag,
})
export const addRowVariantProduct = variant => ({
    type: ADD_ROW_VARIANT_PRODUCT,
    payload:variant,
})

export const removeRowVariantProduct = variant => ({
    type: REMOVE_ROW_VARIANT_PRODUCT,
    payload:variant,
})

export const changeVariantValue = variant => ({
    type: CHANGE_VALUE_VARIANT_PRODUCT,
    payload:variant,
})


export const setProductOptions = options => ({
    type: SET_PRODUCT_OPTIONS,
    payload:options,
})
export const handleProductVariantGenerate = () => ({
    type: SET_PRODUCT_VARIANTS,
})

export const handleSetVariantsBuild = variantsPrices => ({
    type: SET_VARIANTS_BUILD,
    payload:variantsPrices
})
export const handleSetVariants = variants => ({
    type: SET_VARIANTS,
    payload:variants
})

export const handleSetVariantEditable = variant => ({
    type: SET_VARIANT_EDITBLE,
    payload:variant
})

export const handleSetPriceVariants = variantsPrices => ({
    type: SET_PRICE_VARIANTS,
    payload:variantsPrices
})

export const handleSetImageVariants = variantsImages => ({
    type: SET_PRICE_VARIANTS,
    payload:variantsImages
})