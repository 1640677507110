import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ArtisansSaga from "./artisan/saga";
import CatTypeCraftsman from "./catTypeCraftsman/saga";
import CatShippingCarrierSaga from "./catShippingCarrier/saga";
import ecommerceSaga from "./e-commerce/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import collectorsSaga from "./collector/saga";
import productsSaga from "./product/saga";
import CatStateSaga from "./catalogs/saga";
import GallerySaga from "./imageGallery/saga";
import ShippingSaga from "./Shipping/saga"

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ArtisansSaga),
    fork(CatTypeCraftsman),
    fork(CatShippingCarrierSaga),
    fork(ecommerceSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(collectorsSaga),
    fork(productsSaga),
    fork(CatStateSaga),
    fork(GallerySaga),
    fork(ShippingSaga)
  ]);
}
