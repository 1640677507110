import PropTypes from "prop-types";
import React, {useEffect} from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import {useParams, Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import {registerCraftsman as onRegisterCraftsman} from "../../store/auth/register/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo-main.png";
import {states} from "../../common/data";
import {isEmpty} from "lodash";
import account from "../../store/auth/register/reducer";
import Account from "../../store/auth/register/reducer";

const Register = props => {

  //meta title
  document.title = "Registro de Artesano | Guelaguetza Designs";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      register:  'new',
      business_name:  '',
      name:  '',
      paternal_surname:  '',
      maternal_surname:  '',
      birthdate:  '',
      address: '',
      zip_code:  '',
      state:  '',
      category:  '',
      phone: '',
      bank_account:  '',
      email:  '',
    },
    validationSchema: Yup.object({
      business_name: Yup.string().when([], {
        is: () => true,
        then: Yup.string().required("Ingresa esta información"),
        otherwise: Yup.string().notRequired()
      }),
      name: Yup.string().required("Por favor ingresa el nombre"),

      paternal_surname: Yup.string().required("Ingresa el apellido paterno"),
      maternal_surname: Yup.string().required("Ingresa el apellido materno"),
      birthdate: Yup.string().when([], {
            is: () => false,
            then: Yup.string().required("Ingresa tu fecha de nacimiento"),
            otherwise: Yup.string().notRequired()
          }),
      address: Yup.string().when([], {
        is: () => false,
        then: Yup.string().required("Ingresa el domicilio "),
        otherwise: Yup.string().notRequired()
      }),
      state: Yup.string().when([], {
        is: () => true,
        then: Yup.string().required("Elige tu estado"),
        otherwise: Yup.string().notRequired()
      }),
      zip_code: Yup.string().when([], {
        is: () => false,
        then: Yup.string().required("Ingresa tu código postal"),
        otherwise: Yup.string().notRequired()
      }),
      phone: Yup.string().when([], {
        is: () => true,
        then: Yup.string().required("Ingresa tu teléfono"),
        otherwise: Yup.string().notRequired()
      }),
      bank_account: Yup.string().when([], {
        is: () => false,
        then: Yup.string().required("Ingresa tu cuenta bancaria"),
        otherwise: Yup.string().notRequired()
      }),
      email: Yup.string().required("Ingresa un email válido"),
      password: Yup.string().required("Ingresa una contraseña segura"),
      passwordVerified: Yup.string().required("Confirma tu contraseña")
          .when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Las contraseñas no coinciden"
        ),
      }),
    }),
    onSubmit: (values) => {
      const userData = {
        business_name: values["business_name"],
        birthdate: values["birthdate"],
        address: values["address"],
        state: values["state"],
        zip_code: values["zip_code"],
        phone: values["phone"],
        bank_account:values["bank_account"],
        password: values["password"],
        passwordVerified: values["passwordVerified"],
        name: values["name"],
        paternal_surname: values["paternal_surname"],
        maternal_surname: values["maternal_surname"],
        email: values["email"],
        register: values["register"],
      };
      dispatch(onRegisterCraftsman(userData, props.router.navigate));
    }
  });

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  const { passwordVerifiedSuccess } = useSelector(state => ({
    passwordVerifiedSuccess: state.Account.passwordVerifiedSuccess,
  }));
  useEffect(() => {
    if (passwordVerifiedSuccess) {
      validation.resetForm();
    }
  }, [passwordVerifiedSuccess]);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 text-white">
                        <h3 className="text-primary text-white">Guelaguetza Designs </h3>
                        <p>Crea tu cuenta de artesano </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid"/>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                        <Alert color="danger" style={{marginTop: "13px"}}>
                          {forgetError}
                        </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                        <Alert color="success" style={{marginTop: "13px"}}>
                          {forgetSuccessMsg}
                        </Alert>
                    ) : null}

                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                    >

                      <Row>
                        <Col lg={12} md={12} xs={12} sm={12} className={"mb-3"}>
                          <h5>Completa la información tu cuenta</h5>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                          <div className="mb-3">
                            <Label className="form-label">Nombre de Taller o Negocio *</Label>
                            <Input
                                name="business_name"
                                type="text"
                                placeholder="Ingresa el nombre de tu taller o negocio"
                                validate={{
                                  required: {value: true},
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.business_name || ""}
                                invalid={
                                  validation.touched.business_name && validation.errors.business_name ? true : false
                                }
                            />
                            {validation.touched.business_name && validation.errors.business_name ? (
                                <FormFeedback type="invalid">{validation.errors.business_name}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <Label className="form-label">Nombre(s) *</Label>
                            <Input
                                name="name"
                                type="text"
                                placeholder="Ingresa el nombre"
                                validate={{
                                  required: {value: true},
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name && validation.errors.name ? true : false
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className={"col-lg-6"}>
                          <div className="mb-3">
                            <Label className="form-label">Apellido Paterno *</Label>
                            <Input
                                name="paternal_surname"
                                type="text"
                                placeholder="Ingresa el apellido paterno"
                                validate={{
                                  required: {value: true},
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.paternal_surname || ""}
                                invalid={
                                  validation.touched.paternal_surname && validation.errors.paternal_surname ? true : false
                                }
                            />
                            {validation.touched.paternal_surname && validation.errors.paternal_surname ? (
                                <FormFeedback type="invalid">{validation.errors.paternal_surname}</FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className={"col-lg-6 "}>
                          <div className="mb-3">
                            <Label className="form-label">Apellido Materno *</Label>
                            <Input
                                name="maternal_surname"
                                type="text"
                                placeholder="Ingresa el apellido aterno"
                                validate={{
                                  required: {value: true},
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.maternal_surname || ""}
                                invalid={
                                  validation.touched.maternal_surname && validation.errors.maternal_surname ? true : false
                                }
                            />
                            {validation.touched.maternal_surname && validation.errors.maternal_surname ? (
                                <FormFeedback type="invalid">{validation.errors.maternal_surname}</FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <Col md={12} sm={12} lg={12} className={"mb-12"}>
                          <div className="mb-3">
                            <Label className="form-label">Fecha de nacimiento </Label>
                            <Input
                                name="birthdate"
                                type="date"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: {value: true},
                                }}
                                value={validation.values.birthdate || ""}
                                invalid={
                                  validation.touched.birthdate && validation.errors.birthdate ? true : false
                                }
                            />
                            {validation.touched.birthdate && validation.errors.birthdate ? (
                                <FormFeedback type="invalid">{validation.errors.birthdate}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={12} className={"mb-12"}>
                          <div className="mb-3">
                            <Label className="form-label">Domicilio</Label>
                            <Input
                                name="address"
                                type="text"
                                validate={{
                                  required: {value: true},
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address && validation.errors.address ? true : false
                                }
                            />
                            {validation.touched.address && validation.errors.address ? (
                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6} md={6} xs={6} sm={6} className={"mb-6"}>
                          <div className="mb-3">
                            <Label className="form-label">Código Postal </Label>
                            <Input
                                name="zip_code"
                                type="text"
                                validate={{
                                  required: {value: true},
                                }}
                                placeholder="Ingresa el C.P. "
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.zip_code || ""}
                                invalid={
                                  validation.touched.zip_code && validation.errors.zip_code ? true : false
                                }
                            />
                            {validation.touched.zip_code && validation.errors.zip_code ? (
                                <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6} md={6} xs={6} sm={6} className={"mb-6"}>
                          <div className="mb-4">
                            <Label className="form-label">Estado *</Label>
                            <Input
                                name="state"
                                type="select"
                                validate={{
                                  required: {value: true},
                                }}
                                className="form-select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.state || ""}
                                invalid={
                                  validation.touched.state && validation.errors.state ? true : false
                                }
                            >
                              <option value={""}>Elija una opción</option>
                              {
                                states.map((item) => {
                                  return <option key={item.key} value={item.id}>{item.name}</option>
                                })
                              }
                            </Input>
                            {validation.touched.state && validation.errors.state ? (
                                <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className={"mb-3"}>
                          <div className="mb-3">
                            <Label className="form-label">Teléfono *</Label>
                            <Input
                                name="phone"
                                type="tel"
                                placeholder="Ingresa el teléfono "
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                                }
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className={"mb-3"}>
                          <div className="mb-3">
                            <Label className="form-label">CLABE Interbancaria</Label>
                            <Input
                                name="bank_account"
                                type="text"
                                placeholder="Ingresa tu CLABE interbancaria de 18 DÍGITOS"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.bank_account || ""}
                                invalid={
                                  validation.touched.bank_account && validation.errors.bank_account ? true : false
                                }
                            />
                            {validation.touched.bank_account && validation.errors.bank_account ? (
                                <FormFeedback type="invalid">{validation.errors.bank_account}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>

                      <div className={"col-lg-12"}>
                        <div className="mb-3">
                          <Label className="form-label">Correo electrónico *</Label>
                          <Input
                              name="email"
                              type="email"
                              placeholder="Lo usarás para iniciar sesión "
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                          />
                          {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                      </div>


          <Col className={"mb-3"}>
            <h5>Crea una contraseña para tu cuenta</h5>
          </Col>
          <Col className={"mb-3"}>
            <div className="mb-3">
              <Label className="form-label">Password *</Label>
              <Input
                  name="password"
                  className="form-control text-center"
                  placeholder="Crea una contraseña segura"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password ? true : false
                  }
              />
              {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col className={"mb-3"}>
            <div className="mb-3">
              <Label className="form-label">Confirma tu Password *</Label>
              <Input
                  name="passwordVerified"
                  className="form-control text-center"
                  placeholder="Ingresa de nuevo tu contraseña"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.passwordVerified || ""}
                  invalid={
                    validation.touched.passwordVerified && validation.errors.passwordVerified ? true : false
                  }
              />
              {validation.touched.passwordVerified && validation.errors.passwordVerified ? (
                  <FormFeedback type="invalid">{validation.errors.passwordVerified}</FormFeedback>
              ) : null}
            </div>
          </Col>

          <Row className="mb-3">
            <Col className="text-end">
              <button
                  className="btn btn-success w-md "
                  type="submit"
              >
                Registrarse Ahora
              </button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-center">
              ¿Ya tienes contraseña? {" "}
              <Link to="/login" className="btn btn-info">
                Inicia Sesión
              </Link>{" "}
            </Col>
          </Row>
        </Form>
      </div>
    </CardBody>
</Card>
  <div className="mt-5 text-center">
    <p>
      © {new Date().getFullYear()}
    </p>
  </div>
</Col>
</Row>
</Container>
</div>
</React.Fragment>
)
  ;
};

export default withRouter(Register);

Register.propTypes = {
  history: PropTypes.object,
};
