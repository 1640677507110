import {
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL, SET_ORDER_EDIT, SET_MODAL_ORDER_EDIT_SHOW
} from "./actions";

const INIT_STATE = {
  products: [],
  product: {},
  orders: [],
  order: {},
  error: {},
  modalOrderEditShow:false,
  getorderDataSuccess:false
};

const Ecommerce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.data,
        getorderDataSuccess:action.payload.success
      };

    case GET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        orders: state.orders.map(order =>
          (order.id + '') === (action.payload.id + '')
            ? { order, ...action.payload }
            : order
        ),
      };

    case UPDATE_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

      case SET_ORDER_EDIT:
      return {
        ...state,
        order: action.payload,
      };

      case SET_MODAL_ORDER_EDIT_SHOW:
      return {
        ...state,
        modalOrderEditShow: action.payload,
      };

    default:
      return state;
  }
};

export default Ecommerce;
