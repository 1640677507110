export const GET_CAT_TYPE_CRAFTSMAN_LIST = "GET_CAT_TYPE_CRAFTSMAN_LIST"
export const GET_CAT_TYPE_CRAFTSMAN_SUCCESS = "GET_CAT_TYPE_CRAFTSMAN_SUCCESS"
export const GET_CAT_TYPE_CRAFTSMAN_FAIL = "GET_CAT_TYPE_CRAFTSMAN_FAIL"
export const SAVE_CAT_TYPE_CRAFTSMAN = "SAVE_CAT_TYPE_CRAFTSMAN"
export const SAVE_CAT_TYPE_CRAFTSMAN_SUCCESS = "SAVE_CAT_TYPE_CRAFTSMAN_SUCCESS"
export const SAVE_CAT_TYPE_CRAFTSMAN_FAIL = "SAVE_CAT_TYPE_CRAFTSMAN_FAIL"
export const UPDATE_CAT_TYPE_CRAFTSMAN = "UPDATE_CAT_TYPE_CRAFTSMAN"
export const UPDATE_CAT_TYPE_CRAFTSMAN_SUCCESS = "UPDATE_CAT_TYPE_CRAFTSMAN_SUCCESS"
export const UPDATE_CAT_TYPE_CRAFTSMAN_FAIL = "UPDATE_CAT_TYPE_CRAFTSMAN_FAIL"
export const DELETE_CAT_TYPE_CRAFTSMAN = "DELETE_CAT_TYPE_CRAFTSMAN"
export const DELETE_CAT_TYPE_CRAFTSMAN_SUCCESS = "DELETE_CAT_TYPE_CRAFTSMAN_SUCCESS"
export const DELETE_CAT_TYPE_CRAFTSMAN_FAIL = "DELETE_CAT_TYPE_CRAFTSMAN_FAIL"



export const getCatTypeCraftsmanList = () => ({
  type: GET_CAT_TYPE_CRAFTSMAN_LIST,
})

export const getCatTypeCraftsmanSuccess = catTypeCraftsmanList => ({
  type: GET_CAT_TYPE_CRAFTSMAN_SUCCESS,
  payload: catTypeCraftsmanList,
})

export const getCatTypeCraftsmanFail = error => ({
    type: GET_CAT_TYPE_CRAFTSMAN_FAIL,
    payload: error,
  })

  export const saveCatTypeCraftsman = catTypeCratfsman => ({
    type: SAVE_CAT_TYPE_CRAFTSMAN,
    payload:catTypeCratfsman,
  })
  
export const saveCatTypeCraftsmanSuccess = catTypeCraftsmanList => ({
    type: SAVE_CAT_TYPE_CRAFTSMAN_SUCCESS,
    payload: catTypeCraftsmanList,
  })
  
export const saveCatTypeCraftsmanFail = error => ({
    type: SAVE_CAT_TYPE_CRAFTSMAN_FAIL,
    payload: error,
  })

export const updateCatTypeCraftsman = catTypeCratfsman => ({
    type: UPDATE_CAT_TYPE_CRAFTSMAN,
    payload:catTypeCratfsman,
})

export const updateCatTypeCraftsmanSuccess = catTypeCraftsmanList => ({
    type: SAVE_CAT_TYPE_CRAFTSMAN_SUCCESS,
    payload: catTypeCraftsmanList,
})

export const updateCatTypeCraftsmanFail = error => ({
    type: SAVE_CAT_TYPE_CRAFTSMAN_FAIL,
    payload: error,
})

export const deleteCatTypeCraftsman = catTypeCratfsman => ({
    type: DELETE_CAT_TYPE_CRAFTSMAN,
    payload:catTypeCratfsman,
})

export const deleteCatTypeCraftsmanSuccess = catTypeCraftsmanList => ({
    type: SAVE_CAT_TYPE_CRAFTSMAN_SUCCESS,
    payload: catTypeCraftsmanList,
})

export const deleteCatTypeCraftsmanFail = error => ({
    type: SAVE_CAT_TYPE_CRAFTSMAN_FAIL,
    payload: error,
})
