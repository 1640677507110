

export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const GET_DATA_ADIC_ACCOUNT_BY_SESSION = "GET_DATA_ADIC_ACCOUNT_BY_SESSION"
export const GET_DATA_ADIC_ACCOUNT_BY_SESSION_SUCCESS = "GET_DATA_ADIC_ACCOUNT_BY_SESSION_SUCCESS"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}


export const getProfile = data => {
  return {
    type: GET_DATA_ADIC_ACCOUNT_BY_SESSION,
    payload: data,
  }
}

export const setProfile = data => {
  return {
    type: GET_DATA_ADIC_ACCOUNT_BY_SESSION_SUCCESS,
    payload: data,
  }
}