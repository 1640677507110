import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//i18n
import { withTranslation } from "react-i18next";
import TableContainerCustom from "../../components/Common/TableContainerCustom";
import {Link} from "react-router-dom";
import {
    Container, UncontrolledTooltip,
    Button,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {states} from "../../common/data";
import { isEmpty, map } from "lodash";
import toastr from "toastr";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {useSelector, useDispatch } from "react-redux";

import {
    getArtisansList as onGetArtisansList,
    saveArtisan as onSaveArtisan, modalArtisanShow as onModalArtisanShow,
    updateArtisan as onUpdateArtisan, deleteArtisan as onDeleteArtisan
} from "store/artisan/actions";
import {getCatTypeCraftsmanList as onGetCatTypeCraftsmanList} from "../../store/catTypeCraftsman/actions";
import {sendMailVerification as onSendMailVerification} from "../../store/auth/register/actions";
import {PORTAL_URL} from "../../helpers/api_helper";

const ArtisanList = props => {

    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [order, setOrder] = useState(null);

    const [artisan, setArtisan] = useState(null);

    const dispatch = useDispatch();

    const [artisansList, setArtisansList] = useState([]);
    const inputUrlRef = useRef(null);

    const { artisanList,artisanModalShow } = useSelector(state => ({
        artisanList: state.artisans.artisanList,
        artisanModalShow: state.artisans.artisanModalShow,
      }));

    const { catTypeCraftsmanList } = useSelector(state => ({
        catTypeCraftsmanList: state.CatTypeCraftsman.catTypeCraftsmanList,
    }));
    

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            business_name: (artisan && artisan.business_name) || '',
            name: (artisan && artisan.name) || '',
            paternal_surname: (artisan && artisan.paternal_surname) || '',
            maternal_surname: (artisan && artisan.maternal_surname) || '',
            birthdate: (artisan && artisan.birthdate) || '',
            address: (artisan && artisan.address) || '',
            zip_code: (artisan && artisan.zip_code) || '',
            state: (artisan && artisan.state) || '',
            category: (artisan && artisan.cat_type_craftsman_id) || '',
            phone: (artisan && artisan.phone) || '',
            bank_account: (artisan && artisan.bank_account) || '',
            email: (artisan && artisan.email) || '',
            status: (artisan && artisan.status) || '',
        },
        validationSchema: Yup.object({
            business_name: Yup.string().required("Por favor ingresa el nombre del taller"),
            name: Yup.string().required("Por favor ingresa el nombre"),
            paternal_surname: Yup.string().required("Ingresa el apellido paterno"),
            maternal_surname: Yup.string().required("Ingresa el apellido materno"),
            //birthdate: Yup.string().required("Ingresa la fecha de nacimiento"),
            /*total: Yup.string().matches(
                /[0-9\.\-\s+\/()]+/,
                "Ingresa el domicilio"
            ).required("Ingresa el domicilio"),*/
            //address: Yup.string().required("Ingresa el domicilio "),
            state: Yup.string().required("Elige el estado"),
            //zip_code: Yup.string().required("Ingresa el código postal"),
            category: Yup.string().required("Eligue la categoría"),
            phone: Yup.string().required("Ingresa el teléfono"),
            //bank_account: Yup.string().required("Ingresa la cuenta bancaria"),
            email: Yup.string().required("Ingresa el email "),
            status: Yup.string().when([], {
                is: () => isEdit,
                then: Yup.string().required("Elige el status"),
                otherwise: Yup.string().notRequired()
            }),
        }),
        onSubmit: (values) => {
            if (isDelete) {
                // delete artisan
                dispatch(onDeleteArtisan(artisan));
            }
            else if (isEdit){
                const updateArtisan = {
                    id: artisan.id,
                    business_name: values["business_name"],
                    name: values["name"],
                    paternal_surname: values["paternal_surname"],
                    maternal_surname: values["maternal_surname"],
                    birthdate: values["birthdate"],
                    address: values["address"],
                    zip_code: values["zip_code"],
                    state: values["state"],
                    cat_type_craftsman_id: values["cat_type_craftsman_id"],
                    phone: values["phone"],
                    bank_account: values["bank_account"],
                    email: values["email"],
                    category: values["category"],
                    status: values["status"],
                };
                // update artisan
                dispatch(onUpdateArtisan(updateArtisan));
            }
            else {
                const newArtisan = {
                    business_name: values["business_name"],
                    name: values["name"],
                    paternal_surname: values["paternal_surname"],
                    maternal_surname: values["maternal_surname"],
                    birthdate: values["birthdate"],
                    address: values["address"],
                    zip_code: values["zip_code"],
                    state: values["state"],
                    phone: values["phone"],
                    bank_account: values["bank_account"],
                    email: values["email"],
                    category: values["category"],
                };
                // save new artisan
                dispatch(onSaveArtisan(newArtisan));

            }
        },
    });

    const handleCopyToClipboard = (e) => {
        navigator.clipboard.readText(inputUrlRef).then(
            (clipText) => (inputUrlRef.current.select().innerText += clipText)
        ).catch(
            function () {
                toastr.error("No se pudo copiar al portapapeles.","Plataforma Guelaguetza");
            }
        )

        e.target.focus();
    }

    const handleArtisanDeleteClick = args => {
        setArtisan(args)
        setIsEdit(false)
        setIsDelete(true)
        toggle()
    }

    const handleArtisanClick = args => {
        validation.resetForm();
        dispatch(onGetCatTypeCraftsmanList());
        setArtisan(args);
        setIsEdit(true);
        setIsDelete(false);
        toggle();
    };

    const handleArtisanCreateClick = () => {
        dispatch(onGetCatTypeCraftsmanList());
        setIsEdit(false);
        setIsDelete(false);
        toggle();
    };

    const handleSendMsgVerification = (arg) => {
        dispatch(onSendMailVerification(arg));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Clave',
                accessor: 'key',
            },
            {
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Categoría',
                accessor: 'cat_type_craft_name'
            },
            {
                Header: 'Estado',
                accessor: 'cat_state_name'
            },
            {
                Header: 'Teléfono',
                accessor: 'phone'
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: 'Link de Activacion',
                accessor: 'link',
                Cell: (cellProps) => {
                    return (
                        cellProps.row.original.remember_token !=="" ?
                            <>
                                <button onClick={()=>{
                                    const artisanData = cellProps.row.original;
                                    handleSendMsgVerification(artisanData)}}
                                        className="btn-sm btn btn-success referralLink">Enviar por email</button>

                        <CopyToClipboard text={cellProps.row.original?PORTAL_URL+"activate/"+cellProps.row.original.remember_token:""}
                                         onCopy={() => toastr.success("Link copiado al portapapeles","Guelaguetza Platform")}>
                            <button className="btn-sm btn btn-info referralLink">Copiar Link</button>
                        </CopyToClipboard>
                        </>:""
                    );
                }
            },
            {
                Header: 'Opciones',
                accessor: 'options',
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const artisanData = cellProps.row.original;
                                    handleArtisanClick(artisanData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Editar
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const data = cellProps.row.original;
                                    handleArtisanDeleteClick(data);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Borrar
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    //meta title
    document.title = "Lista de artesanos | Guelaguetza Designs";

    const toggle = () => {
        let artisanMShowLocal = !artisanModalShow
        dispatch(onModalArtisanShow(artisanMShowLocal))
    };

     useEffect(() => {
        setArtisansList(artisanList);
    }, [artisanList]);

    useEffect(() => {
        dispatch(onGetArtisansList());
    }, [dispatch]);

    useEffect(() => {
        if (!isEmpty(artisanList)) setArtisansList(artisanList);
    }, [artisanList]);

    useEffect(() => {
        if(artisanModalShow===false) {
            setArtisan(null)
        }
    }, [artisanModalShow]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Artesanos")}
                        breadcrumbItem={props.t("Listado")}
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainerCustom
                                        data={artisanList}
                                        isAddUserList={true}
                                        handleArtisanClick={handleArtisanCreateClick}
                                        isGlobalFilter={false}
                                        isAddOptions={false}
                                        customPageSize={10} columns={columns} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal size={"lg"} isOpen={artisanModalShow} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit ? "Editar Artesano" : !!isDelete ? "Dar de Baja a Artesano" : "Agregar Artesano"}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Card>
                            <CardBody>
                        <Row>
                            <div className="col-lg-12">
                                <div className="mb-3">
                                            <Label className="form-label">Nombre de Taller o Negocio *</Label>
                                            <Input
                                                name="business_name"
                                                type="text" disabled={isDelete}
                                                placeholder="Ingresa el nombre del taller o negocio del artesano"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.business_name || ""}
                                                invalid={
                                                    validation.touched.business_name && validation.errors.business_name ? true : false
                                                }
                                            />
                                            {validation.touched.business_name && validation.errors.business_name ? (
                                                <FormFeedback type="invalid">{validation.errors.business_name}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <Label className="form-label">Nombre(s) *</Label>
                                    <Input
                                        name="name" disabled={isDelete}
                                        type="text"
                                        placeholder="Ingresa el nombre"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className={"col-lg-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Apellido Paterno *</Label>
                                            <Input
                                                name="paternal_surname"
                                                type="text" disabled={isDelete}
                                                placeholder="Ingresa el apellido paterno"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.paternal_surname || ""}
                                                invalid={
                                                    validation.touched.paternal_surname && validation.errors.paternal_surname ? true : false
                                                }
                                            />
                                            {validation.touched.paternal_surname && validation.errors.paternal_surname ? (
                                                <FormFeedback type="invalid">{validation.errors.paternal_surname}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-lg-4 "}>
                                <div className="mb-3">
                                            <Label className="form-label">Apellido Materno *</Label>
                                            <Input
                                                name="maternal_surname"
                                                type="text" disabled={isDelete}
                                                placeholder="Ingresa el apellido aterno"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.maternal_surname || ""}
                                                invalid={
                                                    validation.touched.maternal_surname && validation.errors.maternal_surname ? true : false
                                                }
                                            />
                                            {validation.touched.maternal_surname && validation.errors.maternal_surname ? (
                                                <FormFeedback type="invalid">{validation.errors.maternal_surname}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-lg-4 col-sm-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Fecha de nacimiento </Label>
                                            <Input
                                                name="birthdate"
                                                type="date" disabled={isDelete}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur} 
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                value={validation.values.birthdate || ""}
                                                invalid={
                                                    validation.touched.birthdate && validation.errors.birthdate ? true : false
                                                }
                                            />
                                            {validation.touched.birthdate && validation.errors.birthdate ? (
                                                <FormFeedback type="invalid">{validation.errors.birthdate}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-lg-8"}>
                                <div className="mb-3">
                                            <Label className="form-label">Domicilio</Label>
                                    <Input
                                        name="address"
                                        type="text"  disabled={isDelete}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.address || ""}
                                        invalid={
                                            validation.touched.address && validation.errors.address ? true : false
                                        }
                                    />
                                            {validation.touched.address && validation.errors.address ? (
                                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-lg-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Código Postal</Label>
                                            <Input
                                                name="zip_code"
                                                type="text"  disabled={isDelete}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                placeholder="Ingresa el C.P. "
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.zip_code || ""}
                                                invalid={
                                                    validation.touched.zip_code && validation.errors.zip_code ? true : false
                                                }
                                            />
                                            {validation.touched.zip_code && validation.errors.zip_code ? (
                                                <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-lg-4"}>
                                <div className="mb-4">
                                            <Label className="form-label">Estado *</Label>
                                            <Input
                                                name="state"
                                                type="select" disabled={isDelete}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.state || ""} 
                                                invalid={
                                                    validation.touched.state && validation.errors.state ? true : false
                                                }
                                            >
                                                <option value={""}>Elija una opción</option>
                                                {
                                                    states.map((item)=>{
                                                        return <option key={item.key} value={item.id}>{item.name}</option>
                                                    })
                                                }
                                            </Input>
                                            {validation.touched.state && validation.errors.state ? (
                                                <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-lg-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Categoría *</Label>
                                            <Input
                                                name="category"
                                                type="select" disabled={isDelete}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.category || ""}
                                                invalid={
                                                    validation.touched.category && validation.errors.category ? true : false
                                                }
                                            >
                                                <option value={""}>Elija una opción</option>
                                                {
                                                    catTypeCraftsmanList?.map((item)=>{
                                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                                    })
                                                }
                                            </Input>
                                            {validation.touched.category && validation.errors.category ? (
                                                <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-lg-6"}>
                                <div className="mb-3">
                                    <Label className="form-label">Teléfono *</Label>
                                    <Input
                                        name="phone"
                                        type="tel" disabled={isDelete}
                                        placeholder="Ingresa el teléfono "
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.phone || ""}
                                        invalid={
                                            validation.touched.phone && validation.errors.phone ? true : false
                                        }
                                    />
                                    {validation.touched.phone && validation.errors.phone ? (
                                        <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className={"col-lg-6"}>
                                <div className="mb-3">
                                    <Label className="form-label">Cuenta Interbancaria</Label>
                                    <Input
                                        name="bank_account"
                                        type="text" disabled={isDelete}
                                        placeholder="Ingresa la cuenta bancaria del artesano"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.bank_account || ""}
                                        invalid={
                                            validation.touched.bank_account && validation.errors.bank_account ? true : false
                                        }
                                    />
                                    {validation.touched.bank_account && validation.errors.bank_account ? (
                                        <FormFeedback type="invalid">{validation.errors.bank_account}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            <div className={"col-lg-6"}>
                                <div className="mb-3">
                                    <Label className="form-label">Email *</Label>
                                    <Input
                                        name="email"
                                        type="email" disabled={artisan?.id}
                                        placeholder="Ingresa el email "
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                            {
                                isEdit ?
                                    <div className={"col-lg-6"}>
                                        <div className="mb-3">
                                            <Label className="form-label">Status *</Label>
                                            <select
                                                name="status"
                                                id="statuss"

                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.status || ""}
                                                className={`form-control mb-3 ${validation.touched.status && validation.errors.status ? 'is-invalid' : ''}`}>
                                                <option value={""}>Elige</option>
                                                <option value={"Activo"}>Activo</option>
                                                <option value={"Inactivo"}>Inactivo</option>
                                                <option value={"Baja"}>Baja</option>
                                            </select>
                                            {validation.touched.status && validation.errors.status ? (
                                                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>:""
                            }
                        </Row>
                                <Row> <Col lg={12}>
                                    <label>Los campos con * son obligatorios </label>
                                </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className={isDelete ? "btn btn-danger save-user" : "btn btn-success save-user"}
                                    >
                                        {isEdit ? "Guardar Cambios" : isDelete ? "Dar de Baja" : "Guardar Registro"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

ArtisanList.propTypes = {
    artisanList: PropTypes.array,
    onGetArtisansList: PropTypes.func,
};


export default withTranslation()(ArtisanList);
