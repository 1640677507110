import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import {
  ACCEPTED_TERMS_SAVE,
  GET_DATA_ADIC_ACCOUNT, getDataAdicAccountSuccess,
  MAIL_VERIFICATION_SEND,
  PASSWORD_VERIFIED,
  passwordVerifiedSuccess, REGISTER_CRAFTSMAN,
  REGISTER_USER
} from "./actions"
import { registerUserSuccessful, registerUserFailed } from "./actions"


import {
  getDataAdicAccountVerification,
  registerCraftsmanSave,acceptedTermsCraftsmanSave,
  postPasswordVerified, sendEmailVerification
} from "../../../helpers/backend_helper"
import {apiError, loginSuccess} from "../login/actions";
import {LOGOUT_USER} from "../login/actionTypes";
import toastr from "toastr";
import {setProfile} from "../profile/actions";

// initialize relavant method of both Auth

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  console.log("using the following url for registration: ")
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      //const response = yield call(postJwtRegister, "/post-jwt-register", user)
      //yield put(registerUserSuccessful(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "genuine") {
      /*const response = yield call(postFakeRegister, user)
      yield put(registerUserSuccessful(response))*/
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* onPasswordVerified({ payload: { user, history } }) {
  try {
    const response = yield call(postPasswordVerified, user);
    if(response.success) {
      //sessionStorage.setItem("authUser", JSON.stringify(response.data));
      //yield put(loginSuccess(response.data));
      toastr.success(response.message, "Plataforma Guelaguetza")
      yield put(passwordVerifiedSuccess(response.data));
      //history('/dashboard');
      //window.location.replace("/dashboard")
    } else {
      //yield put(passwordVerifiedSuccess(response))
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(passwordVerifiedSuccess(error))
  }
}
function* onRegisterCraftsmanSave({ payload: { user, history } }) {
  try {
    const response = yield call(registerCraftsmanSave, user);
    if(response.success) {
      yield put(passwordVerifiedSuccess(response.data));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else {
      //yield put(passwordVerifiedSuccess(response))
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(passwordVerifiedSuccess(error))
  }
}

function* onAcceptedTermsCraftsmanSave({ payload: artisan }) {
  try {
    const response = yield call(acceptedTermsCraftsmanSave, artisan);
    if(response.success) {
      yield put(setProfile(response.data.profile));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else {
      yield put(passwordVerifiedSuccess(response))
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.log("There was an error in save: ", error)
    yield put(passwordVerifiedSuccess(error))
  }
}

function* onSendMailVerification({ payload: user}) {
  try {
    const response = yield call(sendEmailVerification, user);
    if(response.success) {
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else {
      yield put(passwordVerifiedSuccess(response))
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(passwordVerifiedSuccess(error))
  }
}

function* onGetDataAdictAcountByVerification({ payload: key}) {
  try {
    const response = yield call(getDataAdicAccountVerification, {key:key});
    if(response.success) {
      yield put(getDataAdicAccountSuccess(response.data))
    }
  } catch (error) {
    console.log("There was an error registering: ", error)
  }
}


function* accountSaga() {
  yield all([fork(watchUserRegister)])
  yield takeEvery(PASSWORD_VERIFIED, onPasswordVerified);
  yield takeEvery(REGISTER_CRAFTSMAN, onRegisterCraftsmanSave);
  yield takeEvery(ACCEPTED_TERMS_SAVE, onAcceptedTermsCraftsmanSave);
  yield takeEvery(MAIL_VERIFICATION_SEND, onSendMailVerification);
  yield takeEvery(GET_DATA_ADIC_ACCOUNT, onGetDataAdictAcountByVerification);
}

export default accountSaga
