import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
  Button, Card, CardBody, CardTitle, Form, FormFeedback, Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"

import { useFormik } from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";

import {
  updateTrackingOrders as onUpdateTrackingOrders} from "../../store/e-commerce/actions";
import {GET_ARTISAN_SUCCESS, getArtisan as onGetArtisan} from "../../store/artisan/actions";
import {getCatShippingCarrierList as onGetCatShippingCarrierList} from "../../store/catShippingCarrier/actions";


const EcommerceOrdersModal = props => {
  const { isOpen, toggle, products } = props;
  const [ isOpenCraftsmanDet, setIsOpenCraftsmanDet ] = useState(false);
  const [role, setRole] = useState({});
  const [editTracking, setEditTracking] = useState(true);
  const [tracking, setTracking] = useState({});
  const [status, setStatus] = useState("");

  const [catStatus,setCatStatus] = useState([
    {id:1,label:"Pendiente"},
    {id:2,label:"En Tránsito"},
    {id:3,label:"Recibido"},
  ])

  const { catShippingCarrierList } = useSelector(state => ({
    catShippingCarrierList: state.CatShippingCarrier.catShippingCarrierList,
  }));

  const { order } = useSelector(state => ({
    order: state.Ecommerce.order,
  }));

  const { artisan } = useSelector(state => ({
    artisan: state.artisans.artisan,
  }));

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if(obj.role_id)
      {
        setRole(obj.role_id)
      }
    }
  }, [props.success]);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(onGetCatShippingCarrierList());
  }, []);


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      number_tracking: (tracking && tracking.number_tracking) || '',
      shipping_carrier_id: (tracking && tracking.shipping_carrier_id) || "",
    },
    validationSchema: Yup.object({
      number_tracking: Yup.string().required("Por favor ingresa el número de tracking"),
      shipping_carrier_id: Yup.string().required("Por favor elige la paquetería"),
    }),
    onSubmit: (values) => {
      const updateOrder = {
        id: order.id,
        number_tracking: values.number_tracking,
        shipping_carrier_id: values.shipping_carrier_id,
        shipping_carrier_other: values.shipping_carrier_other,
        typeUpdate: "noAdmin",
        products:products
      };

          dispatch(onUpdateTrackingOrders(updateOrder));
          //setEditTracking(false)
    },
  });

  const validationAd = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (status && status) || '',
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Selecciona el status para actualizar"),
    }),
    onSubmit: (values) => {
      const updateOrder = {
          id: order.id,
          status: values.status,
          typeUpdate:"admin"
        };

          dispatch(onUpdateTrackingOrders(updateOrder));
          setEditTracking(false)
    },
  });

  const handleGetArtisanDetail = item => {
    dispatch(onGetArtisan({id:item.craftsman_id}))
    toggleCraftDetModal()
  }

  const toggleCraftDetModal = () => {
    let isOpen = !isOpenCraftsmanDet;
    setIsOpenCraftsmanDet(isOpen)
    if(!isOpen) {
      dispatch({type:GET_ARTISAN_SUCCESS,payload: {data:null}})
    }
  }

  return (
      <>
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true} size={props.size}
      centered={true}
      className="exampleModal"
      tabIndex="-1" contentClassName="modal-lg"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Seguimiento de envío</ModalHeader>
        <ModalBody>
          {
          role===1  &&
          (
          <>
            <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validationAd.handleSubmit();
                  return false;
                }}
            >
              <div className={"row"}>
                <div className={"col-md-6"}>
                  <div className="mb-3">
                    <Label className="form-label">Actualizar Status </Label>
                    <Input
                        name="status"
                        type="select"
                        onChange={validationAd.handleChange}
                        onBlur={validationAd.handleBlur}
                        value={validationAd.values.status || ""}
                        invalid={
                          validationAd.touched.status && validationAd.errors.status ? true : false
                        }
                    >
                      <option value={""}>Selecciona el status</option>
                      {
                        catStatus.map((item)=>
                            (<option key={item.id} value={item.label}>{item.label}</option>)
                        )

                      }
                    </Input>
                    {validationAd.touched.status && validationAd.errors.status ? (
                        <FormFeedback type="invalid">{validationAd.errors.status}</FormFeedback>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={"col-md-12 "}>
                  <div className="mb-3 text-end">
                    <button type="submit" className={"btn btn-sm btn-success"}> Guardar Cambios</button>
                  </div>
                </div>
              </div>
            </Form>
          </>)
          }

          {
            role===3 ? editTracking &&
                (
                    <>
                    <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                    >
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <div className="mb-3">
                        <Label className="form-label">Paquetería </Label>
                        <Input
                            name="shipping_carrier_id"
                            type="select"
                            placeholder="Ingresa el transportista "
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.shipping_carrier_id || ""}
                            invalid={
                              validation.touched.shipping_carrier_id
                              && validation.errors.shipping_carrier_id ? true : false
                            }
                        >
                          <option value={""}>Elige</option>
                            {
                                catShippingCarrierList.map(item=>
                                    (<option key={item.id} value={item.id}>{item.name}</option>))
                            }
                        </Input>
                        {validation.touched.shipping_carrier_id && validation.errors.shipping_carrier_id ? (
                            <FormFeedback type="invalid">{validation.errors.shipping_carrier_id}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className={"col-md-6"}>
                      <div className="mb-3">
                        <Label className="form-label">Número de Tracking </Label>
                        <Input
                            name="number_tracking"
                            type="text"
                            placeholder="Ingresa el número de tracking"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.number_tracking || ""}
                            invalid={
                              validation.touched.number_tracking && validation.errors.number_tracking ? true : false
                            }
                        />
                        {validation.touched.number_tracking && validation.errors.number_tracking ? (
                            <FormFeedback type="invalid">{validation.errors.number_tracking}</FormFeedback>
                        ) : null}
                      </div>
                    </div>


                  </div>
                  <div className="row">
                    <div className={"col-md-12 "}>
                      <div className="mb-3 text-end">
                        <button type="submit" className={"btn btn-sm btn-success"}> Guardar Cambios</button>
                      </div>
                    </div>
                  </div>
                    </Form>
                </>):
                (<p className="mb-4">
                  <h5>Detalle </h5>
                  <h6>
                    Paquetería: <span className="text-primary">{order?.shipping_carrier_name}</span></h6>

                  <h6>
                    Número de Tracking: <span className="text-primary">{order?.number_tracking}</span></h6>
                </p>)
          }
          {
            role===3 && !editTracking &&
              (<>
                <p className="mb-1">
                  <h5>Tracking information (optional)</h5>
                  <h6>
                    Paquetería: <span className="text-primary">{order?.shipping_carrier_name}</span></h6>

                  <h6>
                    Número de Tracking: <span className="text-primary">{order?.number_tracking}</span></h6>
                </p>
                <div className="row">
                <div className={"col-md-12 "}>
                  <div className="mb-4 ">
                    <button onClick={()=>setEditTracking(true)} className={"btn btn-sm btn-warning"}> Actualizar Tracking</button>
                  </div>
                </div>
              </div></>)
          }


          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Producto</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Artesano</th>
              </tr>
              </thead>
              <tbody>
              {
                products?.map(item =>
                    (<tr key={item.id}>
                  <th scope="row">
                    <div>
                      <img src={item.image!==null?JSON.parse(item.image).length>1?JSON.parse(item.image)[0]:"":""} alt="" className="avatar-sm"/>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">{item.name}</h5>
                      <div className="text-warning"><a href={"#"} onClick={()=>handleGetArtisanDetail(item)}> Artesano: {item.craftsman_name} </a></div>
                      <div className="text-warning"> Taller o Negocio: {item.business_name}</div>
                    </div>
                  </td>
                      <td>
                        <b>{item.quantity}</b>
                      </td>
                      <td>{item.craftsman_name}</td>
                </tr>))
              }

              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </div>
    </Modal>

  <Modal
      isOpen={isOpenCraftsmanDet}
      role="dialog"
      autoFocus={true} size={"lg"}
      centered={true}
      className=""
      tabIndex="1"
      toggle={toggleCraftDetModal}
  >
    <div className="modal-content">
      <ModalHeader toggle={toggle}>Detalles del Artesano </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                <tr>
                  <th scope="row">Clave:</th>
                  <td>{artisan?.key}</td>
                </tr>
                <tr>
                  <th scope="row">Nombre Completo:</th>
                  <td>{artisan?.name} {artisan?.paternal_surname} {artisan?.maternal_surname}</td>
                </tr>
                <tr>
                  <th scope="row">Negocio o Taller:</th>
                  <td>{artisan?.business_name}</td>
                </tr>
                <tr>
                  <th scope="row">Teléfono:</th>
                  <td>{artisan?.phone}</td>
                </tr>
                <tr>
                  <th scope="row">E-mail:</th>
                  <td>{artisan?.email}</td>
                </tr>
                <tr>
                  <th scope="row">Dirección:</th>
                  <td>{artisan?.address} {artisan?.zip_code} {artisan?.cat_state_name}</td>
                </tr>
                <tr>
                  <th scope="row">Tipo de Artesanía:</th>
                  <td>{artisan?.cat_type_craft_name} </td>
                </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" onClick={toggleCraftDetModal}>
          Cerrar
        </Button>
      </ModalFooter>
    </div>
  </Modal>
        </>
  )
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default EcommerceOrdersModal
