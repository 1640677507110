import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};

const OrderId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const Shipper = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};
const ProductTotal = (cell) => {
    return cell.value ? cell.value : '';
};
const Total = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return (
        <Badge
            className={"font-size-12 badge-soft-" +
                ((cell.value === "Completado" | cell.value === "Recibido") ? "success" : "danger" && cell.value === "Pendiente" ? "warning" : "warning")}
        >
            {cell.value}
        </Badge>
    );
};

const TrackingNumber = (cell) => {
    return cell.value ? cell.value : '';
};

const TrackingDhlNumber = (cell) => {
    return (
        <a target={"_blank"}  href={"https://www.mydhli.com/global-en/home/tracking.html?tracking-id="+cell.value} rel="noreferrer">
            {cell.value}
        </a>
    );
};
const TrackingFedexNumber = (cell) => {
    return (
        <a target={"_blank"}  href={"https://www.fedex.com/fedextrack/no-results-found?trknbr="+cell.value} rel="noreferrer">
            {cell.value}
        </a>
    );
};
const PaymentStatus = (cell) => {
    return (
        <>
            <Badge
                className={"font-size-12 badge-soft-" +
                    (cell.value === "paid" ? "success" : "danger" && cell.value === "Refund" ? "warning" : "danger")}
            >
                {cell.value}
            </Badge>

        </>
    );
};

const PaymentMethod = (cell) => {
    return (
        <span>
            <i
                className={
                    (cell.value === "Paypal" ? "fab fa-cc-paypal me-1" : "" ||
                        cell.value === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
                        cell.value === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
                        cell.value === "Visa" ? "fab fa-cc-visa me-1" : ""
                    )}
            />{" "}
            {cell.value}
        </span>
    );
};

export {
    OrderId,
    Shipper,
    Date,
    ProductTotal,
    Total,
    Status,
    PaymentStatus,
    PaymentMethod,
    TrackingNumber,
    TrackingDhlNumber,
    TrackingFedexNumber
};