import React, { useEffect, useState } from "react"
import {getCroppedImg, getCroppedImgBlobAlt, getRotatedImage} from "../../../helpers/canvasUtils";
import Cropper from 'react-easy-crop'
import imageCompression from "browser-image-compression";
import './style.css'
import { Card, CardBody, Form, Input } from "reactstrap";
import { getImageList as onGetImageList, 
    uploadImage as onUploadImage } from "store/imageGallery/actions";
    import {useSelector, useDispatch } from "react-redux";

import toastr from "toastr";

const CroppedImage = props => {

    const dispatch = useDispatch()

    const {imageUploadSuccess} = useSelector(state => ({
        imageUploadSuccess: state.ImageGallery.imageUploadSuccess
    })); 

    const [crop, setCrop] = useState({ x: 1, y: 1 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)

    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedImageBlob, setCroppedImageBlob] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [image, setImage] = useState(null);
    const [nameImage, setNameImage] = useState("");
    const [cropImageModalShow,setCropImageModalShow]=useState(false)

    const onCropComplete = async (croppedArea, croppedAreaPixelss) => {
        setCroppedAreaPixels(croppedAreaPixelss)
        try {
            const croppedImageBlobUp = await getCroppedImgBlobAlt(image, croppedAreaPixelss)
            setCroppedImageBlob(croppedImageBlobUp)
        } catch (e) {
            toastr.error("No se pudo obtener la imagen recotada, asegurate de usar una imagen jpg, png jpeg")
        }
        
    }

    const handleImageUpload  = async (event) => {
        const imageFile = event.target.files[0];
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            setImage(URL.createObjectURL(compressedFile))
            setNameImage(imageFile.name)
        } catch (error) {
            toastr.error("Hubo un error al subir la imagen, intenta nuevamente", "Plataforma Guelaguetza")
        }

    }

    const uploadFile = async () => {
        try {
            const formData = new FormData()
            formData.append("file", croppedImageBlob,nameImage)
            dispatch(onUploadImage(formData))
        } catch (err) {
            alert(err)
            toastr.error("Hubo un error al subir la imagen, intenta nuevamente", "Plataforma Guelaguetza")
        } 
    };

    const forceCanvasUpdate = () => {
        // Ajuste temporal si no se mueve el recorte
        setCrop(crop => ({ x: crop.x + 1, y: crop.y }));
        setTimeout(() => {
            setCrop(crop => ({ x: crop.x - 1, y: crop.y }));
        }, 100);
    };

    useEffect(()=> {
        if(imageUploadSuccess) {
            setImage(null);
            setCroppedImage(null);
            setCrop({ x: 0, y: 0 })
            setZoom(1)
            setCroppedAreaPixels(null)
        }
    },[imageUploadSuccess]);

    useEffect(() => {
        if (image) {
            forceCanvasUpdate();
        }
    }, [image]);

    return (
        <Card>
            <CardBody>
                {
                image ?
                <>
                <div className="crop-container">
                    <Cropper
                        image={image}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={4/4}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete} onZoomChange={setZoom} />
                </div>
                <div style={{position:"relative !important"}} className="footer  hstack gap-1">
                    <button className="btn btn-outline-secondary"  type="button"
                    onClick={() => { setImage(null) }}> Cambiar Imagen </button>
                    <button className="btn btn-outline-danger" type="button"
                    onClick={uploadFile}> Guardar imagen editada </button>
                </div>
                </> :
                <Form className="container"> 
                    <label className="mb-3 mt-3"> Sube una imagen desde tu equipo </label>
                    <Input className="form-control form-control-lg" accept=".jpg,.jpeg,.png"  onChange={handleImageUpload} 
                    id="formFileLg" type="file"/>
                </Form>
                }
            </CardBody>
        </Card>
    )
}

export default CroppedImage