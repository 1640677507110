import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { isEmpty } from "lodash";

import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';



import {
    OrderId,
    Date,
    Status,
    ProductTotal, TrackingNumber, TrackingDhlNumber, TrackingFedexNumber,
    Shipper,
}
    from "./ShippingCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import ShippingDetAndUpdateModal from "./ShippingDetAndUpdateModal";

import {
    Col,Button,
    Row,
    Input,
    Card,
    CardBody,
} from "reactstrap";

import {
    getShipping as onGetShipping,
    setModalShippingEditShow,
    setShippingEdit as onSetShippingEdit
} from "store/Shipping/actions";

function Shipping() {

    //meta title
    document.title = "Envíos | Guelaguetza Designs";

    const dispatch = useDispatch();
    const { shippingList,getShippingDataSuccess, modalShippingEditShow, shipping } = useSelector(state => ({
        shippingList: state.Shipping.shippingList,
        getShippingDataSuccess: state.Shipping.getShippingDataSuccess,
        modalShippingEditShow: state.Shipping.modalShippingEditShow,
        shipping: state.Shipping.shipping
    }));

    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const [role, setRole] = useState(obj?.role_id);

    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [shippList, setShippList] = useState([]);
    const [order, setOrder] = useState(null);
    const [productByShipping, setProductByShipping] = useState([]);


    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            orderId: (order && order.orderId) || '',
            billingName: (order && order.billingName) || '',
            orderdate: (order && order.orderdate) || '',
            total: (order && order.total) || '',
            paymentStatus: (order && order.paymentStatus) || 'Paid',
            badgeclass: (order && order.badgeclass) || 'success',
            paymentMethod: (order && order.paymentMethod) || 'Mastercard',
        },
        validationSchema: Yup.object({
            orderId: Yup.string()
                .matches(
                    /[0-9\.\-\s+\/()]+/,
                    "Please Enter Valid Order Id"
                ).required("Please Enter Your Order Id"),
            billingName: Yup.string().required("Please Enter Your Billing Name"),
            orderdate: Yup.string().required("Please Enter Your Order Date"),
            total: Yup.string().matches(
                /[0-9\.\-\s+\/()]+/,
                "Please Enter Valid Amount"
            ).required("Total Amount"),
            paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
            badgeclass: Yup.string().required("Please Enter Your Badge Class"),
            paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateOrder = {
                    id: order ? order.id : 0,
                    orderId: values.orderId,
                    billingName: values.billingName,
                    orderdate: values.orderdate,
                    total: values.total,
                    paymentStatus: values.paymentStatus,
                    paymentMethod: values.paymentMethod,
                    badgeclass: values.badgeclass,
                };
                // update order
                dispatch(onUpdateOrder(updateOrder));
                validation.resetForm();
            } else {
                const newOrder = {
                    id: Math.floor(Math.random() * (30 - 20)) + 20,
                    orderId: values["orderId"],
                    billingName: values["billingName"],
                    orderdate: values["orderdate"],
                    total: values["total"],
                    paymentStatus: values["paymentStatus"],
                    paymentMethod: values["paymentMethod"],
                    badgeclass: values["badgeclass"],
                };
                // save new order
                dispatch(onAddNewOrder(newOrder));
                validation.resetForm();
            }
            toggle();
        },
    });


    const toggleViewModal = () => {
        let modalShippingEditShowL = !modalShippingEditShow
        dispatch(setModalShippingEditShow(modalShippingEditShowL))
        if(!modalShippingEditShowL) {
            setProductByShipping([])
        }
        //setModal1(!modal1);
        //window.location.reload();
    }

    useEffect(() => {
        dispatch(onGetShipping());
    }, []);

    useEffect(() => {
        setShippList(shippingList);
    }, [shippingList]);

    useEffect(() => {
        if (!isEmpty(shippingList) && !!isEdit) {
            setShippList(shippingList);
            setIsEdit(false);
        }
    }, [shippingList]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setOrder(null);
        } else {
            setModal(true);
        }
    };

    const handleOrderShippingCarrierClick = arg => {
        const shipp = arg;
        dispatch(onSetShippingEdit(shipp))
        //setS(order);
        setIsEdit(true);
        toggleViewModal()
    };

    //delete order
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (order) => {
        setOrder(order);
        setDeleteModal(true);
    };

    const handleDeleteOrder = () => {
        if (order && order.id) {
            dispatch(onDeleteOrder(order.id));
            setDeleteModal(false);
        }
    };
    const handleOrderClicks = () => {
        setShippList("");
        setIsEdit(false);
        toggle();
    };

    const handleProductInShipping = (data,e) => {
        const products = productByShipping
        let productExists = products.findIndex(item=> item.id===data.id);
        if(productExists===-1 && e.target.checked) {
            products.push(data);
        }
        if(productExists>-1 && !e.target.checked) {
            products.splice(productExists,1);
        }
        setProductByShipping(products)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Paquetería',
                accessor: 'shipping_carrier_name',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Shipper {...cellProps} />;
                }
            },
            {
                Header: 'Guía',
                accessor: 'code',
                disableFilters: true,
                Cell: (cellProps) => {
                    const orderData = cellProps.row.original;
                    let carrier=orderData?.shipping_carrier_name?.toLowerCase()
                    switch (carrier) {
                        case "dhl":
                            return <TrackingDhlNumber {...cellProps} />;
                            break;
                        case "fedex":
                            return <TrackingFedexNumber {...cellProps} />;
                            break;
                        default:
                            return <TrackingNumber {...cellProps} />
                            break;
                    }
                }
            },
            {
                Header: 'Fecha',
                accessor: 'date_shipping_f',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
           {
                Header: 'Opciones',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const shippData = cellProps.row.original;
                                    handleOrderShippingCarrierClick(shippData);
                                }}
                            >
                                <i className="fa fa-eye"></i>
                            </Button>
                        </div>
                    );
                }
            },
        ],
        []
    );


    return (
        <React.Fragment>
            <ShippingDetAndUpdateModal products={shipping?.details} shipping={shipping} size={"xl"} isOpen={modalShippingEditShow} edit={isEdit}
                                  toggle={toggleViewModal} />

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Lista" breadcrumbItem="Códigos de Envío" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={shippingList}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        section={""}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                </div>
            </div>
        </React.Fragment>
    );
}
Shipping.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default Shipping;