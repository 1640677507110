import React from "react"
import {Card, CardBody, Col} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import RecentProducts from "../artisan/RecentProducts";

const ProductCard = ({product,key, rolePrivate}) => {

    const navigate = useNavigate();

    return (
        <Col xl={4} sm={6} key={"_col_" + key} style={{cursor:"pointer"}}>
            <Card
                onClick={() =>
                    navigate(
                        `/product-edit/${product.uuid}`
                    )
                }
            >
                <CardBody>
                    <div className="product-img position-relative">
                        {product.shopify_id ? (
                            <div className="avatar-sm product-ribbon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i style={{fontSize:"40px"}} className=" bx bxl-shopify"></i>
                                                            </span>
                            </div>
                        ) : null}
                        <img
                            src={ product.image!==null?JSON.parse(product.image).length>0?JSON.parse(product.image)[0].url_public:"":""}
                            alt=""
                            className="img-fluid mx-auto d-block"
                        />
                    </div>
                    <div className="mt-4 text-center">
                        <h5 className="mb-3 text-truncate">
                            <Link
                                to={"/product-edit/" + product.uuid}
                                className="text-dark"
                            >
                                {product.title}{" "}
                            </Link>
                        </h5>
                        {
                            rolePrivate===1 && (
                                <div className="text-black mb-3">
                                    Artesano: {product.craftsman_name}
                                </div>
                            )}
                        <div className="text-muted mb-3">
                            En Shopify: {product.shopify_id ? product.shopify_status:"No Publicado"}
                        </div>
                        <h5 className="my-0">

                            <b style={{display:"none"}}>${product.price_artisan }</b>
                        </h5>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

RecentProducts.propTypes = {
    product: PropTypes.object,
    key: PropTypes.number,
    rolePrivate: PropTypes.object
}

export default ProductCard
