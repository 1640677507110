import { takeEvery, put, call } from "redux-saga/effects";
import {
    GET_CAT_SHIPPING_CARRIER_LIST, SAVE_CAT_SHIPPING_CARRIER,
    UPDATE_CAT_SHIPPING_CARRIER, DELETE_CAT_SHIPPING_CARRIER
} from "./actions"
import {
    getCatShippingCarrierSuccess,
    getCatShippingCarrierFail,
    saveCatShippingCarrierSuccess,
    saveCatShippingCarrierFail, updateCatShippingCarrierSuccess, updateCatShippingCarrierFail,
    deleteCatShippingCarrierSuccess, deleteCatShippingCarrierFail
} from "./actions"
import {saveNewCatShippingCarrier, getCatShippingCarrierData,
    updateCatShippingCarrier, deleteCatShippingCarrier } from "../../helpers/backend_helper"
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchCatShippingCarrierList () {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(getCatShippingCarrierData)
      yield put(getCatShippingCarrierSuccess(response))
    } catch (error) {
        console.info(error)
      yield put(getCatShippingCarrierFail(error))
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

function* onSaveNewCatShippingCarrier ({ payload: catShippingCarrier }) {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
        yield put(saveCatShippingCarrierFail({success:true}));
        const response = yield call(saveNewCatShippingCarrier, catShippingCarrier);
        if(response.success)
        {
            yield put(getCatShippingCarrierSuccess(response))
            toastr.success(response.message, "Plataforma Guelaguetza")
        } else
        {
            yield put(saveCatShippingCarrierFail(response));
            toastr.error(response.message, "Plataforma Guelaguetza")
        }
    } catch (error) {
        console.info(error)
        yield put(saveCatShippingCarrierFail(error));
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

function* onUpdateCatShippingCarrier ({ payload: catShippingCarrier }) {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
        yield put(saveCatShippingCarrierFail({success:true}));
        const response = yield call(updateCatShippingCarrier,catShippingCarrier);
        console.info(response)
        if(response.success)
        {
            yield put(getCatShippingCarrierSuccess(response))
            toastr.success(response.message, "Plataforma Guelaguetza")
        } else
        {
            yield put(updateCatShippingCarrierFail(response));
            toastr.error(response.message, "Plataforma Guelaguetza")
        }
    } catch (error) {
        console.info(error)
        yield put(saveCatShippingCarrierFail(error));
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}


function* onDeleteCatShippingCarrier ({ payload: catShippingCarrier }) {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
        yield put(saveCatShippingCarrierFail({success:true}));
        const response = yield call(deleteCatShippingCarrier, catShippingCarrier);
        if(response.success)
        {
            yield put(deleteCatShippingCarrierSuccess(response));
            toastr.success(response.message, "Plataforma Guelaguetza")
        } else
        {
            yield put(deleteCatShippingCarrierFail(response));
            toastr.error(response.message, "Plataforma Guelaguetza")
        }
    } catch (error) {
        console.info(error)
        yield put(deleteCatShippingCarrierFail(error));
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

// watchers
function* CatShippingCarrierSaga() {
    yield takeEvery(GET_CAT_SHIPPING_CARRIER_LIST, fetchCatShippingCarrierList)
    yield takeEvery(SAVE_CAT_SHIPPING_CARRIER, onSaveNewCatShippingCarrier),
    yield takeEvery(UPDATE_CAT_SHIPPING_CARRIER, onUpdateCatShippingCarrier),
    yield takeEvery(DELETE_CAT_SHIPPING_CARRIER, onDeleteCatShippingCarrier)
}
    
export default CatShippingCarrierSaga;