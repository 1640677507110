import React, {useEffect, useMemo, useState} from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//i18n
import { withTranslation } from "react-i18next";
import TableContainerCustom from "../../components/Common/TableContainerCustom";
import {Link} from "react-router-dom";
import {
    Container, UncontrolledTooltip,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {states} from "../../common/data";
import {
    getCollectorsList as onGetCollectorsList,
    saveCollector as onSaveCollector, updateCollector as onUpdateCollector,
    setModalCollectorShow as onModalCollectorShow
}
    from "../../store/collector/actions";
import {useDispatch, useSelector} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {PORTAL_URL} from "../../helpers/api_helper";
import toastr from "toastr";
import {sendMailVerification as onSendMailVerification} from "../../store/auth/register/actions";

const CollectorList = props => {

    const [isDelete, setIsDelete] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const dispatch = useDispatch();


    const [collectorsList, setCollectorsList] = useState([]);
    const [collector, setCollector] = useState(null);

    const { collectorList,modalCollectorShow } = useSelector(state => ({
        collectorList: state.Collectors.collectorList,
        modalCollectorShow: state.Collectors.modalCollectorShow,
    }));

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            collectorID: (collector && collector.id) || '',
            name: (collector && collector.name) || '',
            paternal_surname: (collector && collector.paternal_surname) || '',
            maternal_surname: (collector && collector.maternal_surname) || '',
            birthdate: (collector && collector.birthdate) || '',
            address: (collector && collector.address) || '',
            zip_code: (collector && collector.zip_code) || '',
            state: (collector && collector.state) || '',
            phone: (collector && collector.phone) || '',
            email: (collector && collector.email) || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Por favor ingresa el nombre"),
            paternal_surname: Yup.string().required("Por favor ingresa el apellido paterno"),
            maternal_surname: Yup.string().required("Por favor ingresa el apellido materno"),
            birthdate: Yup.string().required("Ingresa la fecha de nacimiento"),
            address: Yup.string().required("Ingresa el domicilio"),
            zip_code: Yup.string().required("Ingresa el código postal"),
            state: Yup.string().required("Ingresa el estado"),
            phone: Yup.string().required("Ingresa el teléfono"),
            email: Yup.string().required("Ingresa el email"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const collectorUpdate = {
                    id: collector.id,
                    name: values.name,
                    paternal_surname: values.paternal_surname,
                    maternal_surname: values.maternal_surname,
                    birthdate: values.birthdate,
                    address: values.address,
                    zip_code: values.zip_code,
                    state: values.state,
                    phone: values.phone,
                    email: values.email,
                };
                // update order
                dispatch(onUpdateCollector(collectorUpdate));
                //validation.resetForm();
            } else {
                const newCollector = {
                    name: values.name,
                    paternal_surname: values.paternal_surname,
                    maternal_surname: values.maternal_surname,
                    birthdate: values.birthdate,
                    address: values.address,
                    zip_code: values.zip_code,
                    state: values.state,
                    phone: values.phone,
                    email: values.email,
                };
                // save new order
                dispatch(onSaveCollector(newCollector));
                //validation.resetForm();
            }
            //toggle();
        },
    });

    const handleCollectorNewOrEditClick = arg => {

        const collectorU = arg;

        if(collectorU.id)
        {
            setCollector({
                id: collectorU.id,
                name: collectorU.name,
                paternal_surname: collectorU.paternal_surname,
                maternal_surname: collectorU.maternal_surname,
                birthdate: collectorU.birthdate,
                address: collectorU.address,
                zip_code: collectorU.zip_code,
                state: collectorU.cat_state_id,
                phone: collectorU.phone,
                email: collectorU.email,
            });
            setIsEdit(true);
            setIsDelete(false);
        }
        else
        {
            setCollector(null);
            setIsEdit(false);
            setIsDelete(false);
        }

        toggle();
    };

    const handleCollectorDeleteClick = arg => {

        const collectorU = arg;

        if(collectorU.id)
        {
            setCollector({
                id: collectorU.id,
                name: collectorU.name,
                paternal_surname: collectorU.paternal_surname,
                maternal_surname: collectorU.maternal_surname,
                birthdate: collectorU.birthdate,
                address: collectorU.address,
                zip_code: collectorU.zip_code,
                state: collectorU.cat_state_id,
                phone: collectorU.phone,
                email: collectorU.email,
            });
            setIsEdit(false);
            setIsDelete(true);
        }

        toggle();
    };

    const handleSendMsgVerification = (arg) => {
        dispatch(onSendMailVerification(arg));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Nombre',
                accessor: 'name',
            },
            {
                Header: 'Dirección',
                accessor: 'address'
            },
            {
                Header: 'Estado',
                accessor: 'cat_state_name'
            },
            {
                Header: 'Teléfono',
                accessor: 'phone'
            },
            {
                Header: 'Link de Activacion',
                accessor: 'link',
                Cell: (cellProps) => {
                    return (
                        cellProps.row.original.remember_token !=="" ?
                            <>
                                <button
                                    onClick={()=>{
                                        const artisanData = cellProps.row.original;
                                        handleSendMsgVerification(artisanData)}}
                                    className="btn-sm btn btn-success referralLink mr-1">Enviar por email</button>
                                <CopyToClipboard text={cellProps.row.original?PORTAL_URL+"activate/"+cellProps.row.original.remember_token:""}
                                                 onCopy={() => toastr.success("Link copiado al portapapeles","Guelaguetza Platform")}>
                                    <button className="btn-sm btn btn-info referralLink">Copiar Link</button>
                                </CopyToClipboard>
                            </>:"-Cuenta activada-"
                    );
                }
            },
            {
                Header: 'Opciones',
                accessor: 'options',
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const colleData = cellProps.row.original;
                                    handleCollectorNewOrEditClick(colleData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Editar
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const collData = cellProps.row.original;
                                    handleCollectorDeleteClick(collData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Dar de baja
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    //meta title
    document.title = "Lista de Recolectores | Guelaguetza Designs";

    const toggle = () => {
        if (modalCollectorShow) {
            dispatch(onModalCollectorShow(false));
            setCollector(null);
        } else {
            dispatch(onModalCollectorShow(true));
        }
    };

    useEffect(() => {
        setCollectorsList(collectorList);
    }, [collectorList]);

    useEffect(() => {
        dispatch(onGetCollectorsList());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Recolectores")}
                        breadcrumbItem={props.t("Lista")}
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                    <TableContainerCustom
                        data={collectorsList}
                        isAddCollectorList={true}
                        handleCollectorClick={handleCollectorNewOrEditClick}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        customPageSize={10} columns={columns} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal size={"lg"} isOpen={modalCollectorShow} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">
                    {!!isDelete ? "Eliminar Recolector" : isEdit ? "Editar Recolector" : "Agregar Recolector"}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Card>
                            <CardBody>
                        <Row>
                            <div className="col-md-4">
                                <div className="mb-3">
                                            <Label className="form-label">Nombre(s)</Label>
                                            <Input
                                                name="name" disabled={isDelete}
                                                type="text"
                                                placeholder="Ingresa el nombre"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ""}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Apellido Paterno </Label>
                                            <Input
                                                name="paternal_surname"
                                                type="text" disabled={isDelete}
                                                placeholder="Ingresa el apellido paterno"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.paternal_surname || ""}
                                                invalid={
                                                    validation.touched.paternal_surname && validation.errors.paternal_surname ? true : false
                                                }
                                            />
                                            {validation.touched.paternal_surname && validation.errors.paternal_surname ? (
                                                <FormFeedback type="invalid">{validation.errors.paternal_surname}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Apellido Materno </Label>
                                            <Input
                                                name="maternal_surname"
                                                type="text" disabled={isDelete}
                                                placeholder="Ingresa el apellido materno"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.maternal_surname || ""}
                                                invalid={
                                                    validation.touched.maternal_surname && validation.errors.maternal_surname ? true : false
                                                }
                                            />
                                            {validation.touched.maternal_surname && validation.errors.maternal_surname ? (
                                                <FormFeedback type="invalid">{validation.errors.maternal_surname}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Fecha de nacimiento </Label>
                                            <Input
                                                name="birthdate"
                                                type="date" disabled={isDelete}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.birthdate || ""}
                                                invalid={
                                                    validation.touched.birthdate && validation.errors.birthdate ? true : false
                                                }
                                            />
                                            {validation.touched.birthdate && validation.errors.birthdate ? (
                                                <FormFeedback type="invalid">{validation.errors.birthdate}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-md-8"}>
                                <div className="mb-3">
                                            <Label className="form-label">Domicilio</Label>
                                    <Input
                                        name="address" placeholder={"Ingresa la calle y número"}
                                        type="text" disabled={isDelete}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.address || ""}
                                        invalid={
                                            validation.touched.address && validation.errors.address ? true : false
                                        }
                                    />
                                            {validation.touched.address && validation.errors.address ? (
                                                <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className="mb-3">
                                            <Label className="form-label">Código Postal</Label>
                                            <Input
                                                name="zip_code"
                                                type="text" disabled={isDelete}
                                                placeholder="Ingresa el C.P. "
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.zip_code || ""}
                                                invalid={
                                                    validation.touched.zip_code && validation.errors.zip_code ? true : false
                                                }
                                            />
                                            {validation.touched.zip_code && validation.errors.zip_code ? (
                                                <FormFeedback type="invalid">{validation.errors.zip_code}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>
                            <div className={"col-md-4"}>
                                <div className="mb-4">
                                            <Label className="form-label">Estado</Label>
                                            <Input
                                                name="state" disabled={isDelete}
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.state || ""}
                                                invalid={
                                                    validation.touched.state && validation.errors.state ? true : false
                                                }
                                            >
                                                <option value={""}>Elija una opción</option>
                                                {
                                                    states.map((item)=>{
                                                        return <option key={item.key} value={item.id}>{item.name}</option>
                                                    })
                                                }
                                            </Input>
                                            {validation.touched.state && validation.errors.state ? (
                                                <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                                            ) : null}
                                        </div>
                            </div>

                            <div className={"col-md-4"}>
                                <div className="mb-3">
                                    <Label className="form-label">Teléfono</Label>
                                    <Input
                                        name="phone" disabled={isDelete}
                                        type="tel"
                                        placeholder="Ingresa el teléfono "
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.phone || ""}
                                        invalid={
                                            validation.touched.phone && validation.errors.phone ? true : false
                                        }
                                    />
                                    {validation.touched.phone && validation.errors.phone ? (
                                        <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>

                            <div className={"col-md-6"}>
                                <div className="mb-3">
                                    <Label className="form-label">Email</Label>
                                    <Input
                                        name="email"
                                        type="text" disabled={collector?.id}
                                        placeholder="Ingresa el email "
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.email || ""}
                                        invalid={
                                            validation.touched.email && validation.errors.email ? true : false
                                        }
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                    ) : null}
                                </div>
                            </div>
                        </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className={isDelete?"btn btn-danger save-user":"btn btn-success save-user"}
                                    >
                                        {isDelete?"Dar de Baja":isEdit?"Guardar Cambios":"Guardar"}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};


export default withTranslation()(CollectorList);
