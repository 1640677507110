import axios from "axios";
import authHeader from "./jwt-token-access/auth-token-header";

//apply base url for axios
const API_URL = process.env.REACT_APP_URL_API;


//APPLY BASE URL FOR PLATAFORM FRONTEND
export const PORTAL_URL = process.env.REACT_APP_URL_PORTAL
export const PORTAL_SHOPIFY_PREVIEW_URL = process.env.REACT_URL_STORE_PREVIEW_PRODUCT

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = authHeader().Authorization;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}


export async function upload(url, data) {
  return axiosApi.post(url, data, {
      headers:{
        "Content-Type": "multipart/form-data",
        "Authorization" : `Bearer ${authHeader().token}`
      },
    }).then((response) => response.data);
}



export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export default axiosApi;