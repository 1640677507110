import React from "react";
import { Card, CardBody, Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ImageList from "./ImageList";
import CroppedImage from "components/Common/CroppedImage/CroppedImage";
import PropTypes from "prop-types";

const ImageGallery = () => {
  document.title = "Tus imágenes | Guelaguetza Platform";

  return (
    <React.Fragment>
        <Container fluid>
          <Breadcrumbs title="Guelaguetza Designs " breadcrumbItem="Galería" />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
            
                <div className="w-100">
                  <Card>
                    <CardBody>
                    <CroppedImage/>
                     <ImageList />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            
          </div>
        </Container>
    </React.Fragment>
  )
}


export default ImageGallery
