import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ArtisanList from "../pages/administrator/ArtisanList";

import ProductEdit from "../pages/artisan/ProductEdit";
import ProductAddStore from "../pages/administrator/ProductPublish";
import EcommerceProducts from "../pages/artisan/ProductList";
import ProductDetail from "../pages/artisan/ProductDetail";

import EcommerceOrder from "../pages/administrator/OrdersList";
import CollectorList from "../pages/administrator/CollectorList";
import TypeCraftsmanList from "../pages/administrator/TypeCraftsmanList";
import CatShippingCarrierList from "../pages/administrator/CatShippingCarrierList";
import VerifyAccountPage from "../pages/Authentication/VerifyAccount";
import Shipping from "pages/Shipping/ShippingList"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },

    /**
     * Product
     */
  { path: "/product-list", component: <EcommerceProducts/> },
  { path: "/product-add", component: <ProductEdit type="New"/> },
  { path: "/product-edit/:uuid", component: <ProductEdit/> },
  { path: "/product-detail/:uuid", component: <ProductDetail/> },

  { path: "/product-add-store/:uuid", component: <ProductAddStore/> },

    /**
     * Artisan
     */
    { path: "/artisan-list", component: <ArtisanList/> },

    /**
     * Collector
     */
    { path: "/collector-list", component: <CollectorList/> },

    /**
     * type craftsman
     */
    { path: "/type-craftsman-list", component: <TypeCraftsmanList/> },

  /**
   * Cat Shipping Carrier
   */
  { path: "/shipping-carrier-list", component: <CatShippingCarrierList/> },

    /**
     * Pedidos
     */
    { path: "/order-list",component: <EcommerceOrder/> },

    /**
     * Pedidos
     */
    { path: "/shipping",component: <Shipping/> },
 
    // //profile
    { path: "/profile", component: <UserProfile/> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/activate/:key", component: <VerifyAccountPage /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }

