import {
    GET_CAT_SHIPPING_CARRIER_SUCCESS,
    GET_CAT_SHIPPING_CARRIER_FAIL,
    SAVE_CAT_SHIPPING_CARRIER_SUCCESS,
    SAVE_CAT_SHIPPING_CARRIER_FAIL,
    UPDATE_CAT_SHIPPING_CARRIER_FAIL,
    UPDATE_CAT_SHIPPING_CARRIER_SUCCESS,
    DELETE_CAT_SHIPPING_CARRIER_SUCCESS,
    DELETE_CAT_SHIPPING_CARRIER_FAIL} from "./actions";

const INIT_STATE = {
  catShippingCarrierList: [],
  getCatShippingCarrierListSuccess: [],
  catCatShippingCarrier:{},
    error: {},
  }
          
  const CatShippingCarrier = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CAT_SHIPPING_CARRIER_SUCCESS:
        return {
          ...state,
          catShippingCarrierList: action.payload.data,
            getCatShippingCarrierListSuccess: action.payload.success,
        }
      case GET_CAT_SHIPPING_CARRIER_FAIL:
        return {
            ...state,
            getCatShippingCarrierListSuccess: action.payload.success,
            error: action.payload.message,
        }
      case SAVE_CAT_SHIPPING_CARRIER_SUCCESS:
          return {
            ...state,
            catShippingCarrierList: action.payload.data.data,
              error: action.payload
          }  
      case SAVE_CAT_SHIPPING_CARRIER_FAIL:
          return {
              ...state,
              error: action.payload,
          }
        case UPDATE_CAT_SHIPPING_CARRIER_SUCCESS:
            console.info(action.payload);
            return {
                ...state,
                catShippingCarrierList: action.payload,
                error: action.payload
            }
        case UPDATE_CAT_SHIPPING_CARRIER_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_CAT_SHIPPING_CARRIER_SUCCESS:
            return {
                ...state,
                catShippingCarrierList: action.payload,
                error: action.payload
            }
        case DELETE_CAT_SHIPPING_CARRIER_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
        return state
      }
  }
          
  export default CatShippingCarrier