import {SAVE_ARTISAN} from "../../artisan/actions";

export const REGISTER_CRAFTSMAN = "REGISTER_CRAFTSMAN"
export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_USER_FAILED = "register_user_failed"
export const PASSWORD_VERIFIED = "PASSWORD_VERIFIED"
export const MAIL_VERIFICATION_SEND = "MAIL_VERIFICATION_SEND"
export const PASSWORD_VERIFIED_SUCCESS = "PASSWORD_VERIFIED_SUCCESS"
export const PASSWORD_VERIFIED_FAIL = "PASSWORD_VERIFIED_FAIL"
export const GET_DATA_ADIC_ACCOUNT = "GET_DATA_ADIC_ACCOUNT"
export const GET_DATA_ADIC_ACCOUNT_SUCCESS = "GET_DATA_ADIC_ACCOUNT_SUCCESS"
export const ACCEPTED_TERMS_SAVE = "ACCEPTED_TERMS_SAVE"



export const acceptedTermsCraftsmanSave = artisan => ({
  type: ACCEPTED_TERMS_SAVE,
  payload:artisan,
})

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const passwordVerified = (user, history) => {
  return {
    type: PASSWORD_VERIFIED,
    payload: { user, history },
  }
}

export const registerCraftsman = (user, history) => {
  return {
    type: REGISTER_CRAFTSMAN,
    payload: { user, history },
  }
}


export const passwordVerifiedSuccess = user => {
  return {
    type: PASSWORD_VERIFIED_SUCCESS,
    payload: user,
  }
}
export const sendMailVerification = user => {
  return {
    type: MAIL_VERIFICATION_SEND,
    payload: user,
  }
}

export const getDataAdicAccount = key => {
  return {
    type: GET_DATA_ADIC_ACCOUNT,
    payload: key,
  }
}

export const getDataAdicAccountSuccess = data => {
  return {
    type: GET_DATA_ADIC_ACCOUNT_SUCCESS,
    payload: data,
  }
}
