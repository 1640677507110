import {PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG,
  GET_DATA_ADIC_ACCOUNT_BY_SESSION_SUCCESS} from "./actions";

const initialState = {
  error: "",
  success: "",
  profile:null
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case GET_DATA_ADIC_ACCOUNT_BY_SESSION_SUCCESS:
      state = { ...state, profile: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
