/* ORDERS */
export const GET_SHIPPING = "GET_SHIPPING"
export const GET_SHIPPING_SUCCESS = "GET_SHIPPING_SUCCESS"

/**
 * Edit ORDER
 */
export const UPDATE_SHIPPING = "UPDATE_SHIPPING"
export const UPDATE_SHIPPING_SUCCESS = "UPDATE_SHIPPING_SUCCESS"
export const UPDATE_SHIPPING_FAIL = "UPDATE_SHIPPING_FAIL"
export const SET_SHIPPING_EDIT = "SET_SHIPPING_EDIT"
export const SET_MODAL_SHIPPING_EDIT_SHOW = "SET_MODAL_SHIPPING_EDIT_SHOW"

export const getShipping = (filter) => ({
  type: GET_SHIPPING,
  payload:filter
})

export const getShippingSuccess = shipping => ({
  type: GET_SHIPPING_SUCCESS,
  payload: shipping,
})

export const setShippingEdit = shipping => ({
  type: SET_SHIPPING_EDIT,
  payload: shipping,
})

export const updateShipping = shipping => ({
  type: UPDATE_SHIPPING,
  payload: shipping,
})

export const updateShippingSuccess = shipping => ({
  type: UPDATE_SHIPPING_SUCCESS,
  payload: shipping,
})

export const setModalShippingEditShow = flag => ({
  type: SET_MODAL_SHIPPING_EDIT_SHOW,
  payload: flag,
})