const states = [
    { id: 1, key: "AGU", name: "AGUASCALIENTES" },
    { id: 2, key: "BCN",  name: "BAJA CALIFORNIA" },
    { id: 3, key: "BCS", name: "BAJA CALIFORNIA SUR" },
    { id: 4, key: "CAM", name: "CAMPECHE" },
    { id: 5, key: "CHP", name: "CHIAPAS" },
    { id: 6, key: "CHH", name: "CHIHUAHUA" },
    { id: 7, key: "COA", name: "COAHUILA" },
    { id: 8, key: "COL", name: "COLIMA" },
    { id: 9, key: "CMX", name: "CIUDAD DE MEXICO" },
    { id: 10, key: "DUR", name: "DURANGO" },
    { id: 11, key: "GUA", name: "GUANAJUATO" },
    { id: 12, key: "GRO", name: "GUERRERO" },
    { id: 13, key: "HID", name: "HIDALGO" },
    { id: 14, key: "JAL", name: "JALISCO" },
    { id: 15, key: "MEX", name: "ESTADO DE MEXICO" },
    { id: 16, key: "MIC", name: "MICHOACAN" },
    { id: 17, key: "MOR", name: "MORELOS" },
    { id: 18, key: "NAY", name: "NAYARIT" },
    { id: 19, key: "NLE",  name: "NUEVO LEON" },
    { id: 20, key: "OAX", name: "OAXACA" },
    { id: 21, key: "PUE", name: "PUEBLA" },
    { id: 22, key: "QUE",  name: "QUERETARO" },
    { id: 23, key: "ROO", name: "QUINTANA ROO" },
    { id: 24, key: "SLP", name: "SAN LUIS POTOSI" },
    { id: 25, key: "SIN", name: "SINALOA" },
    { id: 26, key: "SON", name: "SONORA" },
    { id: 27, key: "TAB", name: "TABASCO" },
    { id: 28, key: "TAM", name: "TAMAULIPAS" },
    { id: 29, key: "TLA", name: "TLAXCALA" },
    { id: 30, key: "VER", name: "VERACRUZ" },
    { id: 31, key: "YUC", name: "YUCATAN" },
    { id: 32, key: "ZAC", name: "ZACATECAS" }
]

export {states}