import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";

import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import DeleteModal from '../../components/Common/DeleteModal';
import toastr from "toastr";

import {
    OrderId,
    Date,
    PaymentStatus,
    Status,
    ProductTotal, TrackingNumber, TrackingDhlNumber, TrackingFedexNumber, Artisan, Product,
}
    from "./OrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
    Button,
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";

import {
    getOrders as onGetOrders,
    setModalOrderEditShow,
    setOrderEdit as onSetOrderEdit
} from "store/e-commerce/actions";
import RenderByCond from "components/Common/RenderByCond";

function EcommerceOrder() {

    //meta title
    document.title = "Pedidos | Guelaguetza Designs";

    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const [role, setRole] = useState(obj?.role_id);

    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [shippingList, setShippingList] = useState([]);
    const [order, setOrder] = useState(null);
    const [productByShipping, setProductByShipping] = useState([]);



    const { modalOrderEditShow } = useSelector(state => ({
        modalOrderEditShow: state.Ecommerce.modalOrderEditShow,
    }));

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            orderId: (order && order.orderId) || '',
            billingName: (order && order.billingName) || '',
            orderdate: (order && order.orderdate) || '',
            total: (order && order.total) || '',
            paymentStatus: (order && order.paymentStatus) || 'Paid',
            badgeclass: (order && order.badgeclass) || 'success',
            paymentMethod: (order && order.paymentMethod) || 'Mastercard',
        },
        validationSchema: Yup.object({
            orderId: Yup.string()
                .matches(
                    /[0-9\.\-\s+\/()]+/,
                    "Please Enter Valid Order Id"
                ).required("Please Enter Your Order Id"),
            billingName: Yup.string().required("Please Enter Your Billing Name"),
            orderdate: Yup.string().required("Please Enter Your Order Date"),
            total: Yup.string().matches(
                /[0-9\.\-\s+\/()]+/,
                "Please Enter Valid Amount"
            ).required("Total Amount"),
            paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
            badgeclass: Yup.string().required("Please Enter Your Badge Class"),
            paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateOrder = {
                    id: order ? order.id : 0,
                    orderId: values.orderId,
                    billingName: values.billingName,
                    orderdate: values.orderdate,
                    total: values.total,
                    paymentStatus: values.paymentStatus,
                    paymentMethod: values.paymentMethod,
                    badgeclass: values.badgeclass,
                };
                // update order
                dispatch(onUpdateOrder(updateOrder));
                validation.resetForm();
            } else {
                const newOrder = {
                    id: Math.floor(Math.random() * (30 - 20)) + 20,
                    orderId: values["orderId"],
                    billingName: values["billingName"],
                    orderdate: values["orderdate"],
                    total: values["total"],
                    paymentStatus: values["paymentStatus"],
                    paymentMethod: values["paymentMethod"],
                    badgeclass: values["badgeclass"],
                };
                // save new order
                dispatch(onAddNewOrder(newOrder));
                validation.resetForm();
            }
            toggle();
        },
    });


    const toggleViewModal = () => {
        if(productByShipping.length<=0) {
            toastr.warning("Agrega al menos un producto para envío")
            return false;
        }
        let modalOrderEditShowLocal = !modalOrderEditShow
        dispatch(setModalOrderEditShow(modalOrderEditShowLocal))
        if(!modalOrderEditShowLocal) {
            //setProductByShipping([])
        }
        //setModal1(!modal1);
        //window.location.reload();
    }

    const dispatch = useDispatch();
    const { orders,getorderDataSuccess } = useSelector(state => ({
        orders: state.Ecommerce.orders,
        getorderDataSuccess: state.Ecommerce.getorderDataSuccess,
    }));


    const toggle = () => {
        if (modal) {
            setModal(false);
            setOrder(null);
        } else {
            setModal(true);
        }
    };

    const handleOrderShippingCarrierClick = arg => {
        const order = arg;
        dispatch(onSetOrderEdit(order))
        setOrder(order);
        setIsEdit(true);
        toggleViewModal()
    };

    //delete order
    const [deleteModal, setDeleteModal] = useState(false);

    const handleDeleteOrder = () => {
        if (order && order.id) {
            dispatch(onDeleteOrder(order.id));
            setDeleteModal(false);
        }
    };


    const handleProductInShipping = (data,e) => {
        const products = productByShipping
        let productExists = products.findIndex(item=> item.id===data.id);
        if(productExists===-1 && e.target.checked) {
            products.push(data);
        }
        if(productExists>-1 && !e.target.checked) {
            products.splice(productExists,1);
        }
        setProductByShipping(products)
    }

    const columns = useMemo(
        () => [
            {
                Header: '',
                accessor: 'checked',
                disableFilters: true,
                Cell: (cellProps) => {
                    const shippData = cellProps.row.original;
                    return (
                        <div className="d-flex gap-3">
                            <RenderByCond cond={role===3 && !shippData.number_tracking} 
                            child={ 
                                <><Input checked={shippData.checked} onClick={(e)=>handleProductInShipping(shippData, e)} type={"checkbox"}></Input><label>Incluir</label></>
                            }/>
                        </div>
                    );
                }
            },

            {
                Header: 'Orden Shopify',
                accessor: 'order_name',
                width: '150px',
                style: {
                    textAlign: "center",
                    width: "10%",
                    background: "#0000",
                },
                disableFilters: true,
                Cell: (cellProps) => {
                    return <OrderId {...cellProps} />;
                }
            },
            {
                Header: 'Fecha Pedido',
                accessor: 'created_at_f',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                }
            },
            {
                Header: 'Producto',
                accessor: 'name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Product {...cellProps} />;
                }
            },
            {
                Header: 'Cantidad',
                accessor: 'quantity',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <ProductTotal {...cellProps} />;
                }
            },
            {
                Header: 'Artesano',
                accessor: 'craftsman_name',
                disableFilters: false,
                Cell: (cellProps) => {
                    return <Artisan {...cellProps} />;
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <Status {...cellProps} />;
                }
            },
            {
                Header: 'Paquetería',
                accessor: 'shipping_carrier_name',
                disableFilters: true,
                Cell: (cellProps) => {
                    return <PaymentStatus {...cellProps} />;
                }
            },
            {
                Header: ' Tracking',
                accessor: 'number_tracking',
                disableFilters: true,
                Cell: (cellProps) => {
                    const orderData = cellProps.row.original;
                    let carrier=orderData?.shipping_carrier_name?.toLowerCase()
                    switch (carrier) {
                        case "dhl":
                            return <TrackingDhlNumber {...cellProps} />;
                            break;
                        case "fedex":
                            return <TrackingFedexNumber {...cellProps} />;
                            break;
                        default:
                            return <TrackingNumber {...cellProps} />
                            break;
                    }
                }
            },
            /*{
                Header: 'Opciones',
                accessor: 'action',
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Button
                                type="button"
                                color="primary"
                                className="btn-sm btn-rounded"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    handleOrderShippingCarrierClick(orderData);
                                }}
                            >
                                <i className="fa fa-eye"></i>
                            </Button>
                        </div>
                    );
                }
            },*/
        ],
        []
    );

    useEffect(() => {
        dispatch(onGetOrders());
    }, []);

    useEffect(() => {
        setShippingList(orders);
    }, [orders]);

    return (
        <React.Fragment>
            <EcommerceOrdersModal products={productByShipping} order={order} size={"lg"} isOpen={modalOrderEditShow} edit={isEdit}
                                  toggle={toggleViewModal} />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Pedidos" breadcrumbItem="Listado" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        columns={columns}
                                        data={shippingList}
                                        isGlobalFilter={true}
                                        isAddOptions={false}
                                        section={role===3&&"orders"}
                                        handleOrderClicks={handleOrderShippingCarrierClick}
                                        customPageSize={10}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                </div>
            </div>
        </React.Fragment>
    );
}
EcommerceOrder.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;