import {
    GET_ARTISANS_SUCCESS,
    GET_ARTISANS_FAIL,
    SAVE_ARTISAN_SUCCESS,
    SAVE_ARTISAN_FAIL,
    MODAL_ARTISAN_SHOW,
    GET_ARTISAN_SUCCESS
} from "./actions"

const INIT_STATE = {
    artisanList: [],
    artisan:{},
    artisanModalShow:false
  }
          
  const Artisans = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ARTISAN_SUCCESS:
        return {
          ...state,
          artisan: action.payload.data,
        }
      case GET_ARTISANS_SUCCESS:
        return {
          ...state,
          artisanList: action.payload.data,
        }
      case GET_ARTISANS_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case SAVE_ARTISAN_SUCCESS:
          return {
            ...state,
            artisanList: action.payload.data,
              artisanModalShow: !action.payload.success,

        }
        case SAVE_ARTISAN_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case MODAL_ARTISAN_SHOW:
        return {
            ...state,
            artisanModalShow: action.payload,
        }
      default:
        return state
      }
  }
          
  export default Artisans