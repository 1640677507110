const admin_menu = [
    {
        id: 1,
        type: "title",
        title: "Menú",
        classIcon: "",
        to:"#",
        levels: 0,
    },
    {
        id: 2,
        type: "menu",
        title: "Dashboard",
        classIcon: "bx bxs-dashboard",
        to:"/",
        levels: 1,
    },
    {
        id: 3,
        type: "menu",
        title: "Productos",
        classIcon: "bx bxl-product-hunt",
        to:"/product-list",
        levels: 2,
        submenu:[
            {
                id: 1,
                type: "menu",
                title: "Product Listing",
                classIcon: "bx bx-list-check",
                to:"/product-list",
                levels: 0,
            }
        ]
    },
    {
        id: 4,
        type: "menu",
        title: "Artesanos",
        classIcon: "bx bxs-user-plus",
        to:"/artisan-list",
        levels: 0,
        submenu:[
            {
                id: 1,
                type: "menu",
                title: "Artisan List",
                classIcon: "bx bx-list-check",
                to:"/artisan-list",
                levels: 0,
            },
            {
                id: 2,
                type: "menu",
                title: "Artisan Add",
                classIcon: "bx bxs-edit",
                to:"/artisan-add",
                levels: 0,
            }
        ]
    },
    {
        id: 5,
        type: "menu",
        title: "Recolectores",
        classIcon: "bx bx-collection",
        to:"/collector-list",
        levels: 2,
        submenu:[
            {
                id: 1,
                type: "menu",
                title: "Collector List",
                classIcon: "bx bx-list-check",
                to:"/collector-list",
                levels: 0,
            },
            {
                id: 2,
                type: "menu",
                title: "Collector Add",
                classIcon: "bx bxs-edit",
                to:"/Collector-add",
                levels: 0,
            }
        ]
    },
    {
        id: 6,
        type: "menu",
        title: "Pedidos",
        classIcon: "bx bxs-box",
        to:"/order-list",
        levels: 1,
    },
    {
        id: 7,
        type: "menu",
        title: "Envios",
        classIcon: " bx bxs-package ",
        to:"/shipping",
        levels: 1,
    },
    {
        id: 8,
        type: "submenu",
        title: "Catalogos",
        classIcon: "bx bx-list-ul",
        to:"#",
        levels: 2,
        submenu:[
            {
                id: 81,
                type: "menu",
                title: "Categorias de Arte",
                classIcon: "bx bx-list-check",
                to:"/type-craftsman-list",
                levels: 0,
            },
            {
                id: 82,
                type: "menu",
                title: "Servicios de Paqueteria",
                classIcon: "bx bx-list-check",
                to:"/shipping-carrier-list",
                levels: 0,
            }
        ]
    },
]

const artisan_menu = [
    {
        id: 1,
        type: "title",
        title: "Menú",
        classIcon: "",
        to:"#",
        levels: 0,
    },
    {
        id: 2,
        type: "menu",
        title: "Dashboard",
        classIcon: "bx bx-calendar",
        to:"/",
        levels: 1,
    },
    {
        id: 3,
        type: "submenu",
        title: "Productos",
        classIcon: " bx bx-shopping-bag ",
        to:"#",
        levels: 2,
        submenu:[
            {
                id: 1,
                type: "menu",
                title: "Listado",
                classIcon: " bx bx-list-ol ",
                to:"/product-list",
                levels: 0,
            },
            {
                id: 2,
                type: "menu",
                title: " Nuevo Producto",
                classIcon: " bx bxs-plus-square ",
                to:"/product-add",
                levels: 0,
            }
        ]
    },
    {
        id: 4,
        type: "menu",
        title: "Pedidos",
        classIcon: " bx bxs-box ",
        to:"/order-list",
        levels: 1,
    },
]

const collector_menu = [
    {
        id: 1,
        type: "title",
        title: "Menú",
        classIcon: "",
        to:"#",
        levels: 0,
    },
    {
        id: 2,
        type: "menu",
        title: "Dashboard",
        classIcon: "bx bx-calendar",
        to:"/",
        levels: 1,
    },
    {
        id: 3,
        type: "menu",
        title: "Pedidos",
        classIcon: "bx bxs-box",
        to:"/order-list",
        levels: 1,
    },
    {
        id: 4,
        type: "menu",
        title: "Envios",
        classIcon: "bx bxs-box",
        to:"/shipping",
        levels: 1,
    },
]


export {admin_menu, artisan_menu, collector_menu}