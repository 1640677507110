import React, {useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//i18n
import { withTranslation } from "react-i18next";
import TableContainerCustom from "../../components/Common/TableContainerCustom";
import {Link} from "react-router-dom";
import {
    Container, UncontrolledTooltip,
    Button,
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import {useSelector, useDispatch } from "react-redux";
import { getCatTypeCraftsmanList as onGetCatTypeCraftsmanList, 
    saveCatTypeCraftsman as onSaveCatTypeCraftsman,
    updateCatTypeCraftsman as onUpdateCatTypeCraftsman,
    deleteCatTypeCraftsman as onDeleteCatTypeCraftsman
} from "store/catTypeCraftsman/actions";


const TypeCraftsmanList = props => {

    const [modal, setModal] = useState(false);
    const [typeSubmit, setTypeSubmit] = useState({
        title : "Crear nueva Categoría de Arte",
        type : "new"
    });

    const [catTypeCraftsmansList, setCatTypeCraftsmansList] = useState([]);
    const [catTypeCraftsman, setCatTypeCraftsman] = useState(null);

    const { catTypeCraftsmanList } = useSelector(state => ({
        catTypeCraftsmanList: state.CatTypeCraftsman.catTypeCraftsmanList,
      }));

    const { error } = useSelector(state => ({
        error: state.CatTypeCraftsman.error,
    }));
    
    const dispatch = useDispatch();

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (catTypeCraftsman && catTypeCraftsman.name) || '',
            status: (catTypeCraftsman && catTypeCraftsman.status) || true,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Por favor ingresa el nombre del tipo de artesanía"),
        }),
        onSubmit: (values) => {
            if (typeSubmit.type==="edit" || typeSubmit.type==="delete") {
                const updateTypeCratf = {
                    id: catTypeCraftsman.id,
                    orderId: values.orderId,
                    name: values.name,
                    active: values.active,

                };
                if (typeSubmit.type==="edit") {
                    dispatch(onUpdateCatTypeCraftsman(updateTypeCratf));
                } else {
                    dispatch(onDeleteCatTypeCraftsman(updateTypeCratf))
                }
                validation.resetForm();
                toggle();
            }
            else if(typeSubmit.type==="new")
            {
                const newCatTypeCraft = {
                    name: values["name"],
                };
                dispatch(onSaveCatTypeCraftsman(newCatTypeCraft));
                //if(error.success!==undefined && error.success)
                //{
                    validation.resetForm();
                    toggle();
                //}

            }
        },
    });

    const handleCatTypeCraftsmanClick = (typeSub, arg)=> {
        const typeCraft = arg;
        setCatTypeCraftsman({
            id: typeCraft.id,
            name: typeCraft.name,
            active: typeCraft.active,
        });
        setTypeSubmit({
            title: "Editar Tipo de Artesano",
            type: typeSub
        });
        toggle();
    };

    const handleTypeCraftsmanClick = () => {

        setTypeSubmit({
            title: "Crear nueva Categoría de Arte",
            type:"new"
        });
        toggle();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Nombre',
                accessor: 'name',
            },

            {
                Header: 'Activo',
                accessor: 'active'
            },
            {
                Header: 'Opciones',
                accessor: 'options',
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const catTypeCraft = cellProps.row.original;
                                    handleCatTypeCraftsmanClick("edit",catTypeCraft);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Editar
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const catTypeCraft = cellProps.row.original;
                                    handleCatTypeCraftsmanClick("delete", catTypeCraft);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Borrar
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            },
        ],
        []
    );

    //meta title
    document.title = "Categorías de Arte | Guelaguetza Designs";

    const toggle = () => {
        if (modal) {
            setModal(false);
            setCatTypeCraftsman(null);
        } else {
            setModal(true);
        }
    };

    useEffect(() => {
        dispatch(onGetCatTypeCraftsmanList());
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Categorias de Arte")}
                        breadcrumbItem={props.t("Lista")}
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                    <TableContainerCustom
                        data={catTypeCraftsmanList}
                        isAddTypeCraftsamanList={true}
                        handleTypeCraftsmanClick={handleTypeCraftsmanClick}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10} columns={columns} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal size={"lg"} isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">
                    {typeSubmit.title}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Card>
                            <CardBody>
                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                            <Label className="form-label">Nombre o Descripción</Label>
                                            <Input
                                                name="name"
                                                type="text"
                                                placeholder="Ingresa el nombre de la categoría"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.name || ""}
                                                invalid={
                                                    validation.touched.name && validation.errors.name ? true : false
                                                }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>
                            </Col>
                        </Row>
                            </CardBody>
                        </Card>
                        <Row>
                            {

                            }
                            <Col >
                                <div className="text-end">
                                    {
                                        typeSubmit.type==="delete" ?
                                            (
                                                <button
                                                    type="submit"
                                                    className="btn btn-danger save-user"
                                                >
                                                    Eliminar
                                                </button>
                                            ):
                                            (
                                                <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                >
                                                    Guardar
                                                </button>
                                            )
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};
TypeCraftsmanList.propTypes = {
    catTypeCraftsmanList: PropTypes.array,
    onGetCatTypeCraftsmanList: PropTypes.func,
    onSaveCatTypeCraftsman:PropTypes.func
};



export default withTranslation()(TypeCraftsmanList);
