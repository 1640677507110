import { call, put, takeEvery } from "redux-saga/effects";

import { GET_SHIPPING, UPDATE_SHIPPING, getShippingSuccess, setModalShippingEditShow } from "./actions";

//Include Both Helper File with needed methods
import {
  getShippingListData,
  updateShipping,
} from "helpers/backend_helper";
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchShippingList({patload:filter}) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(getShippingListData,filter);
    if(response.success){
      yield put(getShippingSuccess(response));
    } else {

    }
  } catch (error) {
    //yield put(getOrdersFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onUpdateShipping({ payload: shipping }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(updateShipping, shipping);
    if(response.success){
      yield put(getShippingSuccess(response));
      toastr.success(response.message)
      yield put(setModalShippingEditShow(false))
    } else {
      toastr.error(response.message)
    }
  } catch (error) {
    console.info(error)
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* ShippingSaga() {
  yield takeEvery(GET_SHIPPING, fetchShippingList);
  yield takeEvery(UPDATE_SHIPPING, onUpdateShipping);
}

export default ShippingSaga;
