import { select, takeEvery, put, call } from "redux-saga/effects";
import {
  DELETE_PRODUCT,
  GET_PRODUCTS_LIST,
  SAVE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCT_DETAIL,
  UPDATE_PRODUCT_PUBLISH_SHOPIFY,
  UPDATE_PRODUCT_VARIANT,
  setProductVariantFlag,
  SET_PRODUCT_SIZE_LIST,
  SET_PRODUCT_COLOR_LIST,
  SET_PRODUCT_STYLE_LIST,
  ADD_ROW_VARIANT_PRODUCT,
  REMOVE_ROW_VARIANT_PRODUCT,
  setSizeCheck,
  setColorCheck,
  setStyleCheck,
  PRODUCT_OPTION_CHECK_UNCHECK,
  productOptionCheck,
  addRowVariantProduct,
  setProductOptions,
  CHANGE_VALUE_VARIANT_PRODUCT,
  SET_PRODUCT_VARIANTS,
  handleProductVariantGenerate,
  SET_VARIANTS_BUILD,
  SET_PRICE_VARIANTS,
  handleSetVariants, SET_VARIANTS,
  handleSetImageVariants,
} from "./actions"
import {getProductsSuccess, getProductsFail, saveProductFail,
  saveProductSuccess,productAddActiveTabUpdate,
  getProductDetailSuccess, handleSetVariantsBuild} from "./actions"
import {deleteProduct, getProductsData, saveNewProduct, updateProduct,updateProductVariant,
  getProductDetails,publishShopifyProduct} from "../../helpers/backend_helper"
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

const sizeList = state => state.Products.sizeList;
const colorList = state => state.Products.colorList;
const styleList = state => state.Products.styleList;
const options = state => state.Products.options;
const variants = state => state.Products.variants;
const variantsBuildList = state => state.Products.variantsBuild;

function* fetchProducts ({payload:data}) {
    try {
      yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(getProductsData, data)
      yield put(getProductsSuccess(response))
    } catch (error) {
      yield put({type:SHOW_PRELOADER,payload: false})

      yield put(getProductsFail(error))
    } finally {
      yield put({type:SHOW_PRELOADER,payload: false})
    }
  }

function* onDeleteProduct ({ payload: product }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    yield put(saveProductFail({success:true}));
    const response = yield call(deleteProduct, product);
    if(response.success)
    {
      yield put(saveProductSuccess(response));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      yield put(saveProductFail(response));
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveProductFail(error));
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onSaveNewProduct ({ payload: product }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    yield put(saveProductFail({success:true}));
    const response = yield call(saveNewProduct, product);

    if(response.success)
    {
      yield put(saveProductSuccess(response));
      yield put(productAddActiveTabUpdate(2));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      yield put(saveProductFail(response));
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveProductFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onUpdateProduct ({ payload: product }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    yield put(saveProductFail({success:true}));
    const response = yield call(updateProduct, product);
    if(response.success)
    {
      yield put(getProductDetailSuccess(response));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      yield put(saveProductFail(response));
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveProductFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onUpdateProductVariant ({ payload: productV }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    //yield put(saveProductFail({success:true}));
    const response = yield call(updateProductVariant, productV);
    if(response.success)
    {
      yield put(getProductDetailSuccess(response));
      if (response?.data && response?.data?.variants?.length > 0) {
        yield put({type:SET_VARIANTS,payload:response.data.variants})
      }
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      yield put(saveProductFail(response));
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveProductFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onPublishProductShopify ({ payload: product }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(publishShopifyProduct, product);
    if(response.success)
    {
      yield put(getProductDetailSuccess(response));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      yield put(saveProductFail(response));
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveProductFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* fetchProductDetail({ productId }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(getProductDetails, productId);
    if(response.success) {
      yield put(getProductDetailSuccess(response));
      yield put(setProductVariantFlag(response.data.with_variant === 1));
      if (response?.data && response?.data?.variants?.length > 0) {
        yield put(handleSetVariants(response.data.variants))
        //yield put({type:SET_VARIANTS,payload:response.data.variants})
      }
      if (response?.data && response?.data?.options?.length > 0) {
        let product = response?.data
        for (const option of product.options) {
          const valuesJ = JSON.parse(option.values)
          const valuesSJ = JSON.parse(option?.shopify_values)
          let key = 0;
          
          for (let iv=0;iv<valuesJ.length;iv++) {
            yield put({type:ADD_ROW_VARIANT_PRODUCT, 
              payload: {
                name:option.name.toLowerCase(), 
                val:valuesJ[iv],
                value_shopify:(Array.isArray(valuesSJ) && valuesSJ.length>=key) ? valuesSJ[key]:"", 
                key:key,
                rooteable:product?.rooteable
              }
            })
            key++;
          }
          yield put(productOptionCheck({variant:option.name.toLowerCase(),valuesLength:valuesJ.length}))
        }
      }
    }
  } catch (error) {
    console.info(error)
    //yield put(get(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* handleOptionChecked ({payload:optionChecked})  {
  let modifiedRows = [];
  switch (optionChecked.variant) {
    case "size":
      const sizes = yield select(sizeList);
      modifiedRows = [...sizes];
      yield put(setSizeCheck( optionChecked.valuesLength>0))
      break;
    case "color":
      const colors = yield select(colorList);
      modifiedRows = [...colors];
      yield put(setColorCheck( optionChecked.valuesLength>0))
      break;
    case "style":
      const styles = yield select(styleList);
      modifiedRows = [...styles];
      yield put(setStyleCheck( optionChecked.valuesLength>0))
      break;
  }
  if(modifiedRows.length<=0 && optionChecked.valuesLength>0) {
    yield put(addRowVariantProduct({name:optionChecked.variant,val:" ",key:null}))
  }
}

function* handleAddRowVariant ({payload:variant})  {
  let modifiedRows = []
  let position=0
  switch (variant.name) {
    case "size":
      const sizes = yield select(sizeList);
      modifiedRows = [...sizes];
      modifiedRows.push({ id:modifiedRows.length,value: variant.val,value_shopify:variant?.value_shopify});
      yield put({type:SET_PRODUCT_SIZE_LIST,payload: modifiedRows})

      break;
    case "color":
      const colors = yield select(colorList);
      modifiedRows = [...colors];
      modifiedRows.push({ id:modifiedRows.length,value: variant.val,value_shopify:variant?.value_shopify});
      yield put({type:SET_PRODUCT_COLOR_LIST,payload: modifiedRows})
      position=1
      break;
    case "style":
      const styles = yield select(styleList);
      modifiedRows = [...styles];
      modifiedRows.push({ id:modifiedRows.length,value: variant.val,value_shopify:variant?.value_shopify});
      yield put({type:SET_PRODUCT_STYLE_LIST,payload: modifiedRows})
      position=2
      break;
  }

  const variants1 = yield select(options);
  variants1[position].values[variant.key]=variant.val;
  if(variant?.rooteable){
    variants1[position].shopify_values[variant.key]=variant.value_shopify;
  }

  yield put(setProductOptions(variants1))
  yield put(handleProductVariantGenerate())
}

function* handleRemoveRowVariant ({payload:variant})  {
  let modifiedRows = [];
  let position=0;
  if(variant.key!=0) {
    switch (variant.name) {
      case "size":
        const sizes = yield select(sizeList);
        modifiedRows = [...sizes];
        modifiedRows.splice(variant.key, 1);
        yield put({type: SET_PRODUCT_SIZE_LIST, payload: modifiedRows})
        break;
      case "color":
        const colors = yield select(colorList);
        modifiedRows = [...colors];
        modifiedRows.splice(variant.key, 1);
        yield put({type: SET_PRODUCT_COLOR_LIST, payload: modifiedRows})
        position=1
        break;
      case "style":
        const styles = yield select(styleList);
        modifiedRows = [...styles];
        modifiedRows.splice(variant.key, 1);
        yield put({type: SET_PRODUCT_STYLE_LIST, payload: modifiedRows})
        position=2
        break;
    }
  }

  const variants1 = yield select(options);
  variants1[position].values=[];
  for (const lem of modifiedRows){
      variants1[position].values.push(lem.value);
  }
  yield put(setProductOptions(variants1))
  yield put(handleProductVariantGenerate())
}

function* handleChangeVariantValue ({payload:variant})  {
  let modifiedRows = [];
  let position=0;
  let variantModified={};
  switch (variant.name) {
    case "size":
      const sizes = yield select(sizeList);
      modifiedRows = [...sizes];
      variantModified = modifiedRows[variant.key];
      if(variant?.rooteable){
        variantModified.value_shopify=variant.value;
      }else {
        variantModified.value=variant.value;
      }
      modifiedRows.splice(variant.key,1,variantModified);
      yield put({
        type:SET_PRODUCT_SIZE_LIST,
        payload: modifiedRows
      })
      break;
    case "color":
      position=1;
      const colors = yield select(colorList);
      modifiedRows = [...colors];
      variantModified = modifiedRows[variant.key];
      if(variant?.rooteable){
        variantModified.value_shopify=variant.value;
      }else {
        variantModified.value=variant.value;
      }
      modifiedRows.splice(variant.key,1,variantModified);
      yield put({type:SET_PRODUCT_COLOR_LIST,
        payload: modifiedRows})
      break;
    case "style":
      position=2;
      const styles = yield select(styleList);
      modifiedRows = [...styles];
      variantModified = modifiedRows[variant.key];
      if(variant?.rooteable){
        variantModified.value_shopify=variant.value;
      }else {
        variantModified.value=variant.value;
      }
      modifiedRows.splice(variant.key,1,variantModified);
      yield put({type:SET_PRODUCT_STYLE_LIST,
        payload: modifiedRows})
      break;
  }

  const variants1 = yield select(options);
  if(variant?.rooteable){
    variants1[position].shopify_values[variant.key]=variant.value;
  } else {
    variants1[position].values[variant.key]=variant.value;
  }
  yield put(setProductOptions(variants1))
  yield put(handleProductVariantGenerate())
}

const getPosition = (variantIni,pos) => {
  let variantMain = [];
  let variantShopifyMain= [];
  let positionsTotal = 3;
  if(pos<3 )
    if(variantIni[pos].values.length>0 && variantIni[pos].values[0].trim()!=="")
    {
      for (let item of variantIni[pos].values) {
        if (item?.trim() !== "") {
          variantMain.push(item);
        }
      }
      for (let item of variantIni[pos].shopify_values) {
        if (item?.trim() !== "") {
          variantShopifyMain.push(item);
        }
      }
      if(pos<2) {
        for (let i = pos + 1; i < 3; i++) {
          if (variantIni[i].values.length > 0 && variantIni[i].values[0].trim() !== "") {
            positionsTotal = i;
            break;
          }
        }
      }
    }else {
      pos++;
      return getPosition(variantIni,pos)
    }
  return {variantMain,positionsTotal, variantShopifyMain};
}

const compareVariantRoot = (variantRoot) => {
  let variantRootUpdated = []
  if(Array.isArray(variantRoot)) {
    for (const root of variantRoot) {
      if(Array.isArray(root)) {
        variantRootUpdated=[...root]
      } else {
        variantRootUpdated.push(root)
      }
    }
  } else {
    variantRootUpdated = variantRoot
  }
  return variantRootUpdated
}

const getSubVariantsMain = (variantsMain, variants1, positionsTotal,variantShopifyMain)=> {
  let variantsFinal=[];
  let variantsShopifyFinal=[];
  let variantsPREFinal=[];
  let variantsShopifyPREFinal=[];

  for(const item of variantsMain) {
    variantsFinal.push(item)

    let firstVar = []
    firstVar.push(item)
    if (positionsTotal<3 && variants1.values.length>0 && variants1.values[0].trim() !== "") {
      let variantResult = getSubVariants(variants1.values,firstVar)
      if(Array.isArray(variantResult)) {
        for (const  varia of variantResult) {
          variantsPREFinal.push(varia)
        }
      }
    }
  }
  for(const item of variantShopifyMain) {
    variantsShopifyFinal.push(item)
    let firstVar = []
    firstVar.push(item)
    if (positionsTotal<3 && variants1?.shopify_values?.length>0 && variants1?.shopify_values[0]?.trim() !== "") {
      let variantResult = getSubVariants(variants1.shopify_values,firstVar)
      if(Array.isArray(variantResult)) {
        for (const  varia of variantResult) {
          variantsShopifyPREFinal.push(varia)
        }
      }
    }
  }
  if(variantsPREFinal.length>0){
    variantsFinal=null
    variantsFinal = [...variantsPREFinal]
  }
  if(variantsShopifyPREFinal.length>0){
    variantsShopifyFinal=null
    variantsShopifyFinal = [...variantsShopifyPREFinal]
  }

  return {variantsFinal,variantsShopifyFinal};
}

const getSubVariants = (subVariantIter, variantRoot)=> {
  const variantCompos=[];
  let variantRootUpdated = compareVariantRoot(variantRoot)
  for (const elem of subVariantIter) {
    if(elem?.trim()!=="") {
      variantRootUpdated.push(elem)
      variantCompos.push(variantRootUpdated)
    }
    variantRootUpdated= [];
    variantRootUpdated = compareVariantRoot(variantRoot)
  }
  return variantCompos;
}

function generateVariantObj (variantt,prodVariantExists,varianttShop) {
  const variant={
      variant:variantt,
      guelaguetza_id:prodVariantExists!==undefined ? prodVariantExists.id:null,
      price:prodVariantExists!==undefined ? prodVariantExists.price_unit_artisan:"",
      price_admin:prodVariantExists!==undefined ? prodVariantExists.price:"",
      price_cost:prodVariantExists!==undefined ? prodVariantExists.price_cost:"",
      quantity:prodVariantExists!==undefined ? prodVariantExists.quantity:"",
      inventory_quantity:prodVariantExists!==undefined ? prodVariantExists?.inventory_quantity:0,
      committed:prodVariantExists!==undefined ? prodVariantExists?.committed:0,
      image:prodVariantExists!==undefined ? prodVariantExists.image:"",
      variant_admin:varianttShop!==undefined ? varianttShop:"",
  }
  return variant;
}

function* generateProductVariant () {
  let variants1 = yield select(options);
  const variantListL = [...variants1]
  let valuesVariant = getPosition(variantListL,0)
  let variantsFinal=[];
  let variantsShopifyFinal=[]
  const prodVariantList = yield select(variants);
  let variantsPRE2Final = []
  let variantsPREFinal = getSubVariantsMain(valuesVariant.variantMain,variants1[valuesVariant.positionsTotal],valuesVariant.positionsTotal,valuesVariant.variantShopifyMain)
  if(variantsPREFinal?.variantsFinal?.length>0) {
    variantsFinal = []
    variantsFinal = [...variantsPREFinal?.variantsFinal]
    if(valuesVariant.positionsTotal+1<3) {
      let poss = valuesVariant.positionsTotal+1
      variantsPRE2Final = getSubVariantsMain(variantsPREFinal?.variantsFinal,variants1[poss],poss,variantsPREFinal?.variantsShopifyFinal)
    }
  }

  if(variantsPREFinal?.variantsShopifyFinal?.length>0) {
    variantsShopifyFinal = []
    variantsShopifyFinal = [...variantsPREFinal?.variantsShopifyFinal]
    if(valuesVariant.positionsTotal+1<3) {
      let poss = valuesVariant.positionsTotal+1
      variantsPRE2Final = getSubVariantsMain(variantsPREFinal?.variantsFinal,variants1[poss],poss,variantsPREFinal?.variantsShopifyFinal)
    }
  }

  if(variantsPRE2Final?.variantsFinal?.length>0) {
    variantsFinal = []
    variantsFinal = [...variantsPRE2Final?.variantsFinal]
  }

  if(variantsPRE2Final?.variantsShopifyFinal?.length>0) {
    variantsShopifyFinal = []
    variantsShopifyFinal = [...variantsPRE2Final?.variantsShopifyFinal]
  }
  
  const arrayVar = []
  let prodVarkey = 0;
  for (const variantt of variantsFinal) {
    const variantObj = Array.isArray(variantt) ? variantt.join(" / ") : variantt;
    const prodVariantExists = prodVariantList.find(({title})=>title===variantObj)  
    const visObj = variantsShopifyFinal.length>=prodVarkey?variantsShopifyFinal[prodVarkey]:null
    arrayVar.push(generateVariantObj(variantt,prodVariantExists,visObj))
    prodVarkey++
  }
  
  yield put(handleSetVariantsBuild(arrayVar))
}

function* handleAddPriceVariants ({payload:variant})  {
  const variantPriceQQ = yield  select(variantsBuildList);
  const item = variantPriceQQ[variant.key]
  if(variant.target==="price") {
    item.price = variant.value
  }
  if(variant.target==="price_admin") {
    item.price_admin = variant.value
  }
  if(variant.target==="price_cost") {
    item.price_cost = variant.value
  }
  if(variant.target==="quantity") {
    item.quantity = variant.value
  }
  if(variant.target==="image") {
    item.image = variant.value
  }
  if(variant.target==="item_variant") {
    const variantAdminL = [...item.variant_admin]
    variantAdminL[variant.iter_int]=variant.value
    item.variant_admin = variantAdminL
  }
  variantPriceQQ.splice(variant.key,1,item);
  yield put( handleSetVariantsBuild(variantPriceQQ))
}

// watchers
function* productsSaga() {
  yield takeEvery(GET_PRODUCTS_LIST, fetchProducts)
  yield takeEvery(SAVE_PRODUCT, onSaveNewProduct)
  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)
  yield takeEvery(UPDATE_PRODUCT_VARIANT, onUpdateProductVariant)
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail);
  yield takeEvery(UPDATE_PRODUCT_PUBLISH_SHOPIFY, onPublishProductShopify);
  yield takeEvery(ADD_ROW_VARIANT_PRODUCT, handleAddRowVariant);
  yield takeEvery(REMOVE_ROW_VARIANT_PRODUCT, handleRemoveRowVariant);
  yield takeEvery(PRODUCT_OPTION_CHECK_UNCHECK, handleOptionChecked);
  yield takeEvery(CHANGE_VALUE_VARIANT_PRODUCT, handleChangeVariantValue);
  yield takeEvery(SET_PRODUCT_VARIANTS, generateProductVariant);
  yield takeEvery(SET_PRICE_VARIANTS, handleAddPriceVariants);
}
    
export default productsSaga;