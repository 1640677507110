import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_ORDERS, setModalOrderEditShow, setOrderEdit,
  UPDATE_ORDER, updateTrackingOrderSuccess,
} from "./actions";
import {
  getOrdersSuccess,
  updateTrackingOrder,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getOrdersData,
  updateOrder, updateOrders,
} from "helpers/backend_helper";
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchOrders() {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(getOrdersData);
    yield put(getOrdersSuccess(response));
  } catch (error) {
    //yield put(getOrdersFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}


function* onUpdateOrders({ payload: orders }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(updateOrders, orders);
    if(response.success){
      yield put(getOrdersSuccess(response));
      toastr.success(response.message)
      yield put(setModalOrderEditShow(false))
    } else {
      //yield put(o(response));
      toastr.error(response.data.message)

    }

  } catch (error) {
    console.info(error)
    //yield put(updateOrderFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* ecommerceSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders);
  yield takeEvery(UPDATE_ORDER, onUpdateOrders);
}

export default ecommerceSaga;
