import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
  Button,
  Card,
  CardBody, CardFooter,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table, Form,
  TabPane, Input, Modal, ModalHeader, ModalFooter, ModalBody,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Import Product Images
import { productImages } from "assets/images/product";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import actions
import {
  updateProduct as onUpdateProduct,
  getProductDetail as onGetProductDetail,
  productPublishShopify as onProductPublishShopify, updateProductVariant, SET_VARIANTS
} from "store/actions";
import RecentProducts from "./RecentProducts";

//redux
import { useSelector, useDispatch } from "react-redux";
import {PORTAL_SHOPIFY_PREVIEW_URL} from "../../helpers/api_helper";

const ProductDetail = props => {

  //meta title
  document.title = "Detalles del Producto | Guelaguetza Designs";

  const dispatch = useDispatch();

  const { product, variants,options} = useSelector(state => ({
    product: state.Products.product,
    variants: state.Products.variants,
    options: state.Products.options,
  }));

  const [activeTab, setActiveTab] = useState("1");
  const [rolePrivate, setRolePrivate] = useState("");
  const [productUpdate, setProductUpdate] = useState({});
  const [productVariantUpdate, setProductVariantUpdate] = useState({});

  const params = props.router.params;

  useEffect(() => {
    if (params && params.uuid) {
      dispatch(onGetProductDetail(params.uuid));
    }
  }, [dispatch]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const imageShow = (img, id) => {
    const expandImg = document.getElementById("expandedImg" + id);
    expandImg.src = img;
  };


  const [comments, setComments] = useState([]);
  const [priceEditFlag, setPriceEditFlag] = useState(false);
  const [bodyHtmlEditFlag, setBodyHtmlEditFlag] = useState(false);
  const [editQuantityFlag, setEditQuantityFlag] = useState(false);
  const [typeEditFlag, setTypeEditFlag] = useState(false);
  const [titleEditFlag, setTitleEditFlag] = useState(false);
  const [pricesVariantFlag, setPricesVariantFlag] = useState(false);
  const [priceMain, setPriceMain] = useState("")

  const onClickReply = commentId => {
    const modifiedComments = [...comments];

    const commentIdx = (modifiedComments || []).findIndex(
      comment => comment.commentId.toString() === commentId.toString()
    );
    if (commentIdx > -1) {
      if (modifiedComments[commentIdx]) {
        modifiedComments[commentIdx]["showAddComment"] = true;

        for (let i = 0; i < (modifiedComments || []).length; i++) {
          if (i !== commentIdx) {
            modifiedComments[i]["showAddComment"] = false;
          }
        }
      } else {
        modifiedComments[commentIdx]["showAddComment"] = false;
      }
      setComments(modifiedComments);
    }
  };

  const onCancelReply = commentId => {
    if (commentId) {
      const modifiedComments = [...comments];
      for (let i = 0; i < (modifiedComments || []).length; i++) {
        modifiedComments[i]["showAddComment"] = false;
      }
      setComments(modifiedComments);
    }
  };

  const onAddReply = (commentId, replyText) => {
    if (commentId) {
      const productId = params.id || 1;
      dispatch(onAddReplyAction(commentId, productId, replyText));
    }
  };

  const onAddComment = commentText => {
    const productId = params.id || 1;
    dispatch(onAddCommentAction(productId, commentText));
  };

  const handleSetProductPrice = (e) => {
    let productUp = product;
    productUp.price = e.target.value;
    setProductUpdate(productUp);
  }

  const handleSetProductVariantPrice = (e,variant, key) => {
    const variantsEdit = [...variants]
    variant.price = e.target.value
    variantsEdit.splice(key,1,variant)
    dispatch({type:SET_VARIANTS,payload:variantsEdit})
    setProductVariantUpdate(variant)
  }

  const handleSetProductVariantQuantityEdit = (variant, key,edit=true) => {
    const variantsEdit = [...variants]
    variant.editQuantityFlag = edit
    variantsEdit.splice(key,1,variant)
    dispatch({type:SET_VARIANTS,payload:variantsEdit})
  }
  const handleSaveProductVariantQuantity = key => {
    const variantsEdit = [...variants]
    variantsEdit[key].editQuantityFlag = false
    dispatch(updateProductVariant(productVariantUpdate));
    dispatch({type:SET_VARIANTS,payload:variantsEdit})
  }

  const handleSetProductVariantQuantity = (key,variant, e) => {
    const variantsEdit = [...variants]
    variant.quantity_new = e.target.value
    variantsEdit.splice(key,1,variant)
    dispatch({type:SET_VARIANTS,payload:variantsEdit})
    setProductVariantUpdate(variant)
  }

  const handleSetProductVariantPriceEdit = (variant, key,edit=true) => {
    const variantsEdit = [...variants]
    variantsEdit[key].editQuantityFlag = edit
    variantsEdit.splice(key,1,variantsEdit[key])
    dispatch({type:SET_VARIANTS,payload:variantsEdit})
  }

  const handleSetProductBodyHtml = (data) => {
    if(!data) {
      setBodyHtmlEditFlag(true)
    }
    let productUp = product;
    if(data) {
      productUp.body_html = data;
    }
    setProductUpdate(productUp);
  }

  const handleSetProductTitle = (e) => {
    e.preventDefault()
    const productUp = product
    productUp.title = e.target.value
    productUp.name = e.target.name
    setProductUpdate(productUp);
  }

  const handleSetProductType = (e) => {
    e.preventDefault()
    const productUp = product
    productUp.product_type = e.target.value
    setProductUpdate(productUp);
  }

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setRolePrivate(obj.role_id);
    }
    setProductUpdate(product)
  }, [props.success,product]);

  const handleSetPricesVariantFlag = () => {
    setPricesVariantFlag(true);
    setProductVariantUpdate(null);
  }

  const handleSetProductEdit = () => {
    setProductUpdate(product)
    setTitleEditFlag(true);
  }

  const handleSetProductTypeEdit = () => {
    setProductUpdate(product)
    setTypeEditFlag(true);
  }

  const handleSaveProductData = () => {
    setTitleEditFlag(false);
    setTypeEditFlag(false);
    setBodyHtmlEditFlag(false);
    dispatch(onUpdateProduct(productUpdate));
  }

  const handleSaveProductVariantData = (variant,key) => {
    handleSetProductVariantQuantityEdit(variant,key,false)
    dispatch(updateProductVariant(productVariantUpdate))
  }

  const handleSetStatusProductAndPublishShopify = (status) => {
    let productUp = product
    productUp.status = status
    setProductUpdate(productUp)
    handlePublishProductShopify()
  }
  const handlePublishProductShopify = () => {
    dispatch(onProductPublishShopify(productUpdate));
  }

  const [modalPriceAllConfirmShow,setModalPriceAllConfirmShow] = useState(false)

  const toogleModalPriceAllConfirm = () => {
    setModalPriceAllConfirmShow(!modalPriceAllConfirmShow)
  }
  const handleApplyPriceAllSave = () => {
    const variantsEdit = [...variants]
    variantsEdit?.map((item, key)=> {
      item.price = priceMain
      variantsEdit.splice(key,1,item)
      dispatch(updateProductVariant(item))
    })
    dispatch({type:SET_VARIANTS,payload:variantsEdit})
    toogleModalPriceAllConfirm()
  }

  const handleResetPriceAll = () => {
    const variantsEdit = [...variants]
    variantsEdit?.map((item, key)=> {
      item.price = ""
      variantsEdit.splice(key,1,item)
      dispatch({type:SET_VARIANTS,payload:variantsEdit})
    })
    dispatch({type:SET_VARIANTS,payload:variantsEdit})
    setPriceMain("")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Productos" breadcrumbItem="Detalle del Producto" />
          {!isEmpty(product) && (
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xl={6}>
                        <div className="product-detai-imgs">
                          <Row>
                            {
                                rolePrivate === 2 && !product.shopify_id &&
                                <Col md={12} className="mb-3 text-center">
                                  <Link
                                      to={"/product-edit/" + product.uuid}
                                      className={"btn btn-success btn-lg"}
                                  >
                                    Editar
                                  </Link>
                                </Col>
                            }
                            <Col md={2} sm={3} className="col-4">
                              <Nav className="flex-column" pills>
                                {
                                    product.image !== null && JSON.parse(product.image)?.map((item, key) =>
                                        (
                                            <NavItem key={key}>
                                              <NavLink
                                                  className={classnames({
                                                    active: activeTab === "1",
                                                  })}
                                                  onClick={() => {
                                                    toggleTab("1");
                                                  }}
                                              >
                                                <img
                                                    src={item}
                                                    alt=""
                                                    onClick={() => {
                                                      imageShow(
                                                          item,
                                                          1
                                                      );
                                                    }}
                                                    className="img-fluid mx-auto d-block rounded"
                                                />
                                              </NavLink>
                                            </NavItem>
                                        )
                                    )
                                }
                              </Nav>
                            </Col>
                            <Col md={7} sm={9} className="offset-md-1 col-8">
                              {product.shopify_id ? (
                                  <div className="avatar-sm product-ribbon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i style={{fontSize: "40px"}} className=" bx bxl-shopify"></i>
                                  </span>
                                  </div>
                              ) : null}
                              <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                  <div>
                                    <img
                                        src={productImages[product.image]}
                                        alt=""
                                        id="expandedImg1"
                                        className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="2">
                                  <div>
                                    <img
                                        src={productImages[product.image]}
                                        id="expandedImg2"
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="3">
                                  <div>
                                    <img
                                        src={productImages[product.image]}
                                        id="expandedImg3"
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                                <TabPane tabId="4">
                                  <div>
                                    <img
                                        src={productImages[product.image]}
                                        id="expandedImg4"
                                        alt=""
                                        className="img-fluid mx-auto d-block"
                                    />
                                  </div>
                                </TabPane>
                              </TabContent>
                            </Col>
                          </Row>
                        </div>
                        {product.shopify_id &&
                            <Row className={"mt-3"}>
                              <a target={"_blank"} className=" btn btn-info" rel="noreferrer"
                                 href={process.env.REACT_APP_URL_PREV_PROD + "=" + product.shopify_id}>
                                <i className="fa fa-eye font-20"></i> Ver en Shopify
                              </a>
                            </Row>
                        }
                      </Col>

                      <Col xl="6">
                        <div className="mt-4 mt-xl-3">
                          <div className="card">
                            <div className="card-body">
                              {
                                titleEditFlag ? <textarea onChange={(e) => handleSetProductTitle(e)}
                                                          className="form-control form-control-lg" rows={2}
                                                          value={productUpdate.title}></textarea>
                                    :
                                    <h4 className="card-title">{product.title}</h4>
                              }
                              <div className="card-text d-grid gap-2">
                                {
                                  rolePrivate === 1 && !titleEditFlag ? <button onClick={() => handleSetProductEdit()}
                                                                                className="btn btn-danger btn-block">Editar
                                        Título</button>
                                      : rolePrivate === 1 && (<>
                                    <button onClick={() => handleSaveProductData()}
                                            className="btn btn-success btn-block">Guardar Cambios
                                    </button>
                                    <button onClick={() => setTitleEditFlag(false)}
                                            className="btn btn-default btn-block">Cancelar
                                    </button>
                                  </>)
                                }
                              </div>
                            </div>
                          </div>

                          <div className="card">
                            <div className="card-body">
                              {
                                typeEditFlag ? <input onChange={(e) => handleSetProductType(e)}
                                                      className="form-control form-control-lg" rows={2}
                                                      value={productUpdate.product_type}></input>
                                    :
                                    <h4 className="card-title">Tipo de Producto: {
                                      !product.product_type ? "-" : product.product_type}</h4>
                              }
                              <div className="card-text d-grid gap-2">
                                {
                                  rolePrivate === 1 && !typeEditFlag ?
                                      <button onClick={() => handleSetProductTypeEdit()}
                                              className="btn btn-danger btn-block">Editar Tipo</button>
                                      : rolePrivate === 1 && (<>
                                    <button onClick={() => handleSaveProductData()}
                                            className="btn btn-success btn-block">Guardar Cambios
                                    </button>
                                    <button onClick={() => setTypeEditFlag(false)}
                                            className="btn btn-default btn-block">Cancelar
                                    </button>
                                  </>)
                                }
                              </div>
                            </div>
                          </div>
                          <div className="card">
                            <div className="card-body">
                              {rolePrivate === 1 && (
                                  <h3 className="mt-4 text-success text-uppercase">
                                    Artesano: {product.craftsman_name}
                                  </h3>
                              )}
                              {
                                  variants != null && variants.length <= 0 &&
                                  <h5 className="mb-4">
                                    {
                                      rolePrivate === 1 ? "COSTO: " : "PRECIO: "}
                                    <span className="text-muted me-2">
                              ${product.price_artisan} MXN
                            </span>{" "}
                                    Cantidad :{" "}
                                    <span className="text-muted me-2">
                              {product.quantity}
                            </span>{" "}
                                  </h5>
                              }
                              <h5>
                                {
                                  rolePrivate === 1 ? !priceEditFlag ?

                                      product.variants && product.variants.length <= 0 &&
                                      <><b>${product.price} USD </b>{"   "}
                                        {
                                          <button onClick={() => setPriceEditFlag(true)}
                                                  className="btn-sm btn btn-info">Editar</button>
                                        }
                                      </>

                                      : (<>
                                            <div className={"input-group mt-3"}>
                                              <input onChange={(e) => handleSetProductPrice(e)}
                                                     placeholder={"Ingresa el precio para venta"} name={"price"}
                                                     value={product.price} className={"form-control"}/>
                                              <button onClick={() => handleSaveProductData()}
                                                      className="btn-sm btn btn-info">Guardar Cambios
                                              </button>
                                            </div>
                                          </>
                                      ) : ""
                                }
                              </h5>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={4}>
                        <h2 className="font-size-18">Variantes</h2>
                        { rolePrivate === 1 && <div className="border-top">

                          <label className="h5 mb-4 mt-2">Precio para todas las
                            variantes (en Dólares) </label>
                          <div>
                            <Input className="form-control me-auto"
                                   type="number" onChange={(e) => setPriceMain(e.target.value)}
                                   placeholder=""
                                   value={priceMain}
                                   aria-label=""/>

                          </div>
                          <div className="hstack gap-2 mt-2">
                            <button type="button" onClick={handleResetPriceAll}
                                    className="btn btn-secondary">Resetear
                              precio
                            </button>
                            <button type="button" onClick={toogleModalPriceAllConfirm}
                                    className="btn btn-warning">Aplicar
                              a todo
                            </button>

                          </div>
                        </div> }
                      </Col>
                    </Row>
                    <Row>
                      {
                          variants != null &&
                          variants?.map((variant, i) => (
                              <Col lg={6} xl="6" key={i} sm={"6"} md={"6"}>
                                <div className="product-color">
                                  <Link to="#" className="">
                                    <div className="product-color-item border rounded row p-2 pt-3">
                                      <div style={{display: "none"}} className="product-color-item  rounded">
                                        <img
                                            src={product.image !== null ? JSON.parse(product.image).length > 1 ? JSON.parse(product.image)[i] : "" : ""}
                                            alt=""
                                            className="avatar-md"
                                        />
                                      </div>

                                      <p>{variant?.option1 && product.options[0].name.toUpperCase() + ": "} {variant?.option1}
                                        {variant?.option2 && ", " + product.options[1].name.toUpperCase() + ": "} {variant?.option2}
                                        {variant?.option3 && ", " + product.options[2].name.toUpperCase() + ": "} {variant?.option3}</p>
                                      <p><h5>
                                        {
                                          rolePrivate === 1 ? "Costo" : "Precio"
                                        }

                                      </h5>$ {variant?.price_unit_artisan} MXN
                                      </p>
                                      <h5>
                                        {
                                          rolePrivate === 1 ? !variant.editQuantityFlag ?
                                              (
                                                  <><b>${variant.price} USD </b>{"   "}
                                                    {
                                                      <button
                                                          onClick={() => handleSetProductVariantPriceEdit(null, i, true)}
                                                          className="btn-sm btn btn-info">Editar</button>
                                                    }
                                                  </>
                                              ) : (<>
                                                    <div className={"input-group mt-3"}>
                                                      <input type={"number"}
                                                             onChange={(e) => handleSetProductVariantPrice(e, variant, i)}
                                                             placeholder={"Ingresa el precio para venta"} name={"price"}
                                                             className={"form-control"}/>
                                                      <button onClick={() => handleSaveProductVariantData(variant, i)}
                                                              className="btn-sm btn btn-info">Guardar
                                                      </button>
                                                      <button
                                                          onClick={() => handleSetProductVariantPriceEdit(null, i, false)}
                                                          className="btn-sm btn btn-default">Cancelar
                                                      </button>
                                                    </div>
                                                  </>
                                              ) : ""
                                        }
                                      </h5>
                                      <p>{
                                        rolePrivate === 2 ? variant?.editQuantityFlag ? <>
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <h5>Cantidad Disponible Anterior</h5>
                                                  <div>{variant.quantity}</div>
                                                </div>
                                                <div className="col-md-6">
                                                  <h5>Cantidad Disponible Actual</h5>
                                                  <div className="input-group">
                                                    <input onChange={(e) => handleSetProductVariantQuantity(i, variant, e)}
                                                           value={variant.quantity_new}
                                                           className={"form-control text-center"} type={"number"}></input>
                                                    <button title={"Guardar"} type={"button"}
                                                            onClick={() => handleSaveProductVariantQuantity(i)}
                                                            className="btn btn-success  ">
                                                      <i className="fas fa-save"></i></button>
                                                    <button title={"Cancelar"} type={"button"}
                                                            onClick={() => handleSetProductVariantQuantityEdit(variant, i, false)}
                                                            className="btn btn-secondary btn-sm ">
                                                      <i className="mdi mdi-cancel"></i></button>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                            : <>
                                              <h5>Cantidad Disponible</h5>
                                              <div className="input-group">
                                                <input disabled={true} value={variant.quantity}
                                                       className={"form-control text-center"} type={"number"}></input>
                                                <button onClick={() => handleSetProductVariantQuantityEdit(variant, i)}
                                                        className="btn btn-warning btn-sm ">Editar
                                                </button>
                                              </div>
                                            </> : <><h5>Cantidad Disponible</h5> {variant.quantity} </>
                                      }</p>
                                    </div>
                                  </Link>
                                </div>
                              </Col>))}
                    </Row>

                    <div className="mt-5">
                      {
                          rolePrivate === 1 &&
                          (<>
                            <div className={"row mt-3"}>
                              <div className={"col-md-12 text-start"}>
                                <p className=" mb-4 mt-3">
                                  <h5>Descripción del Producto: </h5>
                                  {product.body}
                                </p>
                              </div>
                            </div>
                            <div className={"row"}>
                              {
                                bodyHtmlEditFlag ?
                                    <Form method="post">
                                      <CKEditor
                                          editor={ClassicEditor}
                                          data={product.body_html != null ? product.body_html : ""}
                                          onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                          }}
                                          onChange={(event, editor) => {
                                            handleSetProductBodyHtml(editor.getData());
                                          }}
                                      />
                                    </Form> :
                                    <div
                                        dangerouslySetInnerHTML={{__html: product.body_html ? product.body_html : "No se ha agregado la descripción"}}></div>
                              }
                            </div>
                            <div className={"row mt-3"}>
                              <div className={"col-md-12 text-end"}>
                                {
                                  bodyHtmlEditFlag ?
                                      (
                                          <button onClick={() => handleSaveProductData()}
                                                  className=" btn btn-success ">Guardar Descripción</button>
                                      ) : (
                                          <button onClick={() => handleSetProductBodyHtml(null)}
                                                  className=" btn btn-warning ">Editar Descripción Completa</button>
                                      )
                                }

                              </div>
                            </div>
                          </>)
                      }
                    </div>

                  </CardBody>
                  {
                      (rolePrivate === 1) &&
                      <CardFooter>
                        <div className={"row "}>
                          <div className={"col-md-12 text-center"}>
                            {
                                product.shopify_status === "active" && <button disabled={bodyHtmlEditFlag || priceEditFlag} onClick={()=>handleSetStatusProductAndPublishShopify("draft")} className=" btn btn-success m-4">Guardar en Shopify como BORRADOR</button>
                        }
                        {
                            product.shopify_status === "draft" &&
                        <button disabled={bodyHtmlEditFlag || priceEditFlag} onClick={()=>handleSetStatusProductAndPublishShopify("active")} className=" btn btn-warning m-4">Guardar en Shopify como ACTIVO</button>
                        }
                      </div>
                    </div>
                  </CardFooter>
                  }
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Modal size={"md"} isOpen={modalPriceAllConfirmShow} toggle={toogleModalPriceAllConfirm}>
        <ModalHeader toggle={toogleModalPriceAllConfirm} tag="h4">
          Confirmación de precio
        </ModalHeader>
        <ModalBody>
          <h5 >Por favor confirma que quieres aplicar el precio <span className="text-warning">USD${priceMain} </span>
             para todas las variantes del producto</h5>
        </ModalBody>
        <ModalFooter>
          <div className="text-end">
            <button className="btn btn-default" onClick={toogleModalPriceAllConfirm}>No, Regresar </button>
            <button className="btn btn-success" onClick={handleApplyPriceAllSave}>Confirmar y Guardar Precios</button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

ProductDetail.propTypes = {
  product: PropTypes.object,
  match: PropTypes.any,
  //onGetProductDetail: PropTypes.func,
};

export default withRouter(ProductDetail);
