import { takeEvery, put, call } from "redux-saga/effects";
import {
    GET_CAT_TYPE_CRAFTSMAN_LIST, SAVE_CAT_TYPE_CRAFTSMAN,
    UPDATE_CAT_TYPE_CRAFTSMAN, DELETE_CAT_TYPE_CRAFTSMAN
} from "./actions"
import {
    getCatTypeCraftsmanSuccess,
    getCatTypeCraftsmanFail,
    saveCatTypeCraftsmanSuccess,
    saveCatTypeCraftsmanFail, updateCatTypeCraftsmanSuccess, updateCatTypeCraftsmanFail,
    deleteCatTypeCraftsmanSuccess, deleteCatTypeCraftsmanFail
} from "./actions"
import {saveNewCatTypeCraftsman, getCatTypeCraftsmanData,
    updateCatTypeCraftsman, deleteCatTypeCraftsman } from "../../helpers/backend_helper"
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchCatTypeCraftsmanList () {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(getCatTypeCraftsmanData)
      yield put(getCatTypeCraftsmanSuccess(response.data.data))
    } catch (error) {
      yield put(getCatTypeCraftsmanFail(error))
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

function* onSaveNewCatTypeCraftsman ({ payload: catTypeCraftsman }) {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
        yield put(saveCatTypeCraftsmanFail({success:true}));
        const response = yield call(saveNewCatTypeCraftsman, catTypeCraftsman);
        if(response.success)
        {
            yield put(saveCatTypeCraftsmanSuccess(response));
            toastr.success(response.message, "Plataforma Guelaguetza")
        } else
        {
            yield put(saveCatTypeCraftsmanFail(response));
            toastr.error(response.message, "Plataforma Guelaguetza")
        }
    } catch (error) {
        console.info(error)
        yield put(saveCatTypeCraftsmanFail(error));
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

function* onUpdateCatTypeCraftsman ({ payload: catTypeCraftsman }) {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
        yield put(saveCatTypeCraftsmanFail({success:true}));
        console.info(catTypeCraftsman)
        const response = yield call(updateCatTypeCraftsman,catTypeCraftsman);
        console.info(response)
        if(response.success)
        {
            yield put(updateCatTypeCraftsmanSuccess(response));
            toastr.success(response.message, "Plataforma Guelaguetza")
        } else
        {
            yield put(updateCatTypeCraftsmanFail(response));
            toastr.error(response.message, "Plataforma Guelaguetza")
        }
    } catch (error) {
        console.info(error)
        yield put(saveCatTypeCraftsmanFail(error));
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}


function* onDeleteCatTypeCraftsman ({ payload: catTypeCraftsman }) {
    try {
        yield put(saveCatTypeCraftsmanFail({success:true}));
        const response = yield call(deleteCatTypeCraftsman, catTypeCraftsman);
        if(response.success)
        {
            yield put(deleteCatTypeCraftsmanSuccess(response));
            toastr.success(response.message, "Plataforma Guelaguetza")
        } else
        {
            yield put(deleteCatTypeCraftsmanFail(response));
            toastr.error(response.message, "Plataforma Guelaguetza")
        }
    } catch (error) {
        console.info(error)
        yield put(deleteCatTypeCraftsmanFail(error));
    }
}

// watchers
function* CatTypeCraftsmanSaga() {
    yield takeEvery(GET_CAT_TYPE_CRAFTSMAN_LIST, fetchCatTypeCraftsmanList)
    yield takeEvery(SAVE_CAT_TYPE_CRAFTSMAN, onSaveNewCatTypeCraftsman),
    yield takeEvery(UPDATE_CAT_TYPE_CRAFTSMAN, onUpdateCatTypeCraftsman),
    yield takeEvery(DELETE_CAT_TYPE_CRAFTSMAN, onDeleteCatTypeCraftsman)
}
    
export default CatTypeCraftsmanSaga;