import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap";
import Select from "react-select";
import {useDropzone} from "react-dropzone";
import Switch from "react-switch";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    deleteCatTypeCraftsman as onDeleteCatTypeCraftsman, saveCatTypeCraftsman as onSaveCatTypeCraftsman,
    updateCatTypeCraftsman as onUpdateCatTypeCraftsman
} from "../../store/catTypeCraftsman/actions";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    );
};

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            SI
        </div>
    );
};
const ProductAddStore = () => {

    //meta title
    document.title = "Agregar producto | Guelaguetza Designs";

    const [selectedFiles, setselectedFiles] = useState([])
    const [product, setProduct] = useState({
        unique:true
    });
    const [productUnique, setProductUnique] = useState(false);
    const[sizeList,setSizeList] = useState([{id:1,value:""}]);
    const[colorList,setColorList] = useState([{id:1,value:""}]);
    const[styleList,setStyleList] = useState([{id:1,value:""}]);
    const[variantList,setVariantList] = useState([]);

    const [typeSubmit, setTypeSubmit] = useState({
        title : "Crear nuevo Tipo de Artesano",
        type : "new"
    });

    const options = [
        { value: "AK", label: "Alaska" },
        { value: "HI", label: "Hawaii" },
        { value: "CA", label: "California" },
        { value: "NV", label: "Nevada" },
        { value: "OR", label: "Oregon" },
        { value: "WA", label: "Washington" },
    ]

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )

        setselectedFiles(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        maxFiles:10,
        multiple:true,
        accept: {'image/*': []}
    });

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors  }) => {
        return (
            <li key={file.path}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>

            </li>
        )
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: (product && product.name) || '',
            weight: (product && product.weight) || 0,
            productUnique: (product && product.productUnique)==true,
            description: (product && product.name) || '',
            priceUnit: (product && product.name) || '',
            variantList:{}
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Por favor ingresa el nombre de la artesanía"),
        }),
        onSubmit: (values) => {
            if (typeSubmit.type==="edit" || typeSubmit.type==="delete") {

            }
            else if(typeSubmit.type==="new")
            {
                const newProduct = {
                    name: values["name"],
                    description: values["description"],
                    weight: values["weight"],
                    priceUnit: values["priceUnit"],
                    productUnique: values["productUnique"],
                };
            }
        },
    });

    function handleAddRowVariant(variant) {
        let modifiedRows = [];
        switch (variant) {
            case "size":
                modifiedRows = [...sizeList];
                modifiedRows.push({ id:modifiedRows.length+1,value: ""});
                setSizeList(modifiedRows);
                break;
            case "color":
                modifiedRows = [...colorList];
                modifiedRows.push({ id:modifiedRows.length+1,value: ""});
                setColorList(modifiedRows);
                break;
            case "style":
                modifiedRows = [...styleList];
                modifiedRows.push({ id:modifiedRows.length+1,value: ""});
                setStyleList(modifiedRows);
                break;
        }
    }

    function handleRemoveRowVariant(variant, id) {
        let modifiedRows = [];
        if (id !== 1) {
            switch (variant) {
                case "size":
                    modifiedRows = [...sizeList];
                    modifiedRows = modifiedRows.filter(x => x["id"] !== id);
                    setSizeList(modifiedRows);
                    break;
                case "color":
                    modifiedRows = [...colorList];
                    modifiedRows = modifiedRows.filter(x => x["id"] !== id);
                    setColorList(modifiedRows);
                    break;
                case "style":
                    modifiedRows = [...styleList];
                    modifiedRows = modifiedRows.filter(x => x["id"] !== id);
                    setStyleList(modifiedRows);
                    break;
            }

        }
    }

    function handleChangeVariantValue(variant,e, item) {
        e.preventDefault();
        let modifiedRows = [];
        let index;
        switch (variant) {
            case "size":
                modifiedRows = [...sizeList];
                index = sizeList.indexOf(item);
                modifiedRows.splice(index,1,{id:item.id,value:e.target.value});
                setSizeList(modifiedRows)
                break;
            case "color":
                modifiedRows = [...colorList];
                index = colorList.indexOf(item);
                modifiedRows.splice(index,1,{id:item.id,value:e.target.value});
                setColorList(modifiedRows)
                break;
            case "style":
                modifiedRows = [...styleList];
                index = styleList.indexOf(item);
                modifiedRows.splice(index,1,{id:item.id,value:e.target.value});
                setStyleList(modifiedRows)
                break;
        }
    }


    const handleVariantGenerate = () => {

        sizeList.map((item) =>
            (
                setVariantList(
                    {
                        ...variantList,
                        id:"",
                    }
                )
            ))
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title="Producto" breadcrumbItem="Nuevo" />

                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Información básica</CardTitle>
                                    <p className="card-title-desc mb-4">
                                        Completa la siguiente información
                                    </p>

                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="productname">Nombre del producto</Label>
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Ingresa el nombre del producto"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="manufacturername">
                                                        Peso para envío
                                                    </Label>
                                                    <Input
                                                        id="weight"
                                                        name="weight"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Ingresa el peso en kg"
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="price">Precio por artículo</Label>
                                                    <Input
                                                        id="price"
                                                        name="price"
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="$"
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="mb-3">
                                                    <Label className="control-label">Tipo de Producto</Label>
                                                    <select className="form-control select2">
                                                        <option>Selecciona</option>
                                                    </select>
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="productdesc">
                                                        Descripción del producto
                                                    </Label>
                                                    <textarea
                                                        className="form-control mb-3"
                                                        id="productdesc"
                                                        rows="5"
                                                        placeholder="Agrega la descripción del producto: medidas, material y cualquier detalle que sea importante"
                                                    />
                                                </div>
                                                <hr></hr>
                                                <div className="mb-3 mt-2">
                                                    <Label htmlFor="price">Agrega la descripción para publicar en Store</Label>
                                                    <Editor
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                    />
                                                </div>
                                            </Col>

                                            <Col sm={12} lg={12} md={12}>
                                                <div className="mb-3 mt-2">
                                                    <Label htmlFor="price">¿Es producto único?</Label>
                                                    <div>
                                                        <Switch
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            className="me-1 mb-sm-8 mb-2 ml-5"
                                                            onColor="#626ed4"
                                                            onChange={() => {
                                                                setProductUnique(!productUnique);
                                                            }}
                                                            checked={productUnique}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            {
                                                !productUnique?
                                                    <>
                                                        <Col sm={12} lg={4} md={4}>
                                                            <div  className="mb-3">
                                                                <Label htmlFor="price">Agrega Tamaños o Tallas</Label>
                                                                {
                                                                    (sizeList || []).map((item,key) => (
                                                                        <div key={key} className="input-group">
                                                                            <Input  onChange={event =>
                                                                                handleChangeVariantValue("size",event,item)
                                                                            }
                                                                                    id="size"
                                                                                    name="size"
                                                                                    type="text" value={item.value}
                                                                                    className="form-control"
                                                                                    placeholder="Mediano"
                                                                            />
                                                                            {
                                                                                item.id!==1 ?
                                                                                    <button onClick={() => {
                                                                                        handleRemoveRowVariant("size",item.id);
                                                                                    }}
                                                                                            className="btn btn-danger"
                                                                                            type="button"
                                                                                            id="inputGroupFileAddon04">Borrar</button>:""}
                                                                            {
                                                                                (key+1)===sizeList.length?
                                                                                    <button onClick={() => {
                                                                                        handleAddRowVariant("size");
                                                                                    }}
                                                                                            className="btn btn-info"
                                                                                            type="button"
                                                                                            id="inputGroupFileAddon04">Otro</button>:""
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="price">Agrega Colores</Label>
                                                                {
                                                                    (colorList || []).map((item,key) => (
                                                                        <div key={key} className="input-group">
                                                                            <Input  onChange={event =>
                                                                                handleChangeVariantValue("color",event,item)
                                                                            }
                                                                                    id="size"
                                                                                    name="size"
                                                                                    type="text" value={item.value}
                                                                                    className="form-control"
                                                                                    placeholder="Mediano"
                                                                            />
                                                                            {
                                                                                item.id!==1 ?
                                                                                    <button onClick={() => {
                                                                                        handleRemoveRowVariant("color",item.id);
                                                                                    }}
                                                                                            className="btn btn-danger"
                                                                                            type="button"
                                                                                            id="inputGroupFileAddon04">Borrar</button>:""}
                                                                            {
                                                                                (key+1)===colorList.length?
                                                                                    <button onClick={() => {
                                                                                        handleAddRowVariant("color");
                                                                                    }}
                                                                                            className="btn btn-info"
                                                                                            type="button"
                                                                                            id="inputGroupFileAddon04">Otro</button>:""
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col sm={12} lg={4} md={4}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="price">Agrega Estilos</Label>
                                                                {
                                                                    (styleList || []).map((item,key) => (
                                                                        <div key={key} className="input-group">
                                                                            <Input  onChange={event =>
                                                                                handleChangeVariantValue("style",event,item)
                                                                            }
                                                                                    id="size"
                                                                                    name="size"
                                                                                    type="text" value={item.value}
                                                                                    className="form-control"
                                                                                    placeholder="Clasico"
                                                                            />
                                                                            {
                                                                                item.id!==1 ?
                                                                                    <button onClick={() => {
                                                                                        handleRemoveRowVariant("style",item.id);
                                                                                    }}
                                                                                            className="btn btn-danger"
                                                                                            type="button"
                                                                                            id="inputGroupFileAddon04">Borrar</button>:""}
                                                                            {
                                                                                (key+1)===styleList.length?
                                                                                    <button onClick={() => {
                                                                                        handleAddRowVariant("style");
                                                                                    }}
                                                                                            className="btn btn-info"
                                                                                            type="button"
                                                                                            id="inputGroupFileAddon04">Otro</button>:""
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        </Col>
                                                    </>:""}
                                            {
                                                !productUnique?
                                                    <>
                                                        <Col sm={12} lg={12} md={12}>
                                                            <h5 className={"mb-3 mt-3"}>Variantes disponibles</h5>
                                                        </Col>

                                                        <Col sm={12} lg={12} md={12}>
                                                            <table className={"table table-bordeared mb-5"}>
                                                                <thead>
                                                                <tr>
                                                                    <th style={{minWidth:"500px"}}>Variante</th>
                                                                    <th>Precio</th>
                                                                    <th>Cantidad disponible</th>
                                                                </tr>
                                                                </thead>
                                                            </table>
                                                        </Col></>:""}
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-3">Fotos para el producto</CardTitle>
                                    <Form  className="container">
                                        <div {...getRootProps({ style})}>
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                            <em>(2 files are the maximum number of files you can drop here)</em>
                                        </div>
                                        <aside>
                                            <h4>Imágenes </h4>
                                            <ul>{acceptedFileItems}</ul>
                                        </aside>
                                    </Form>
                                    <div className=" text-end">
                                        <Button type="submit" color="secondary" className=" m-2 ">
                                            Regresar
                                        </Button>
                                        <Button type="submit" color="primary" className="btn  m-2">
                                            Solo Guardar Cambios
                                        </Button>

                                        <Button type="submit" color="success" className="btn ">
                                            Guardar y Publicar en Shopify
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProductAddStore
