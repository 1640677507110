/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"
export const SET_ORDER_EDIT = "SET_ORDER_EDIT"
export const SET_MODAL_ORDER_EDIT_SHOW = "SET_MODAL_ORDER_EDIT_SHOW"

export const getOrders = () => ({
  type: GET_ORDERS,
})

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
})

export const setOrderEdit = order => ({
  type: SET_ORDER_EDIT,
  payload: order,
})

export const updateTrackingOrders = orders => ({
  type: UPDATE_ORDER,
  payload: orders,
})

export const updateTrackingOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
})

export const setModalOrderEditShow = flag => ({
  type: SET_MODAL_ORDER_EDIT_SHOW,
  payload: flag,
})