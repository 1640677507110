export const GET_IMAGE_LIST = "GET_IMAGE_LIST"
export const GET_IMAGE_LIST_SUCCESS = "GET_IMAGE_LIST_SUCCESS"
export const UPLOAD_IMAGE = "UPLOAD_IMAGE"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"
export const SET_IMAGE = "SET_IMAGE"

export const getImageList = (filter) => ({
  type: GET_IMAGE_LIST,
  payload:filter
})

export const getImageListSuccess = data => ({
  type: GET_IMAGE_LIST_SUCCESS,
  payload: data,
})

export const uploadImage = data => ({
  type: UPLOAD_IMAGE,
  payload:data
})

export const uploadImageSuccess = data => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: data,
})

export const setImage = image => ({
  type: SET_IMAGE,
  payload: image
})