import { takeEvery, put, call } from "redux-saga/effects";
import {
  DELETE_ARTISAN,
  GET_ARTISAN,
  GET_ARTISANS_LIST,
  getArtisanSuccess,
  SAVE_ARTISAN,
  UPDATE_ARTISAN
} from "./actions"
import {getArtisansSuccess, getArtisansFail, saveArtisanFail, saveArtisanSuccess} from "./actions"
import {deleteArtisan,getArtisanData, getArtisansData, saveNewArtisan, updateArtisan} from "../../helpers/backend_helper"
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchArtisans ({payload:data}) {
    try {
      yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(getArtisansData,data)
      yield put(getArtisansSuccess(response))
    } catch (error) {
      yield put(getArtisansFail(error))
    } finally {
      yield put({type:SHOW_PRELOADER,payload: false})
    }
  }

function* fetchArtisanById({ payload: artisan }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(getArtisanData, artisan)
    yield put(getArtisanSuccess(response))
  } catch (error) {
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}


function* onDeleteArtisan ({ payload: artisan }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(deleteArtisan, artisan);
    if(response.success)
    {
      yield put(saveArtisanSuccess(response));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveArtisanFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onSaveNewArtisan ({ payload: artisan }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(saveNewArtisan, artisan);
    if(response.success)
    {
      yield put(saveArtisanSuccess(response));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveArtisanFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onUpdateArtisan ({ payload: artisan }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(updateArtisan, artisan);
    if(response.success)
    {
      yield put(saveArtisanSuccess(response));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveArtisanFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

// watchers
function* artisansSaga() {
  yield takeEvery(GET_ARTISAN, fetchArtisanById)
  yield takeEvery(GET_ARTISANS_LIST, fetchArtisans)
  yield takeEvery(SAVE_ARTISAN, onSaveNewArtisan)
  yield takeEvery(UPDATE_ARTISAN, onUpdateArtisan)
  yield takeEvery(DELETE_ARTISAN, onDeleteArtisan)
}
    
export default artisansSaga;