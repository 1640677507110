import { GET_IMAGE_LIST_SUCCESS, SET_IMAGE, UPLOAD_IMAGE_SUCCESS  } from "./actions";

const INIT_STATE = {
  imageList: [],
  imageSelected:null,
    error: {},
    imageUploadSuccess:false,
  }
          
  const ImageGallery = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_IMAGE_LIST_SUCCESS:
        return {
          ...state,
          imageList: action.payload,
        }
      case UPLOAD_IMAGE_SUCCESS:
        return {
          ...state,
          imageList: action.payload.data,
          imageUploadSuccess:action.payload.success
        }
      case SET_IMAGE: 
        return {
          ...state,
          imageSelected: action.payload
        }
      default:
        return state
      }
  }
          
  export default ImageGallery