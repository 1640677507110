import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  PASSWORD_VERIFIED,
  PASSWORD_VERIFIED_SUCCESS,
  PASSWORD_VERIFIED_FAIL,
  GET_DATA_ADIC_ACCOUNT_SUCCESS,
} from "./actions"

const initialState = {
  registrationError: null,
  passwordVerifiedError:null,
  passwordVerifiedSuccess:false,
  message: null,
  loading: false,
  user: null,
  role: null,
  dataAdic: null
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    case PASSWORD_VERIFIED:
      state = {
        ...state,
        loading: true,
        registrationError: null,
      }
      break
    case PASSWORD_VERIFIED_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        registrationError: null,
        passwordVerifiedSuccess: true,
      }
      break
    case PASSWORD_VERIFIED_FAIL:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    case GET_DATA_ADIC_ACCOUNT_SUCCESS:
      state = {
        ...state,
        role: action.payload.role,
        dataAdic: action.payload
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
