//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_LOGIN = "login";

/**
 * Artisans
 */
export const DELETE_ARTISAN = "craftsman/delete";
export const GET_ARTISAN_ID_DATA = "craftsman/get-by-id";
export const GET_ARTISAN_DATA = "craftsman/list";
export const SAVE_ARTISAN = "craftsman/save";
export const UPDATE_ARTISAN = "craftsman/update";

/**
 * Cat Type Craft
 */
export const DELETE_CAT_TYPE_CRAFTSMAN = "cat-type-craft/delete";
export const GET_CAT_TYPE_CRAFTSMAN_DATA = "cat-type-craft/list";
export const SAVE_CAT_TYPE_CRAFTSMAN = "cat-type-craft/save";
export const UPDATE_CAT_TYPE_CRAFTSMAN = "cat-type-craft/update";

/**
 * GET CATS GENERAL
 * @type {string}
 */
export const GET_CAT_STATES_LIST = "cat-state/list";

/**
 * Cat Shipping Carrier
 */
export const DELETE_CAT_SHIPPING_CARRIER = "cat-shipping-carrier/delete";
export const GET_CAT_SHIPPING_CARRIER_DATA = "cat-shipping-carrier/list";
export const SAVE_CAT_SHIPPING_CARRIER = "cat-shipping-carrier/save";
export const UPDATE_CAT_SHIPPING_CARRIER = "cat-shipping-carrier/update";

/**
 * Collectors
 */
export const DELETE_COLLECTOR = "collector/delete";
export const GET_COLLECTOR_DATA = "collector/list";
export const SAVE_COLLECTOR = "collector/save";
export const UPDATE_COLLECTOR = "collector/update";

/**
 * Products
 */
export const DELETE_PRODUCT = "product/delete";
export const GET_PRODUCT_DATA = "product/list";
export const SAVE_PRODUCT = "product/save";
export const UPDATE_PRODUCT = "product/update";
export const UPDATE_PRODUCT_VARIANT = "product-variant/update";
export const PUBLISH_SHOPIFY_PRODUCT = "product/publish-shopify";
export const IMAGES_ADD_PRODUCT = "product/images-add";
export const GET_PRODUCT_DETAIL = "product/detail";
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

export const IMAGE_GET_LIST = "gallery/get"
export const IMAGE_UPLOAD = "gallery/image-upload"
/**
 *
 * @type {string}
 */
export const POST_PASSWORD_VERIFIED = "/password-verified";

export const REGISTER_CRAFTSMAN_URL = "/craftsman-register";
export const ACCEPTED_TERMS_CRAFTSMAN_SAVE_URL = "/craftsman/accepted-terms-save";
export const SEND_EMAIL_VERIFICATION = "/email/send-verification";
export const GET_DATA_ADIC_ACCOUNT = "/user/data-adic";
export const GET_PROFILE = "/profile";
export const REQUEST_FORGET_PASS = "/forget-password";

/**
 * ORDERS
 * @type {string}
 */
export const GET_ORDERS_DATA = "order/list";
export const UPDATE_ORDERS = "order-det/update";
export const GET_ORDER_DETAIL = "order/detail";

/**
 * SHIPPING
 * @type {string}
 */
export const GET_SHIPPING_DATA = "shipping/list";
export const UPDATE_SHIPPING = "shipping/update";
export const GET_SHIPPING_DETAIL = "shipping/detail";