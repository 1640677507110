import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap"

import toastr from "toastr"
import { useDispatch, useSelector } from "react-redux";
import { getImageList as onGetImageList, setImage as onSetImage} from "store/imageGallery/actions";

import "./style.css"

const ImageList = props => {

  const dispatch = useDispatch()

  const { imageList, imageSelected } = useSelector(state => ({
    imageList: state.ImageGallery.imageList,
    imageSelected: state.ImageGallery.imageSelected
  })); 

  const handleSetImageSelect = image => {
    try {
      dispatch(onSetImage(image))
      toastr.success("Imagen seleccionada")
    } catch(e) {
      console.error(e)
      toastr.error("No se pudo seleccionar la imagen, intenta nuevamente")
    }
  }

  useEffect(()=> {
      dispatch(onGetImageList())
      dispatch(onSetImage(null))
  },[])

  return (
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>Mis imágenes</h5>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {imageList?.map((image, key) => (
            <Col xl={4} lg={4} md={4} sm={6} xs={6} key={key}>
              <Card onClick={()=>handleSetImageSelect(image)} className={imageSelected?.id===image?.id?"image-selected shadow-none":"shadow-none border cursor"}>
                <CardBody className="p-3">
                  <div >
                    <div className="float-end ms-2">
                      <UncontrolledDropdown style={{display:"none"}} className="mb-2">
                        <DropdownToggle
                          className="font-size-16 text-muted"
                          tag="a"
                        >
                          <i className="mdi mdi-dots-horizontal"></i>
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem  href="#">
                            Seleccionar
                          </DropdownItem>
                          
                          <div className="dropdown-divider"></div>
                          <DropdownItem style={{display:"none"}} href="#">
                            Eliminar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div className=" me-2 mb-3">
                      <div className="avatar-title bg-transparent rounded">
                        <img src={image?.url_public} />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="overflow-hidden me-auto">
                        <h5 className="font-size-14 text-truncate mb-1">
                          <Link to="#" className="text-body">
                            {image?.name}
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default ImageList
