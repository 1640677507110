import { del, get, post, put, upload } from "./api_helper";
import * as url from "./url_helper";
import {ACCEPTED_TERMS_CRAFTSMAN_SAVE_URL, GET_ARTISAN_ID_DATA, GET_DATA_ADIC_ACCOUNT} from "./url_helper";

// Gets the logged-in user data from local session
const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
const postPasswordVerified = data => post(url.POST_PASSWORD_VERIFIED, data);
const registerCraftsmanSave = data => post(url.REGISTER_CRAFTSMAN_URL, data);
const acceptedTermsCraftsmanSave = data => post(url.ACCEPTED_TERMS_CRAFTSMAN_SAVE_URL, data);
const sendEmailVerification = data => post(url.SEND_EMAIL_VERIFICATION, data);
const getDataAdicAccountVerification = data => post(url.GET_DATA_ADIC_ACCOUNT, data);
const getProfile = data => post(url.GET_PROFILE, data);
const requestForgetPwd = data => post(url.REQUEST_FORGET_PASS, data);

/**
 * ARTISAN
 * @returns {Promise<*>}
 */
const deleteArtisan = artisan => post(url.DELETE_ARTISAN, artisan);
const getArtisanData = artisan => post(url.GET_ARTISAN_ID_DATA,artisan);
const getArtisansData = data => post(url.GET_ARTISAN_DATA, data);
const saveNewArtisan = artisan => post(url.SAVE_ARTISAN, artisan);
const updateArtisan = artisan => post(url.UPDATE_ARTISAN, artisan);

/**
 * GET CATS GENERAL
 * @returns {Promise<*>}
 */
const getCatStatesData = filter => post(url.GET_CAT_STATES_LIST, filter);


/**
 * CAT_TYPE_CRAFTSMAN
 * @returns {Promise<*>}
 */
const deleteCatTypeCraftsman = catTypeCraftsman => post(url.DELETE_CAT_TYPE_CRAFTSMAN, catTypeCraftsman);
const getCatTypeCraftsmanData = () => get(url.GET_CAT_TYPE_CRAFTSMAN_DATA);
const saveNewCatTypeCraftsman = catTypeCraftsman => post(url.SAVE_CAT_TYPE_CRAFTSMAN, catTypeCraftsman);
const updateCatTypeCraftsman = catTypeCraftsman => post(url.UPDATE_CAT_TYPE_CRAFTSMAN, catTypeCraftsman);

/**
 * CAT_SHIPPING_CARRIER
 * @returns {Promise<*>}
 */
export const deleteCatShippingCarrier = catShippingCarrier => post(url.DELETE_CAT_SHIPPING_CARRIER, catShippingCarrier);
export const getCatShippingCarrierData = () => get(url.GET_CAT_SHIPPING_CARRIER_DATA);
export const saveNewCatShippingCarrier = catShippingCarrier => post(url.SAVE_CAT_SHIPPING_CARRIER, catShippingCarrier);
export const updateCatShippingCarrier = catShippingCarrier => post(url.UPDATE_CAT_SHIPPING_CARRIER, catShippingCarrier);


/**
 * COLLECTORS
 * @returns {Promise<*>}
 */
const deleteCollector = collector => post(url.DELETE_COLLECTOR, collector);
const getCollectorsData = () => get(url.GET_COLLECTOR_DATA);
const saveNewCollector = collector => post(url.SAVE_COLLECTOR, collector);
const updateCollector = collector => post(url.UPDATE_COLLECTOR, collector);

/**
 * PRODUCTS
 * @param collector
 * @returns {Promise<*>}
 */
const deleteProduct = product => post(url.DELETE_PRODUCT, product);
const getProductsData = data => post(url.GET_PRODUCT_DATA, data);
const saveNewProduct = product => post(url.SAVE_PRODUCT, product);
const updateProduct = product => post(url.UPDATE_PRODUCT, product);

const updateProductVariant = productV => post(url.UPDATE_PRODUCT_VARIANT, productV);

/**
 * ORDERS
 * @param collector
 * @returns {Promise<*>}
 */
export const getOrdersData = () => get(url.GET_ORDERS_DATA);
export const getOrderDetail = () => get(url.GET_ORDER_DETAIL);
export const updateOrders = product => post(url.UPDATE_ORDERS, product);

/**
 * SHIPPING
 * @param shipping
 * @returns {Promise<*>}
 */
export const getShippingListData = (filter) => post(url.GET_SHIPPING_DATA, filter);
export const getShippingDetail = (filter) => post(url.GET_SHIPPING_DETAIL, filter);
export const updateShipping = shipping => post(url.UPDATE_SHIPPING, shipping);


/**
 * Image gallery 
 * @returns 
 */
export const getImageListData = () => get(url.IMAGE_GET_LIST);
export const uploadImage = image => upload(url.IMAGE_UPLOAD, image);

export const publishShopifyProduct = product => post(url.PUBLISH_SHOPIFY_PRODUCT, product);
export const getProductDetails = id =>
    get(`${url.GET_PRODUCT_DETAIL}/${id}`, { params: { id } });

export {
    getLoggedInUser,
    isUserAuthenticated,
    postLogin,
    postPasswordVerified,
    getArtisansData,
    getArtisanData,
    saveNewArtisan,
    updateArtisan,
    deleteArtisan,
    getCatTypeCraftsmanData,
    saveNewCatTypeCraftsman,
    updateCatTypeCraftsman,
    deleteCatTypeCraftsman,
    getCollectorsData,
    saveNewCollector,
    updateCollector,
    deleteCollector,
    getProductsData,
    saveNewProduct,
    sendEmailVerification,
    registerCraftsmanSave,
    getDataAdicAccountVerification,
    updateProduct,
    updateProductVariant,
    deleteProduct,
    acceptedTermsCraftsmanSave,
    getProfile,
    requestForgetPwd,
    getCatStatesData
  };
  