import { GET_SHIPPING_SUCCESS, SET_MODAL_SHIPPING_EDIT_SHOW, SET_SHIPPING_EDIT, UPDATE_SHIPPING_SUCCESS, getShippingSuccess } from "./actions";

const INIT_STATE = {
  
  shippingList: [],
  shipping: {},
  error: {},
  modalShippingEditShow:false,
  getShippingDataSuccess:false
};

const Shipping = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHIPPING_SUCCESS:
      return {
        ...state,
        shippingList: action.payload.data,
        getShippingSuccess:action.payload.success
      };
    case UPDATE_SHIPPING_SUCCESS:
      return {
        ...state,
        shippingList: action.payload.data
      };

      case SET_SHIPPING_EDIT:
      return {
        ...state,
        shipping: action.payload,
      };

      case SET_MODAL_SHIPPING_EDIT_SHOW:
      return {
        ...state,
        modalShippingEditShow: action.payload,
      };

    default:
      return state;
  }
};

export default Shipping;
