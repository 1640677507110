import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    Nav,
    NavItem,
    NavLink,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
} from "reactstrap";
import classnames from "classnames";
import { isEmpty, map } from "lodash";

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


//redux
import { useSelector, useDispatch } from "react-redux";


import {getProductsList as onGetProducts} from "../../store/product/actions";
import {getArtisansList as onGetArtisanList} from "../../store/artisan/actions";
import {
    getCatTypeCraftsmanList as onGetCatTypeCraftsmanList
} from "../../store/catTypeCraftsman/actions";
import { getCatStateList as onGetCatStateList } from "../../store/catalogs/actions"
import ProductCard from "../product/ProductCard";
import "./style.css"
import Catalogs from "../../store/catalogs/reducer";
const EcommerceProducts = props => {

    //meta title
    document.title = "Productos | Guelaguetza Designs";

    const dispatch = useDispatch();

    const { productList, artisanList, catTypeCraftsmanList, catStateList } = useSelector(state => ({
        productList: state.Products.productList,
        artisanList: state.artisans.artisanList,
        catTypeCraftsmanList: state.CatTypeCraftsman.catTypeCraftsmanList,
        catStateList: state.Catalogs.catStateList,
    }));

    const [FilterClothes, setFilterClothes] = useState([
        { id: "", name: "Selecciona", link: "#" },
        { id: "artisan", name: "Artesanos", link: "#" },
        { id: "category", name: "Categorías", link: "#" },
        { id: "state", name: "Estados", link: "#" },
        { id: "pubShopify", name: "Publicado en Shopify", link: "#" },
    ]);
    const [filterShopifyPub, setFilterShopifyPub] = useState([
        { id: "all", name: "Todo", link: "#" },
        { id: "draft", name: "Draft", link: "#" },
        { id: "active", name: "Active", link: "#" },
    ]);

    const [filterType, setFilterType] = useState(null);
    const [productLists, setProductLists] = useState([]);
    const [activeTab, setActiveTab] = useState("1");
    const [rolePrivate, setRolePrivate] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [artisanSelectList, setArtisanSelectList] = useState([]);
    const [catStateSelectList, setCatStateSelectList] = useState([]);

    const [filters, setFilters] = useState({
        discount: [],
        price: { min: 0, max: 500 },
    });
    const [page, setPage] = useState(1);
    // eslint-disable-next-line no-unused-vars
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        setProductLists(productList);
    }, [productLists]);

    useEffect(() => {
        dispatch(onGetArtisanList({active:true}));
        dispatch(onGetProducts());
        dispatch(onGetCatTypeCraftsmanList());
        dispatch(onGetCatStateList({filter:"productList"}));
    }, [dispatch]);

    useEffect(() => {
        if (!isEmpty(productList)) setProductLists(productList);
    }, [productList]);

    useEffect(() => {
        setArtisanSelectList(artisanList)
    }, [artisanList]);

useEffect(() => {
        setCatStateSelectList(catStateList)
    }, [catStateList]);


    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const handlePageClick = page => {
        setPage(page);
    };

    useEffect(() => {
        if (sessionStorage.getItem("authUser")) {
            const obj = JSON.parse(sessionStorage.getItem("authUser"));
            setRolePrivate(obj.role_id);
        }
    }, [props.success]);

    const handleFiltersType = (data) => {
        setFilters({
            ...filters,
            type:data,
            data:null
        })
    }

    const handleGetProducts = (data) => {
        const filtersL = {
            type:filters.type,
            data:data
        }
        setFilters({
            ...filters,
            data:data
        })
        dispatch(onGetProducts(filtersL));
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Productos" breadcrumbItem="Listado" />
                    <Row>
                        {
                            rolePrivate===1 &&
                            (<Col lg="3">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Filtrar por</CardTitle>
                                        <Row>
                                            <Col className="mb-3" sm={12} lg={12} md={12}>
                                                <select name="selectFilter" onChange={(e)=>handleFiltersType(e.target.value)} className="form-control">
                                                    {FilterClothes.map((cloth, key) => (
                                                        <option key={"_li_" + key} value={cloth.id}>
                                                            {cloth.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Col>
                                            {
                                                filters?.type==="artisan" && <Col sm={6} lg={12} md={12}>
                                                    <h5 className="font-size-14 mb-3">Artesanos</h5>
                                                    {/* Render Cloth Categories */}
                                                    <ul className="list-unstyled product-list">
                                                        {artisanSelectList.map((artisan, key) => (
                                                            <li className={filters?.data===artisan?.id && "filter-item-selected"} key={"_li_" + key}>
                                                                <Link onClick={()=>handleGetProducts(artisan.id)}
                                                                      to={"#"}>
                                                                    <i className="mdi mdi-chevron-right me-2"/>
                                                                    {artisan.name_full}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Col>
                                            }

                                            {
                                                filters?.type==="category" &&
                                            <Col sm={6} lg={12} md={12}>
                                                <h5 className="font-size-14 mb-3">Categorías</h5>
                                                {/* Render Cloth Categories */}
                                                <ul className="list-unstyled product-list">
                                                    {catTypeCraftsmanList?.map((catTypeCraftsman, key) => (
                                                        <li  className={filters?.data===catTypeCraftsman?.id && "filter-item-selected"} key={"_li_" + key}>
                                                            <Link onClick={()=>handleGetProducts(catTypeCraftsman.id)} to={"#"}>
                                                                <i className="mdi mdi-chevron-right me-2"/>
                                                                {catTypeCraftsman.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Col>}
                                            {
                                                filters?.type==="state" &&
                                            <Col sm={6} lg={12} md={12}>
                                                <h5 className="font-size-14 mb-3">Estados</h5>
                                                {/* Render Cloth Categories */}
                                                <ul className="list-unstyled product-list">
                                                    {catStateSelectList?.map((state, key) => (
                                                        <li className={filters?.data===state?.id && "filter-item-selected"} key={"_li_" + key}>
                                                            <Link onClick={()=>handleGetProducts(state.id)} to={"#"}>
                                                                <i className="mdi mdi-chevron-right me-2"/>
                                                                {state.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Col>}

                                            {
                                                filters?.type==="pubShopify" &&
                                            <Col sm={6} lg={12} md={12}>
                                                <h5 className="font-size-14 mb-3">Estatus en Shopify</h5>
                                                {/* Render Cloth Categories */}
                                                <ul className="list-unstyled product-list">
                                                    {filterShopifyPub.map((cloth, key) => (
                                                        <li className={filters?.data === cloth?.id && "filter-item-selected"}
                                                            key={"_li_" + key}>
                                                            <Link onClick={() => handleGetProducts(cloth.id)} to={"#"}>
                                                                <i className="mdi mdi-chevron-right me-2"/>
                                                                {cloth.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Col>}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>)
                        }
                        <Col lg={9}>
                            <Row className="mb-3">
                                <Col xl={4} sm={6}>
                                    <div className="mt-2">
                                    <h5>Productos </h5>
                                    </div>
                                </Col>
                                <Col lg={8} sm={6}>
                                    <Form className="mt-4 mt-sm-0 float-sm-end d-sm-flex align-items-center">
                                        <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("1");
                                                    }}
                                                >
                                                    <i className="bx bx-grid-alt"/>
                                                </NavLink>
                                            </NavItem>

                                        </Nav>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                {!isEmpty(productList) &&
                                    productList.map((product, key) => (<ProductCard rolePrivate={rolePrivate} product={product} key={key}/>))
                                }
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <Pagination className="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1">
                                        <PaginationItem disabled={page === 1}>
                                            <PaginationLink
                                                previous
                                                to="#"
                                                onClick={() => handlePageClick(page - 1)}
                                            />
                                        </PaginationItem>
                                        {map(Array(totalPage), (item, i) => (
                                            <PaginationItem active={i + 1 === page} key={i}>
                                                <PaginationLink
                                                    onClick={() => handlePageClick(i + 1)}
                                                    to="#"
                                                >
                                                    {i + 1}
                                                </PaginationLink>
                                            </PaginationItem>
                                        ))}
                                        <PaginationItem disabled={page === totalPage}>
                                            <PaginationLink
                                                next
                                                to="#"
                                                onClick={() => handlePageClick(page + 1)}
                                            />
                                        </PaginationItem>
                                    </Pagination>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

EcommerceProducts.propTypes = {
    productList: PropTypes.array,
    onGetArtisansList: PropTypes.func,
    onGetProducts: PropTypes.func,
};

export default withRouter(EcommerceProducts);
