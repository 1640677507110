import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {PORTAL_URL} from "../../helpers/api_helper";
import toastr from "toastr";
import {states} from "../../common/data";


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <Col md={5}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Búsqueda
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} registros...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>

      </Col>
      {isJobListGlobalFilter && (
        <JobListGlobalFilter />
      )}

    </React.Fragment>
  );
}

const TableContainerCustom = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  isAddCollectorList,
  isAddTypeCraftsamanList,
                                isAddShippingCarrierList,
  handleOrderClicks,
  handleCustomerClick,
  handleArtisanClick,
  handleCollectorClick,
  handleTypeCraftsmanClick,
                                handleShippingCarrierClick,
  isAddCustList,
  customPageSize,
  className,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };
  return (
    <Fragment>
      <Row className="mb-2">
        {isAddUserList &&
            (
                <Col sm="8">
                  <div className="text-sm-start">
                    <label>Opciones de filtrado</label>
                  </div>
                    <div className="text-sm-start hstack gap-4 mb-4">
                      <select className="form-control  mb-2 me-2">
                        <option value={""}>Elija una un Estado para filtrar</option>
                        {
                          states.map((item) => {
                            return <option key={item.key} value={item.id}>{item.name}</option>
                          })
                        }
                      </select>
                      <select className="form-control  mb-2 me-2">
                        <option value={""}>Elija una Categoría</option>
                        {
                          states.map((item) => {
                            return <option key={item.key} value={item.id}>{item.name}</option>
                          })
                        }
                      </select>
                      <select className="form-control  mb-2 me-2">
                        <option value={""}>Elija un Status</option>
                        <option value={"Activo"}>Activo</option>
                        <option value={"Inactivo"}>Inactivo</option>
                        <option value={"Baja"}>Baja</option>
                      </select>

                      <Button
                          type="button"
                          color="primary"
                          className="btn mb-2 me-2"

                      >
                        <i className="mdi mdi-search-circle-outline me-1"/>
                        Filtrar
                      </Button>
                    </div>
                </Col>
          )

        }
        {isGlobalFilter && (
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                isJobListGlobalFilter={isJobListGlobalFilter}
            />
        )}
        {isAddOptions && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="12">
            <div className="text-sm-end">

              <CopyToClipboard text={PORTAL_URL+"register"}
                               onCopy={() => toastr.success("Link copiado al portapapeles","Guelaguetza Platform")}>
                <button className="mb-2 me-2 btn btn-info referralLink">Copiar Link Para Artesano</button>
              </CopyToClipboard>

              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleArtisanClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Agregar Nuevo Artesano
              </Button>
            </div>
          </Col>
        )}
        {isAddCollectorList && (
            <Col sm="12">
              <div className="text-sm-end">
                <Button
                    type="button"
                    color="primary"
                    className="btn mb-2 me-2"
                    onClick={handleCollectorClick}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Agregar Nuevo Recolector
                </Button>
              </div>
            </Col>
        )}
        {isAddTypeCraftsamanList && (
            <Col sm="12">
              <div className="text-sm-end">
                <Button
                    type="button"
                    color="primary"
                    className="btn mb-2 me-2"
                    onClick={handleTypeCraftsmanClick}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Agregar Nueva Categoría de Arte
                </Button>
              </div>
            </Col>
        )}
        {isAddShippingCarrierList && (
            <Col sm="12">
              <div className="text-sm-end">
                <Button
                    type="button"
                    color="primary"
                    className="btn mb-2 me-2"
                    onClick={handleShippingCarrierClick}
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Agregar Nueva Paquetería
                </Button>
              </div>
            </Col>
        )}
        {isAddCustList && (
          <Col sm="12">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>


        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th data-priority={column.id} key={column.id}>
                    <div className="mb-0" {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>


      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainerCustom.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainerCustom;
