import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
  Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"

import { useFormik } from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";

import {
  updateShipping as onUpdateShipping} from "../../store/Shipping/actions";
import {GET_ARTISAN_SUCCESS, getArtisan as onGetArtisan} from "../../store/artisan/actions";
import {getCatShippingCarrierList as onGetCatShippingCarrierList} from "../../store/catShippingCarrier/actions";
import RenderByCond from "components/Common/RenderByCond";


const ShippingDetAndUpdateModal = props => {
  const { isOpen, toggle, products } = props;
  const [ isOpenCraftsmanDet, setIsOpenCraftsmanDet ] = useState(false);
  const [role, setRole] = useState({});
  const [editTracking, setEditTracking] = useState(true);
  const [tracking, setTracking] = useState({});
  const [status, setStatus] = useState("");

  const [catStatus,setCatStatus] = useState([
    {id:1,label:"En Tránsito"},
    {id:2,label:"Recibido"},
  ])

  const { catShippingCarrierList } = useSelector(state => ({
    catShippingCarrierList: state.CatShippingCarrier.catShippingCarrierList,
  }));

  const { shipping } = useSelector(state => ({
    shipping: state.Shipping.shipping,
  }));

  const { artisan } = useSelector(state => ({
    artisan: state.artisans.artisan,
  }));

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      if(obj.role_id)
      {
        setRole(obj.role_id)
      }
    }
  }, [props.success]);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(onGetCatShippingCarrierList());
  }, []);

  const validationAd = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: (status && status) || '',
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Selecciona el status para actualizar"),
    }),
    onSubmit: (values) => {
      const updateShipping = {
        codeShippingID: shipping.id,
          status: values.status,
          typeUpdate:"admin",
          products:productsL
        };

          dispatch(onUpdateShipping(updateShipping));
          setEditTracking(false)
    },
  });

  const handleGetArtisanDetail = item => {
    dispatch(onGetArtisan({id:item.craftsman_id}))
    toggleCraftDetModal()
  }

  const toggleCraftDetModal = () => {
    let isOpen = !isOpenCraftsmanDet;
    setIsOpenCraftsmanDet(isOpen)
    if(!isOpen) {
      dispatch({type:GET_ARTISAN_SUCCESS,payload: {data:null}})
    }
  }

  const [productsL,setProductsL] = useState(products?.length>0?products:[])
  const handleSetQuantityReceived = (key,e) => {
    const productss = [...productsL]
    const prod = productss[key]
    prod.quantity_received = e.target.value;
    productss.slice(key,1,prod);
    setProductsL(productss)
  }

  useEffect(()=> {
    setProductsL(products)
  },[products])

  return (
      <>
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true} size={props.size}
      centered={true}
      className="exampleModal"
      tabIndex="-1" contentClassName="modal-lg"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Seguimiento de envío</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6} md={6} sm={6}>
              <p className="mb-4">
                <h5>Detalle </h5>
                <h6>
                  Paquetería: <span className="text-primary">{shipping?.shipping_carrier_name}</span></h6>
                <h6>
                  Número de Tracking: <span className="text-primary">{shipping?.code}</span></h6>
                <h6>Status Actual: <span className="text-primary">{shipping?.status}</span></h6>
                <h6> Total de Artículos: <span className="text-primary">{shipping?.product_total_shipping}</span></h6>
              </p>
            </Col>

          </Row>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Producto</th>
                <th scope="col">Artesano</th>
                <th scope="col">Cantidad solicitada</th>
                <th scope="col">Cantidad Recibida</th>
              </tr>
              </thead>
              <tbody>
              {
                productsL?.map((item, key) =>
                    (<tr key={item.id}>
                  <th scope="row">
                    <div>
                      <RenderByCond cond={item?.variant_image} 
                      child={
                          <img src={item?.variant_image} alt="" className="avatar-sm"/>
                      }/>

                      <RenderByCond cond={!item?.variant_image && (item?.image && JSON.parse(item.image).length>1)} 
                      child={
                          <img src={JSON.parse(item.image)[0]} alt="" className="avatar-sm"/>
                      }/>
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">{item.name}</h5>
                      <div className="text-warning"><a href={"#"} onClick={()=>handleGetArtisanDetail(item)}> Artesano: {item.craftsman_name} </a></div>
                      <div className="text-warning"> Taller o Negocio: {item.business_name}</div>
                    </div>
                  </td>
                      <td>
                        <b>{item.quantity}</b>
                      </td>
                      <td>{item.craftsman_name}</td>
                      <td>
                        <Input id={"quantityCheck"+item.id}
                            name={"quantity" + item.id} min={0}
                            type="number" disabled={item.status==="Recibido"}
                            onChange={(e)=>handleSetQuantityReceived(key,e)}
                            value={item.quantity_received}
                        />

                      </td>
                </tr>))
              }

              </tbody>
            </Table>
          </div>
          <Row>
            <Col lg={6}></Col>
            <Col lg={6} md={6} sm={6}>
              <RenderByCond cond={role===1 && shipping?.status==='En tránsito'} child={
                <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationAd.handleSubmit();
                      return false;
                    }}
                >
                  <div className={"row"}>
                    <div className={"col-md-12"}>
                      <div className="mb-3">
                        <Label className="form-label">Actualizar Status </Label>
                        <Input
                            name="status"
                            type="select"
                            onChange={validationAd.handleChange}
                            onBlur={validationAd.handleBlur}
                            value={validationAd.values.status || ""}
                            invalid={
                              validationAd.touched.status && validationAd.errors.status ? true : false
                            }
                        >
                          <option value={""}>Selecciona el status</option>
                          {
                            catStatus.map((item)=>
                                (<option key={item.id} value={item.label}>{item.label}</option>)
                            )

                          }
                        </Input>
                        {validationAd.touched.status && validationAd.errors.status ? (
                            <FormFeedback type="invalid">{validationAd.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className={"col-md-12 "}>
                      <div className="mb-3 text-end">
                        <button type="submit" className={"btn btn-success"}> Guardar Cambios</button>
                      </div>
                    </div>
                  </div>
                </Form>
              }>
              </RenderByCond>
            </Col>
          </Row>
        </ModalBody>
        <div className="p-4" >
          <div className="text-start ">
            <Button type="button"  color="secondary" onClick={toggle}>
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </Modal>

  <Modal
      isOpen={isOpenCraftsmanDet}
      role="dialog"
      autoFocus={true} size={"lg"}
      centered={true}
      className=""
      tabIndex="1"
      toggle={toggleCraftDetModal}
  >
    <div className="modal-content">
      <ModalHeader toggle={toggle}>Detalles del Artesano </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table className="table-nowrap mb-0">
                <tbody>
                <tr>
                  <th scope="row">Clave:</th>
                  <td>{artisan?.key}</td>
                </tr>
                <tr>
                  <th scope="row">Nombre Completo:</th>
                  <td>{artisan?.name} {artisan?.paternal_surname} {artisan?.maternal_surname}</td>
                </tr>
                <tr>
                  <th scope="row">Negocio o Taller:</th>
                  <td>{artisan?.business_name}</td>
                </tr>
                <tr>
                  <th scope="row">Teléfono:</th>
                  <td>{artisan?.phone}</td>
                </tr>
                <tr>
                  <th scope="row">E-mail:</th>
                  <td>{artisan?.email}</td>
                </tr>
                <tr>
                  <th scope="row">Dirección:</th>
                  <td>{artisan?.address} {artisan?.zip_code} {artisan?.cat_state_name}</td>
                </tr>
                <tr>
                  <th scope="row">Tipo de Artesanía:</th>
                  <td>{artisan?.cat_type_craft_name} </td>
                </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="secondary" onClick={toggleCraftDetModal}>
          Cerrar
        </Button>
      </ModalFooter>
    </div>
  </Modal>
        </>
  )
}

ShippingDetAndUpdateModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ShippingDetAndUpdateModal
