import { takeEvery, put, call } from "redux-saga/effects";
import {DELETE_COLLECTOR, GET_COLLECTORS_LIST, SAVE_COLLECTOR, UPDATE_COLLECTOR} from "./actions"
import {getCollectorsSuccess, getCollectorsFail, saveCollectorFail, saveCollectorSuccess} from "./actions"
import {deleteCollector, getCollectorsData, saveNewCollector, updateCollector} from "../../helpers/backend_helper"
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchCollectors () {
    try {
      yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(getCollectorsData)
      yield put(getCollectorsSuccess(response))
    } catch (error) {
      yield put(getCollectorsFail(error))
    } finally {
      yield put({type:SHOW_PRELOADER,payload: false})
    }
  }

function* onDeleteCollector ({ payload: collector }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    yield put(saveCollectorFail({success:true}));
    const response = yield call(deleteCollector, collector);
    if(response.success)
    {
      yield put(saveCollectorSuccess(response));
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      yield put(saveCollectorFail(response));
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveCollectorFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onSaveNewCollector ({ payload: collector }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    const response = yield call(saveNewCollector, collector);
    if(response.success)
    {
      yield put(saveCollectorSuccess(response))
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveCollectorFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

function* onUpdateCollector ({ payload: collector }) {
  try {
    yield put({type:SHOW_PRELOADER,payload: true})
    yield put(saveCollectorFail({success:true}));
    const response = yield call(updateCollector, collector);
    if(response.success)
    {
      yield put(getCollectorsSuccess(response))
      toastr.success(response.message, "Plataforma Guelaguetza")
    } else
    {
      yield put(saveCollectorFail(response));
      toastr.error(response.message, "Plataforma Guelaguetza")
    }
  } catch (error) {
    console.info(error)
    yield put(saveCollectorFail(error));
  } finally {
    yield put({type:SHOW_PRELOADER,payload: false})
  }
}

// watchers
function* collectorsSaga() {
  yield takeEvery(GET_COLLECTORS_LIST, fetchCollectors)
  yield takeEvery(SAVE_COLLECTOR, onSaveNewCollector)
  yield takeEvery(UPDATE_COLLECTOR, onUpdateCollector)
  yield takeEvery(DELETE_COLLECTOR, onDeleteCollector)
}
    
export default collectorsSaga;