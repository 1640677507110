import { takeEvery, put, call } from "redux-saga/effects";
import {
 GET_IMAGE_LIST, UPLOAD_IMAGE,
 getImageList,
 getImageListSuccess,
 uploadImageSuccess
} from "./actions"

import { getImageListData,  uploadImage } from "../../helpers/backend_helper"
import toastr from "toastr";
import {SHOW_PRELOADER} from "../layout/actionTypes";

function* fetchImageList ({payload:filter}) {
    try {
      yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(getImageListData, filter)
      yield put(getImageListSuccess(response.data))
    } catch (error) {
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

function* onUploadImage ({payload:data}) {
  try {
      yield put({type:SHOW_PRELOADER,payload: true})
      const response = yield call(uploadImage, data)
      if(response.success){
        yield put(uploadImageSuccess(response))
        toastr.success(response.message)
      } else {
        toastr.error(response.message)
      }
  } catch (error) {
    console.error(error)
    toastr.error("Hubo un error al guardar la imagen, intenta nuevamente")
  } finally {
      yield put({type:SHOW_PRELOADER,payload: false})
  }
}


// watchers
function* GallerySaga() {
    yield takeEvery(GET_IMAGE_LIST, fetchImageList),
    yield takeEvery(UPLOAD_IMAGE, onUploadImage)
}
export default GallerySaga;